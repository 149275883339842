import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Column } from 'src/app/shared/components/table/column';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import { ConfigService } from 'src/app/initializer/config.service';
import { Base64 } from 'js-base64';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
interface DropdownSchema {
  value: string;
  viewValue: string;
}

interface CustomDropdownSchema {
  value: string;
  viewValue: string;
  disabled: boolean;
}

import { CapitalAccountItem } from './capital-account-datasource';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiHttpService } from '../../services/api-http.service';
import { UserService } from 'src/app/services/user.service';
import { CapitalAccountService } from 'src/app/services/capital-account.service';
import { DatepickerComponent } from 'src/app/shared/components/datepicker/datepicker.component';
import { transaction } from 'src/app/models/transaction.model';
import { convertWithDecimal } from 'src/app/utils/numberConvertion.util';
import * as moment from 'moment';
import { DocumentsService } from 'src/app/services/documents.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { ModalDialogData } from 'src/app/models/modelDialog.model';

@Component({
  selector: 'app-capital-account',
  templateUrl: './capital-account.component.html',
  styleUrls: ['./capital-account.component.scss']
})
export class CapitalAccountComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<CapitalAccountItem>;
  @ViewChild('startDatePicker') picker1!: DatepickerComponent;
  @ViewChild('endDatePicker') picker2!: DatepickerComponent;
  isTableDataLoading: boolean = true;
  isSelectedTransactionLoading: boolean = false;
  currentNavigation: any;
  isLoading: boolean = false;
  isOverviewDataAvailable: boolean = false;
  isTransactionDataAvailable: boolean = false;
  unloadedOverviewColumns: Array<Column> = [
    {
      columnDef: 'title',
      header: '',
      cell: (element: Record<string, any>) => ''
    },
    {
      columnDef: 'totalFund',
      header: '',
      cell: (element: Record<string, any>) => ''
    },
    {
      columnDef: 'yourShare',
      header: '',
      cell: (element: Record<string, any>) => ''
    }
  ];
  // unloadedData: any = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},{}, {}, {}, {}, {}, {}, {}, {}];
  // unloadedTransactionsData: any = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},{}, {}, {}, {}, {}, {}, {}, {}];
  unloadedData = Array(25).fill({});
  unloadedTransactionsData = Array(25).fill({});
  unloadedSingleTransaction: any = [{}];
  selectedTransaction: any = [];
  documentName: string = '';
  isVisible$ = new BehaviorSubject(true);
  displayedColumns: string[] = ['partner', 'date', 'type', 'amount', 'notes'];
  filePath: any;
  headerText!: string;
  types: any;
  status: any;
  totalTransactions: number;
  showViewer: boolean = false;
  startDate!: any;
  beginDate!: any;
  reportType: number = 1;
  showTableOne: number = 1;
  showTableTwo: number = 0;
  showFilter: boolean = false;
  isRowClicked: boolean = false;
  menuTitle: string = 'capital-account';
  frontendBaseUrl: string | undefined;
  languageCode: any;
  transactionData: transaction[] = [];
  apiTransactionResponse: any;
  apiCapitalAccountVerticalResponse: any;
  apiCapitalAccountoverviewResponse: any;
  begin_date!: any;
  end_date!: any;
  yearsList: any;
  lpsid: any;
  vehicleId: any;
  quartersList: any;
  selectedYearFromYearList: any;
  selectedQuarterFromList: any;
  selectedYear: any;
  selectedQuarter: any;
  selectedOverViewId: any;
  format: any;
  startNew: any;
  transactionId: any;
  quarterListOnYearChange: any;
  IFRSData: any;
  IFRSData1: any;
  IFRSData2: any;
  kfwCapitalAccount: any;
  kfwCapitalAccount1: any;
  reportTypes: any;
  selectedReportTypes: any;
  showOverviewFilters: boolean = false;
  showTransactionsFilters: boolean = false;

  selectType: any;
  selectStatus: any;
  selectedVehicle: any;
  selectedIndex: any;
  selectedStatusValue: any;
  selectedTypeValue: any;
  lastDayOfQuarter: any;
  lastDayOfLastQuarter: any;
  capitalTab: any;
  yearParam: any;
  quarterParam: any;
  transId: any;
  tabs: any = ['overview', 'transactions'];
  jsonData: any;
  dateControlSelected: boolean = false;
  hideCapitalAccount: boolean = true;

  destroyAndReload() {
    this.isVisible$.next(false);
    setTimeout(() => {
      this.isVisible$.next(true);
    }, 0);
  }

  handleStartDate(date: any) {
    this.dateControlSelected = true;
    this.startDate = date;
    this.begin_date = this.datepipe.transform(date, 'yyyy-MM-dd');
    this.filterFunction();
  }

  handleEndDate(date: any) {
    this.dateControlSelected = true;
    this.beginDate = date;
    this.end_date = this.datepipe.transform(date, 'yyyy-MM-dd');
    this.filterFunction();
  }
  handleRowClick(row: any) {
    this.showOverviewFilters = false;
    this.showTransactionsFilters = false;
    this.selectedTransaction[0] = row;
    if (this.selectedTransaction.length > 0) {
      this.documentName = this.getPdfFileName(
        this.selectedTransaction[0].documentId
      );
    }

    this.moveToSelectedTab('View Transaction');
    this.isLoading = true;
    this.isSelectedTransactionLoading = true;
    this.getTransactionDocument(this.lpsid, this.vehicleId, row.documentId);
    this.showViewer = true;
    this.isRowClicked = true;
    this.destroyAndReload();
    //this.router.navigateByUrl(`lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/transactions/${row.id}`);
    this.location.replaceState(
      `lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/transactions/${row.id}`
    );
  }
  filterFunction() {
    if (
      this.selectedStatusValue.viewValue != 'allstatus' &&
      this.selectedTypeValue.viewValue != 'alltype'
    ) {
      this.transactionData = _.filter(
        this.apiTransactionResponse,
        (item: any) => {
          return (
            item.sendDate >= this.begin_date &&
            item.sendDate <= this.end_date &&
            item.type.toLowerCase() == this.selectType.toLowerCase() &&
            item.status.toLowerCase() == this.selectStatus.toLowerCase()
          );
        }
      );
    } else if (this.selectedTypeValue.viewValue != 'alltype') {
      this.transactionData = _.filter(
        this.apiTransactionResponse,
        (item: any) => {
          return (
            item.sendDate >= this.begin_date &&
            item.sendDate <= this.end_date &&
            item.type.toLowerCase() == this.selectType.toLowerCase()
          );
        }
      );
    } else if (this.selectedStatusValue.viewValue != 'allstatus') {
      this.transactionData = _.filter(
        this.apiTransactionResponse,
        (item: any) => {
          return (
            item.sendDate >= this.begin_date &&
            item.sendDate <= this.end_date &&
            item.status.toLowerCase() == this.selectStatus.toLowerCase()
          );
        }
      );
    } else {
      this.transactionData = _.filter(
        this.apiTransactionResponse,
        (item: any) => {
          return (
            item.sendDate >= this.begin_date && item.sendDate <= this.end_date
          );
        }
      );
    }
    this.totalTransactions = this.transactionData.length;
    this.isTransactionDataAvailable =
      this.transactionData.length > 0 ? true : false;
  }

  getTooltipText() {
    return {
      type: 'download',
      content: this.translate.instant(
        'translations.dashboard.capital.excelToolTip'
      )
    };
  }

  resetTransaction() {
    this.dateControlSelected = false;
    this.selectedStatusValue = this.status[0];
    this.selectedTypeValue = this.types[0];
    this.selectStatus = this.status[0];
    this.selectType = this.types[0];
    this.transactionData = this.apiTransactionResponse;
    this.totalTransactions = this.transactionData.length;
    this.isTransactionDataAvailable = this.totalTransactions > 0 ? true : false;
    this.picker1.resetForm();
    this.picker2.resetForm();
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDay();
    this.startDate = moment({
      year: year - 100,
      month: month,
      day: day
    }).format('YYYY-MM-DD');
    this.beginDate = moment({
      year: year - 100,
      month: month,
      day: day
    }).format('YYYY-MM-DD');
    this.begin_date = this.datepipe.transform(
      this.apiTransactionResponse[0].sendDate,
      'yyyy-MM-dd'
    );
    this.end_date = this.datepipe.transform(
      this.apiTransactionResponse[this.apiTransactionResponse.length - 1]
        .sendDate,
      'yyyy-MM-dd'
    );
  }

  readJsonFile(): Observable<any> {
    const jsonFilePath =
      this.languageCode === 'de'
        ? 'assets/i18n/de.json'
        : 'assets/i18n/en.json';
    return this.http.get(jsonFilePath);
  }

  fetchJsonData(): void {
    this.readJsonFile().subscribe((data) => {
      this.jsonData = data;
    });
  }
  replaceDatePlaceholders(
    langValue: string,
    lastDayOfQuarter: string,
    lastDayOfLastQuarter: string
  ): string {
    if (
      langValue !== undefined &&
      langValue.includes('{formatDate(lastDayOfLastQuarter)}') &&
      langValue.includes('{formatDate(lastDayOfQuarter)}')
    ) {
      langValue = langValue
        .replace('{formatDate(lastDayOfQuarter)}', lastDayOfQuarter)
        .replace('{formatDate(lastDayOfLastQuarter)}', lastDayOfLastQuarter);
    } else if (
      langValue !== undefined &&
      langValue.includes('{formatDate(lastDayOfQuarter)}')
    ) {
      langValue = langValue.replace(
        '{formatDate(lastDayOfQuarter)}',
        lastDayOfQuarter
      );
    } else if (
      langValue !== undefined &&
      langValue.includes('{formatDate(lastDayOfLastQuarter)}')
    ) {
      langValue = langValue.replace(
        '{formatDate(lastDayOfLastQuarter)}',
        lastDayOfLastQuarter
      );
    }

    return langValue;
  }

  handleOverviewExcelDownload() {
    const reportTypeToExport = this.selectedReportTypes;
    const baseConfigData = this.config.baseData.data;

    const investor = baseConfigData.lps.find(
      (item: any) => item.id === this.lpsid
    );
    this.selectedVehicle = baseConfigData.vehicles.find(
      (item: any) => item.id === this.vehicleId
    );

    let apiObservable: Observable<any> | undefined;
    let fileNamePrefix = '';

    if (reportTypeToExport === 'ifrsVertical') {
      apiObservable = this.apiHttpService.get(
        this.apiEndpointsService.getCapitalOverviewIFRSVertical(
          this.lpsid,
          this.vehicleId,
          this.selectedYearFromYearList,
          this.selectedQuarterFromList,
          this.selectedOverViewId
        )
      );
      fileNamePrefix = 'IFRS Vertical';
    } else if (reportTypeToExport === 'kfwCapitalAccount') {
      apiObservable = this.apiHttpService.get(
        this.apiEndpointsService.getCapitalOverviewKFWCapitalAccount(
          this.lpsid,
          this.vehicleId,
          this.selectedYearFromYearList,
          this.selectedQuarterFromList,
          this.selectedOverViewId
        )
      );
      fileNamePrefix = 'KfW Capital Account';
    }

    if (apiObservable) {
      apiObservable.subscribe(
        (response: any) => {
          const apiResponse = JSON.parse(JSON.stringify(response));
          this.lastDayOfQuarter = this.datepipe.transform(
            apiResponse.data.lastDayOfQuarter,
            'dd. MMM yyyy'
          );
          this.lastDayOfLastQuarter = this.datepipe.transform(
            apiResponse.data.lastDayOfLastQuarter,
            'dd. MMM yyyy'
          );
          const workbook = new ExcelJS.Workbook();
          const sheetName = `${this.selectedYearFromYearList} Q${this.selectedQuarterFromList}`;
          const worksheet = workbook.addWorksheet(sheetName, {
            pageSetup: { paperSize: 9, orientation: 'portrait' }
          });
          const fileName = `${
            this.selectedVehicle.name
          } - Capital account overview ${this.selectedYearFromYearList}-${
            'Q' + this.selectedQuarterFromList
          } - ${fileNamePrefix}`;

          // Set column widths
          worksheet.columns = [{ width: 50 }, { width: 15 }, { width: 15 }];
          worksheet.addRow([
            `${this.selectedVehicle.name} - ${fileNamePrefix}`,
            '',
            `${this.selectedYearFromYearList}/Q${this.selectedQuarterFromList}`
          ]);
          worksheet.getRow(1).font = {
            name: 'Calibri (Body)',
            size: 14,
            bold: true
          };
          worksheet.getRow(1).getCell('C').alignment = { horizontal: 'right' };
          worksheet.addRow([
            `${investor.name}`,
            '',
            `${this.translate.instant(
              'translations.dashboard.capital.inKey'
            )} ${this.selectedVehicle.currency}`
          ]);
          worksheet.addRow([]);
          worksheet.addRow([]);

          apiResponse.data.sections.forEach(
            (section: { items: any[]; labelKey: any }) => {
              let langValue = this.translate.instant(
                `translations.dashboard.capital.` + section.labelKey
              );
              langValue = this.replaceDatePlaceholders(
                langValue,
                this.lastDayOfQuarter,
                this.lastDayOfLastQuarter
              );

              let headerRow = worksheet.addRow([
                langValue,
                this.translate.instant(
                  `translations.dashboard.capital.TotalFund`
                ),
                this.translate.instant(
                  `translations.dashboard.capital.YourShare`
                )
              ]);
              headerRow.font = { name: 'Calibri (Body)', size: 12, bold: true };
              headerRow.eachCell((cell) => {
                cell.border = { bottom: { style: 'thin' } };
              });

              section.items.forEach((item) => {
                let highlighted = item.highlighted === undefined ? false : true;
                let itemLangValue = this.translate.instant(
                  `translations.dashboard.capital.` + item.labelKey
                );
                itemLangValue = this.replaceDatePlaceholders(
                  itemLangValue,
                  this.lastDayOfQuarter,
                  this.lastDayOfLastQuarter
                );

                let dataRow = worksheet.addRow([
                  itemLangValue,
                  item.total === null ? '-' : item.total,
                  item.share === null ? '-' : item.share
                ]);

                let cell2 = dataRow.getCell(2);
                let cell3 = dataRow.getCell(3);
                cell2.numFmt = '#,##0.00';
                cell3.numFmt = '#,##0.00';

                if (highlighted) {
                  dataRow.font = { bold: true };
                }
              });

              // Add an empty row after each section
              worksheet.addRow([]);
            }
          );

          // Right-align columns B and C in all rows
          worksheet.eachRow({ includeEmpty: true }, (row) => {
            row.getCell(2).alignment = { horizontal: 'right' };
            row.getCell(3).alignment = { horizontal: 'right' };
          });

          // Generate a buffer
          workbook.xlsx.writeBuffer().then((buffer) => {
            // Save the Excel file
            const blob = new Blob([buffer], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            saveAs(blob, `${fileName}.xlsx`);
          });
        },
        (error: any) => {
          console.log('Excel capital account overview report download issue..');
        }
      );
    }
  }

  public tabChanged(tabChangeEvent: any): void {
    this.destroyAndReload();
    let messageToSend =
      tabChangeEvent.index === 0
        ? 'capital-account.overview'
        : 'capital-account.transactions';
    this.notificationService.sendNotification({
      prefix: messageToSend,
      suffix: ''
    });
    if (tabChangeEvent.index === 0) {
      this.capitalTab = 'overview';
      this.showOverviewFilters = true;
      this.showTransactionsFilters = false;
      if (
        this.selectedQuarterFromList &&
        this.selectedYearFromYearList &&
        this.selectedOverViewId
      ) {
        this.location.replaceState(
          `lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/${
            this.tabs[tabChangeEvent.index]
          }/${this.selectedYearFromYearList}/Q${this.selectedQuarterFromList}/${
            this.selectedOverViewId
          }`
        );
      } else {
        this.location.replaceState(
          `lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/${
            this.tabs[tabChangeEvent.index]
          }`
        );
      }
    }
    if (tabChangeEvent.index === 1) {
      this.showOverviewFilters = false;
      this.showTransactionsFilters = true;
      this.location.replaceState(
        `lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/${
          this.tabs[tabChangeEvent.index]
        }`
      );
      this.showViewer = true;
      this.moveToSelectedTab('Transactions List');
    }
  }

  transactionColumns: Array<Column> = [
    {
      columnDef: 'reportTitle',
      header: 'Title',
      cell: (element: Record<string, any>) => `${element['title']}`
    },
    {
      columnDef: 'type',
      header: 'Type',
      cell: (element: Record<string, any>) => `${element['type']}`
    },
    {
      columnDef: 'sendDate',
      header: 'Send date',
      cell: (element: Record<string, any>) => `${element['sendDate']}`
    },
    {
      columnDef: 'dueDate',
      header: 'Due date',
      cell: (element: Record<string, any>) => `${element['dueDate']}`
    },
    {
      columnDef: 'amount',
      header: `Amount in `,
      cell: (element: Record<string, any>) => `${element['amount']}`
    },
    {
      columnDef: 'status',
      header: 'Status',
      isStatus: true,
      cell: (element: Record<string, any>) => `${element['status']}`
    },
    {
      columnDef: 'documentId',
      header: '',
      cell: (element: Record<string, any>) => `${element['documentId']}`
    }
  ];

  viewTransactionColumns: Array<Column> = [
    {
      columnDef: 'reportTitle',
      header: 'Title',
      cell: (element: Record<string, any>) => `${element['title']}`
    },
    {
      columnDef: 'type',
      header: 'Type',
      cell: (element: Record<string, any>) => `${element['type']}`
    },
    {
      columnDef: 'sendDate',
      header: 'Send date',
      cell: (element: Record<string, any>) => `${element['sendDate']}`
    },
    {
      columnDef: 'dueDate',
      header: 'Due date',
      cell: (element: Record<string, any>) => `${element['dueDate']}`
    },
    {
      columnDef: 'amount',
      header: 'Amount ',
      cell: (element: Record<string, any>) => `${element['amount']}`
    },
    {
      columnDef: 'status',
      header: 'Status',
      isStatus: true,
      cell: (element: Record<string, any>) => `${element['status']}`
    }
  ];

  kfWCapitalAccountFirstColumn: Array<Column> = [
    {
      columnDef: 'title',
      header: 'kfwSection1title',
      cell: (element: Record<string, any>) => `${element['title']}`
    },
    {
      columnDef: 'totalFund',
      header: 'Total fund',
      cell: (element: Record<string, any>) => `${element['totalFund']}`
    },
    {
      columnDef: 'yourShare',
      header: 'Your share',
      cell: (element: Record<string, any>) => `${element['yourShare']}`
    }
  ];

  kfWCapitalAccountSecondColumn: Array<Column> = [
    {
      columnDef: 'title',
      header: 'kfwSection2title',
      cell: (element: Record<string, any>) => `${element['title']}`
    },
    {
      columnDef: 'totalFund',
      header: 'Total fund',
      cell: (element: Record<string, any>) => `${element['totalFund']}`
    },
    {
      columnDef: 'yourShare',
      header: 'Your share',
      cell: (element: Record<string, any>) => `${element['yourShare']}`
    }
  ];
  IFRSTableFirstColumn: Array<Column> = [
    {
      columnDef: 'title',
      header: 'ifrsSection1title',
      cell: (element: Record<string, any>) => `${element['title']}`
    },
    {
      columnDef: 'totalFund',
      header: 'Total fund',
      cell: (element: Record<string, any>) => `${element['totalFund']}`
    },
    {
      columnDef: 'yourShare',
      header: 'Your share',
      cell: (element: Record<string, any>) => `${element['yourShare']}`
    }
  ];

  IFRSTableSecondColumn: Array<Column> = [
    {
      columnDef: 'title',
      header: 'ifrsSection2title',
      cell: (element: Record<string, any>) => `${element['title']}`
    },
    {
      columnDef: 'totalFund',
      header: 'Total fund',
      cell: (element: Record<string, any>) => `${element['totalFund']}`
    },
    {
      columnDef: 'yourShare',
      header: 'Your share',
      cell: (element: Record<string, any>) => `${element['yourShare']}`
    }
  ];

  IFRSTableThirdColumn: Array<Column> = [
    {
      columnDef: 'title',
      header: 'ifrsSection3title',
      cell: (element: Record<string, any>) => `${element['title']}`
    },
    {
      columnDef: 'totalFund',
      header: 'Total fund',
      cell: (element: Record<string, any>) => `${element['totalFund']}`
    },
    {
      columnDef: 'yourShare',
      header: 'Your share',
      cell: (element: Record<string, any>) => `${element['yourShare']}`
    }
  ];

  constructor(
    private notificationService: NotificationService,
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: CapitalAccountService,
    private userService: UserService,
    private documentsService: DocumentsService,
    public datepipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private config: ConfigService,
    private router: Router,
    private location: Location,
    public dialog: MatDialog,
    private http: HttpClient,
    private translate: TranslateService
  ) {
    this.totalTransactions = 0;
    this.frontendBaseUrl = environment.frontendBaseUrl;
  }

  reportFilterTypes: CustomDropdownSchema[] = [
    { value: '1', viewValue: 'IFRS Vertical', disabled: false },
    { value: '2', viewValue: 'IFRS Horizontal', disabled: false },
    { value: '', viewValue: '----------------', disabled: true },
    { value: '3', viewValue: 'ILPA Short', disabled: false },
    { value: '4', viewValue: 'ILPA Extended', disabled: false },
    { value: '', viewValue: '----------------', disabled: true },
    { value: '5', viewValue: 'GER GAAP Horizontal', disabled: false },
    { value: '', viewValue: '----------------', disabled: true },
    { value: '6', viewValue: 'KfW Capital Account', disabled: false }
  ];
  fileFormats: DropdownSchema[] = [
    { value: '1', viewValue: 'Excel' },
    { value: '2', viewValue: 'PDF' }
  ];
  selectedFileFormat = this.fileFormats[0].value;
  filteredValues = { sendDate: '' };
  ngOnInit(): void {
    if (environment.hideCapitalAccount === true) {
      this.hideCapitalAccount = false;
    }

    const { languageCode } = JSON.parse(this.userService.getUserConfig());
    this.lpsid = this.activatedRoute.snapshot.params['lpId'];
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    if (!this.hideCapitalAccount) {
      this.capitalTab = 'overview';
    } else {
      this.capitalTab =
        this.activatedRoute.snapshot.params['capitalAccountTab'] != undefined &&
        this.tabs.includes(
          this.activatedRoute.snapshot.params['capitalAccountTab']
        )
          ? this.activatedRoute.snapshot.params['capitalAccountTab']
          : 'overview';
    }
    if (
      this.activatedRoute.snapshot.params['year'] &&
      this.activatedRoute.snapshot.params['quarter']
    ) {
      this.yearParam = parseInt(
        this.activatedRoute.snapshot.params['year'].replace(/^\D+/g, '')
      );
      this.quarterParam = parseInt(
        this.activatedRoute.snapshot.params['quarter'].replace(/^\D+/g, '')
      );
    }
    let messageToSend = 'capital-account.overview';
    if (this.capitalTab === 'overview') {
      this.showOverviewFilters = true;
      this.showTransactionsFilters = false;
      this.format = this.activatedRoute.snapshot.params['format'];
      this.currentNavigation = {
        year: this.yearParam,
        quarter: this.quarterParam
      };
      this.selectedYearFromYearList =
        this.activatedRoute.snapshot.params['year'];
      this.selectedQuarterFromList =
        this.activatedRoute.snapshot.params['quarter'];
      this.selectedOverViewId = this.activatedRoute.snapshot.params['format'];
    }

    if (this.capitalTab === 'transactions') {
      environment.hideCapitalAccount !== true;
      {
        messageToSend = 'capital-account.transactions';
      }
      this.transId = this.activatedRoute.snapshot.params['transactionId'];
      this.currentNavigation = { transactionId: this.transId };
      this.showOverviewFilters = false;
      this.showTransactionsFilters = true;
    }
    let baseconfigData = this.config.baseData.data;
    this.selectedVehicle = baseconfigData.vehicles.find(
      (item: any) => item.id === this.vehicleId
    );

    if (this.currentNavigation && this.currentNavigation.transactionId) {
      this.selectedIndex = 1;
    } else {
      this.selectedIndex = 0;
    }
    this.getCapitalAccountFormats(this.lpsid, this.vehicleId);
    if (this.hideCapitalAccount === true) {
      setTimeout(() => {
        this.getCapitalTransactionData(this.lpsid, this.vehicleId);
      }, 900);
    }
    this.destroyAndReload();
    this.notificationService.sendNotification({
      prefix: messageToSend,
      suffix: ''
    });
  }

  backNavigation() {
    this.moveToSelectedTab('Transactions List');
    this.destroyAndReload();
    this.showOverviewFilters = false;
    this.showTransactionsFilters = true;
    this.moveToSelectedTab('Transactions List');
    this.location.replaceState(
      `lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/transactions`
    );
  }

  getCapitalAccountFormats(lpsId: any, vehicleId: any) {
    let displayFormat: any[] = [];
    this.apiHttpService
      .get(this.apiEndpointsService.getCapitalAccountFormats(lpsId, vehicleId))
      .subscribe(
        (response) => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          _.forEach(
            parsedResponse.data.browserDisplayFormats,
            function (e: any) {
              let report = { value: e.id, viewValue: e.labelKey };
              displayFormat.push(report);
            }
          );
          this.reportTypes = displayFormat;
          let isValidFormat = this.reportTypes.find(
            (type: any) => type.value === this.format
          );
          if (isValidFormat) {
            this.selectedOverViewId = this.reportTypes.find(
              (type: any) => type.value === this.format
            ).value;

            this.selectedReportTypes = this.reportTypes.find(
              (type: any) => type.value === this.format
            ).viewValue;
          } else if (!isValidFormat && this.format != undefined) {
            this.selectedOverViewId = this.reportTypes[0].value;
            this.selectedReportTypes = this.reportTypes[0].viewValue;
            this.notificationService.sendNotification({ isInaccessible: true });
            const redirectURL = `lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/overview/${this.selectedYearFromYearList}/${this.selectedQuarterFromList}/${this.selectedOverViewId}`;
            this.openModelDialog(true, redirectURL);
          } else {
            this.selectedOverViewId = this.reportTypes[0].value;
            this.selectedReportTypes = this.reportTypes[0].viewValue;
          }
          this.getCapitalAccountOverview(lpsId, vehicleId);
        },
        (error) => {
          this.isTableDataLoading = false;
          if (error.status == 404) {
            this.reportTypes = [];
          }
        }
      );
  }
  getCapitalAccountOverview(lpsId: any, vehicleId: any) {
    let Year: any[] = [];
    let quarter: any[] = [];
    this.apiHttpService
      .get(this.apiEndpointsService.getCapitalAccountOverview(lpsId, vehicleId))
      .subscribe(
        (response) => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.apiCapitalAccountoverviewResponse = parsedResponse.data;
          if (this.apiCapitalAccountoverviewResponse.length > 0) {
            _.forEach(
              this.apiCapitalAccountoverviewResponse,
              function (e: any) {
                let noOfyear = { value: 'year', viewValue: e.year };
                Year.push(noOfyear);
                let noOfQuarter = {
                  value: 'quarter',
                  year: e.year,
                  viewValue: 'Q' + e.quarter
                };
                quarter.push(noOfQuarter);
              }
            );
            let _year = this.getUniqByvalue(Year);
            //order year
            this.yearsList = _year.sort((one: any, two: any) =>
              one.viewValue > two.viewValue ? -1 : 1
            );
            //order quater
            this.quartersList = quarter.sort((one: any, two: any) =>
              one.year > two.year ? -1 : 1
            );

            this.quarterListOnYearChange = _.filter(
              this.quartersList,
              (item: any) => {
                return item.year == _year[0].viewValue;
              }
            );

            this.quarterListOnYearChange.sort((one: any, two: any) =>
              one.viewValue > two.viewValue ? -1 : 1
            );

            if (this.currentNavigation) {
              if (
                this.currentNavigation.year &&
                this.currentNavigation.quarter
              ) {
                let isvalidYear = _.filter(this.quartersList, (item: any) => {
                  return item.year == this.currentNavigation.year;
                });
                if (isvalidYear.length == 0) {
                  this.currentNavigation.year = this.quartersList[0].year;
                  this.currentNavigation.quarter =
                    this.quartersList[0].viewValue;
                  this.selectedQuarter = this.quartersList[0].viewValue;
                  const redirectURL = `lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/overview/${this.quartersList[0].year}/${this.quartersList[0].viewValue}/${this.selectedOverViewId}`;
                  this.notificationService.sendNotification({
                    isInaccessible: true
                  });
                  this.openModelDialog(true, redirectURL);
                } else if (isvalidYear.length > 0) {
                  this.selectedYear = this.selectedYear = this.yearsList.find(
                    (x: any) => x.viewValue === this.currentNavigation.year
                  );

                  let isValidQuater = _.filter(
                    this.quartersList,
                    (item: any) => {
                      return (
                        item.viewValue ==
                          `Q${this.currentNavigation.quarter}` &&
                        item.year == isvalidYear[0].year
                      );
                    }
                  );

                  this.quarterListOnYearChange = _.filter(
                    this.quartersList,
                    (item: any) => {
                      return item.year == isvalidYear[0].year;
                    }
                  );
                  this.quarterListOnYearChange.sort((one: any, two: any) =>
                    one.viewValue > two.viewValue ? -1 : 1
                  );
                  this.selectedQuarter = this.quarterListOnYearChange.find(
                    (item: any) => {
                      return (
                        item.viewValue == `Q${this.currentNavigation.quarter}`
                      );
                    }
                  );

                  if (isValidQuater.length == 0) {
                    this.currentNavigation.quarter =
                      isvalidYear[0].viewValue.split('')[1];

                    this.selectedQuarter = isvalidYear[0].viewValue;
                    const redirectURL = `lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/overview/${isvalidYear[0].year}/${this.selectedQuarter}/${this.selectedOverViewId}`;
                    this.notificationService.sendNotification({
                      isInaccessible: true
                    });
                    this.openModelDialog(true, redirectURL);
                  } else {
                    this.selectedQuarterFromList =
                      this.selectedQuarter.viewValue.split('')[1];
                  }
                }
                this.quarterListOnYearChange = _.filter(
                  this.quartersList,
                  (item: any) => {
                    return item.year == this.selectedYear.viewValue;
                  }
                );
                this.quarterListOnYearChange.sort((one: any, two: any) =>
                  one.viewValue > two.viewValue ? -1 : 1
                );
              }
              if (!this.selectedYear || !this.selectedQuarter) {
                this.selectedYear = this.yearsList[0];
                this.selectedQuarter = this.quarterListOnYearChange[0];
                this.selectedYearFromYearList = this.yearsList[0].viewValue;
                this.selectedQuarterFromList = parseInt(
                  this.quarterListOnYearChange[0].viewValue.split('')[1]
                );
              }
            } else {
              if (this.quarterListOnYearChange.length) {
                this.selectedYear = this.yearsList[0];
                this.selectedQuarter = this.quarterListOnYearChange[0];
                this.selectedYearFromYearList = this.yearsList[0].viewValue;
                this.selectedQuarterFromList = parseInt(
                  this.quarterListOnYearChange[0].viewValue.split('')[1]
                );
              } else {
                this.isTableDataLoading = false;
              }
            }
            if (this.selectedReportTypes && this.selectedOverViewId) {
              this.onReportSelection({
                value: this.selectedOverViewId,
                viewValue: this.selectedReportTypes
              });
            } else {
              this.getCapitalOverviewIFRSVertical(
                lpsId,
                vehicleId,
                this.selectedYearFromYearList,
                this.selectedQuarterFromList,
                this.selectedOverViewId
              );
            }
          } else if (this.capitalTab === 'overview') {
            this.isTableDataLoading = false;
            this.location.replaceState(
              `lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/overview`
            );
          }
        },
        (error) => {
          this.isTableDataLoading = false;
          if (error.status == 404) {
            this.apiCapitalAccountoverviewResponse = [];
            this.totalTransactions = 0;
          }
        }
      );
  }
  getCapitalTransactionData(lpsId: any, vehicleId: any) {
    let typeList: any[] = [];
    let statusList: any[] = [];
    this.apiHttpService
      .get(this.apiEndpointsService.getCapitalTransactionData(lpsId, vehicleId))
      .subscribe(
        (response) => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          if (parsedResponse.data.length) {
            this.isTransactionDataAvailable = parsedResponse.data.length > 0;
            this.transactionData = parsedResponse.data.sort(
              (a: any, b: any) => {
                const d1 = new Date(a.sendDate);
                const d2 = new Date(b.sendDate);
                return d1.getTime() - d2.getTime();
              }
            );
            this.begin_date = this.datepipe.transform(
              this.transactionData[0].sendDate,
              'yyyy-MM-dd'
            );
            this.end_date = this.datepipe.transform(
              this.transactionData[this.transactionData.length - 1].sendDate,
              'yyyy-MM-dd'
            );
            this.apiTransactionResponse = _.forEach(
              parsedResponse.data,
              (e: any) => {
                e.amountWithoutFormat = e.amount;
                e.amount = convertWithDecimal(e.amount, this.selectedVehicle);
                let type = { value: 'title', viewValue: e.type };
                typeList.push(type);
                let status = { value: 'status', viewValue: e.status };
                statusList.push(status);
                return e.type;
              }
            );
            this.transactionData = this.apiTransactionResponse;
            this.totalTransactions = this.transactionData.length;
            this.types = this.getUniqByvalue(typeList);
            this.types = [{ value: 'alltype', viewValue: 'alltype' }].concat(
              this.types
            );
            this.status = this.getUniqByvalue(statusList);
            this.status = [
              { value: 'allstatus', viewValue: 'allstatus' }
            ].concat(this.status);
            this.selectType = this.types[0];
            this.selectStatus = this.status[0];
            this.selectedStatusValue = this.status[0];
            this.selectedTypeValue = this.types[0];

            if (this.currentNavigation.transactionId) {
              let currentTransaction = this.transactionData.find(
                (transaction: any) =>
                  transaction.id === this.currentNavigation.transactionId
              );
              if (!currentTransaction) {
                this.notificationService.sendNotification({
                  isInaccessible: true
                });
                currentTransaction = this.transactionData[0];
                const redirectURL = `lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/${this.tabs[1]}`;
                this.openModelDialog(true, redirectURL);
              } else {
                this.tabChanged({ index: 1 });
                this.destroyAndReload();
                if (currentTransaction && currentTransaction.documentId) {
                  setTimeout(() => {
                    this.handleRowClick(currentTransaction);
                  }, 300);
                }
              }
            }
          }
          this.isTableDataLoading = false;
        },
        (error) => {
          this.isTableDataLoading = false;
          if (error.status == 404) {
            this.transactionData = [];
            this.totalTransactions = 0;
          }
        }
      );
  }

  getCapitalOverviewIFRSVertical(
    lpsId: any,
    vehicleId: any,
    year: any,
    quarter: any,
    overViewId: any
  ) {
    let HeaderData: any[] = [];
    let IFRSDataColumnData1: any[] = [];
    let IFRSDataColumnData2: any[] = [];
    let IFRSDataColumnData3: any[] = [];
    this.apiHttpService
      .get(
        this.apiEndpointsService.getCapitalOverviewIFRSVertical(
          lpsId,
          vehicleId,
          year,
          quarter,
          overViewId
        )
      )
      .subscribe(
        (response) => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.lastDayOfQuarter = parsedResponse.data.lastDayOfQuarter;
          this.lastDayOfLastQuarter = parsedResponse.data.lastDayOfLastQuarter;
          this.apiCapitalAccountVerticalResponse = parsedResponse.data;
          this.isOverviewDataAvailable =
            this.apiCapitalAccountVerticalResponse.sections.length > 0;
          _.forEach(
            this.apiCapitalAccountVerticalResponse.sections,
            (data: any) => {
              switch (data.labelKey) {
                case 'ifrsSection1title': {
                  let dataheader = {
                    columnDef: 'title',
                    header: 'I. Commitment Status',
                    cell: data.labelKey
                  };

                  HeaderData.push(dataheader);
                  _.forEach(data.items, (itemData: any) => {
                    let dataColumn = {
                      title: itemData.labelKey.toString(),
                      totalFund: convertWithDecimal(
                        itemData.total,
                        this.selectedVehicle,
                        true
                      ),
                      yourShare: convertWithDecimal(
                        itemData.share,
                        this.selectedVehicle,
                        true
                      ),
                      highlighted:
                        itemData.highlighted == undefined ? false : true
                    };
                    IFRSDataColumnData1.push(dataColumn);
                  });
                  return IFRSDataColumnData1;
                }
                case 'ifrsSection2title': {
                  let dataheader = {
                    columnDef: 'totalFund',
                    header: 'Total fund',
                    cell: data.labelKey
                  };

                  HeaderData.push(dataheader);
                  _.forEach(data.items, (itemData: any) => {
                    let dataColumn = {
                      title: itemData.labelKey,
                      totalFund: convertWithDecimal(
                        itemData.total,
                        this.selectedVehicle,
                        true
                      ),
                      yourShare: convertWithDecimal(
                        itemData.share,
                        this.selectedVehicle,
                        true
                      ),
                      highlighted:
                        itemData.highlighted == undefined ? false : true
                    };
                    IFRSDataColumnData2.push(dataColumn);
                  });
                  return IFRSDataColumnData2;
                }
                case 'ifrsSection3title': {
                  let dataheader = {
                    columnDef: 'yourShare',
                    header: 'Your share',
                    cell: data.labelKey
                  };
                  HeaderData.push(dataheader);
                  _.forEach(data.items, (itemData: any) => {
                    let dataColumn = {
                      title: itemData.labelKey,
                      totalFund: convertWithDecimal(
                        itemData.total,
                        this.selectedVehicle,
                        true
                      ),
                      yourShare: convertWithDecimal(
                        itemData.share,
                        this.selectedVehicle,
                        true
                      ),
                      highlighted:
                        itemData.highlighted == undefined ? false : true
                    };
                    IFRSDataColumnData3.push(dataColumn);
                  });
                  return IFRSDataColumnData3;
                }
              }
              return IFRSDataColumnData3;
            }
          );
          this.IFRSData = JSON.parse(JSON.stringify(IFRSDataColumnData1));
          this.IFRSData1 = JSON.parse(JSON.stringify(IFRSDataColumnData2));
          this.IFRSData2 = JSON.parse(JSON.stringify(IFRSDataColumnData3));
          this.isTableDataLoading = false;
        },
        (error) => {
          this.isTableDataLoading = false;
          if (error.status == 404) {
            this.IFRSData = [];
          }
        }
      );
  }
  getCapitalOverviewKFWCapitalAccount(
    lpsId: any,
    vehicleId: any,
    year: any,
    quarter: any,
    slectedOverViewId: any
  ) {
    let IFRSDataColumnData1: any[] = [];
    let IFRSDataColumnData2: any[] = [];
    this.apiHttpService
      .get(
        this.apiEndpointsService.getCapitalOverviewKFWCapitalAccount(
          lpsId,
          vehicleId,
          year,
          quarter,
          slectedOverViewId
        )
      )
      .subscribe(
        (response) => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.lastDayOfQuarter = parsedResponse.data.lastDayOfQuarter;
          this.lastDayOfLastQuarter = parsedResponse.data.lastDayOfLastQuarter;

          this.apiCapitalAccountVerticalResponse = parsedResponse.data.sections;
          this.isOverviewDataAvailable =
            this.apiCapitalAccountVerticalResponse.length > 0;
          _.forEach(this.apiCapitalAccountVerticalResponse, (data: any) => {
            switch (data.labelKey) {
              case 'kfwSection1title': {
                _.forEach(data.items, (itemData: any) => {
                  let dataColumn = {
                    title: itemData.labelKey,
                    highlighted:
                      itemData.highlighted == undefined ? false : true,
                    totalFund: convertWithDecimal(
                      itemData.total,
                      this.selectedVehicle,
                      true
                    ),
                    yourShare: convertWithDecimal(
                      itemData.share,
                      this.selectedVehicle,
                      true
                    )
                  };
                  IFRSDataColumnData1.push(dataColumn);
                });
                return IFRSDataColumnData1;
              }
              case 'kfwSection2title': {
                _.forEach(data.items, (itemData: any) => {
                  let dataColumn = {
                    title: itemData.labelKey,
                    highlighted:
                      itemData.highlighted == undefined ? false : true,
                    totalFund: convertWithDecimal(
                      itemData.total,
                      this.selectedVehicle,
                      true
                    ),
                    yourShare: convertWithDecimal(
                      itemData.share,
                      this.selectedVehicle,
                      true
                    )
                  };
                  IFRSDataColumnData2.push(dataColumn);
                });
                return IFRSDataColumnData2;
              }
            }
            return '';
          });
          this.kfwCapitalAccount = JSON.parse(
            JSON.stringify(IFRSDataColumnData1)
          );
          this.kfwCapitalAccount1 = JSON.parse(
            JSON.stringify(IFRSDataColumnData2)
          );
          this.isTableDataLoading = false;
        },
        (error) => {
          this.isTableDataLoading = false;
          if (error.status == 404) {
            this.kfwCapitalAccount = [];
          }
        }
      );
  }
  onReportselectSelection(event: any) {
    switch (event.value.value) {
      case 'year': {
        let filteredData = _.filter(this.quartersList, (item: any) => {
          return item.year == event.value.viewValue;
        });
        this.quarterListOnYearChange = filteredData;
        this.quarterListOnYearChange.sort((one: any, two: any) =>
          one.viewValue > two.viewValue ? -1 : 1
        );

        this.quarterListOnYearChange = filteredData;
        this.selectedYearFromYearList = event.value.viewValue;
        this.selectedQuarterFromList = filteredData[0].viewValue.split('')[1];
        this.location.replaceState(
          `lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/overview/${this.selectedYearFromYearList}/Q${this.selectedQuarterFromList}/${this.selectedOverViewId}`
        );
        this.selectedQuarter = this.quarterListOnYearChange[0];
        if (this.showTableOne == 1) {
          return this.getCapitalOverviewIFRSVertical(
            this.lpsid,
            this.vehicleId,
            this.selectedYearFromYearList,
            this.selectedQuarterFromList,
            this.selectedOverViewId
          );
        } else {
          return this.getCapitalOverviewKFWCapitalAccount(
            this.lpsid,
            this.vehicleId,
            this.selectedYearFromYearList,
            this.selectedQuarterFromList,
            this.selectedOverViewId
          );
        }
      }
      case 'quarter': {
        this.selectedQuarterFromList = parseInt(
          event.value.viewValue.split('')[1]
        );
        this.location.replaceState(
          `lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/overview/${this.selectedYearFromYearList}/Q${this.selectedQuarterFromList}/${this.selectedOverViewId}`
        );
        if (this.showTableOne == 1) {
          return this.getCapitalOverviewIFRSVertical(
            this.lpsid,
            this.vehicleId,
            this.selectedYearFromYearList,
            this.selectedQuarterFromList,
            this.selectedOverViewId
          );
        } else {
          return this.getCapitalOverviewKFWCapitalAccount(
            this.lpsid,
            this.vehicleId,
            this.selectedYearFromYearList,
            this.selectedQuarterFromList,
            this.selectedOverViewId
          );
        }
      }
      case 'alltype': {
        if (this.selectStatus.viewValue == 'allstatus') {
          this.transactionData = _.filter(
            this.apiTransactionResponse,
            (item: any) => {
              return (
                item.sendDate >= this.begin_date &&
                item.sendDate <= this.end_date
              );
            }
          );
          this.totalTransactions = this.transactionData.length;
          return this.totalTransactions;
        } else {
          this.transactionData = _.filter(
            this.apiTransactionResponse,
            (item: any) => {
              return (
                item.sendDate >= this.begin_date &&
                item.sendDate <= this.end_date &&
                item.status.toLowerCase() == this.selectStatus.toLowerCase()
              );
            }
          );
        }
        this.totalTransactions = this.transactionData.length;
        return this.totalTransactions;
      }

      case 'allstatus': {
        if (this.selectedTypeValue.viewValue == 'alltype') {
          this.transactionData = _.filter(
            this.apiTransactionResponse,
            (item: any) => {
              return (
                item.sendDate >= this.begin_date &&
                item.sendDate <= this.end_date
              );
            }
          );
          this.totalTransactions = this.transactionData.length;
          return this.totalTransactions;
        } else {
          this.transactionData = _.filter(
            this.apiTransactionResponse,
            (item: any) => {
              return (
                item.sendDate >= this.begin_date &&
                item.sendDate <= this.end_date &&
                item.type.toLowerCase() == this.selectType.toLowerCase()
              );
            }
          );
        }

        this.totalTransactions = this.transactionData.length;
        return this.totalTransactions;
      }
      case 'title': {
        let filteredData = _.filter(
          this.apiTransactionResponse,
          (item: any) => {
            if (this.selectedStatusValue.viewValue == 'allstatus') {
              return (
                item.type.toLowerCase() ===
                  event.value.viewValue.toLowerCase() &&
                item.sendDate >= this.begin_date &&
                item.sendDate <= this.end_date
              );
            } else {
              return (
                item.type.toLowerCase() ==
                  event.value.viewValue.toLowerCase() &&
                item.sendDate >= this.begin_date &&
                item.sendDate <= this.end_date &&
                item.status == this.selectedStatusValue.viewValue
              );
            }
          }
        );
        this.selectType = event.value.viewValue;
        this.transactionData = filteredData;
        this.totalTransactions = this.transactionData.length;
        return this.totalTransactions;
      }
      case 'status': {
        let filteredData = _.filter(
          this.apiTransactionResponse,
          (item: any) => {
            if (this.selectedTypeValue.viewValue == 'alltype') {
              return (
                item.status.toLowerCase() ==
                  event.value.viewValue.toLowerCase() &&
                item.sendDate >= this.begin_date &&
                item.sendDate <= this.end_date
              );
            } else {
              return (
                item.status.toLowerCase() ==
                  event.value.viewValue.toLowerCase() &&
                item.sendDate >= this.begin_date &&
                item.sendDate <= this.end_date &&
                item.type == this.selectedTypeValue.viewValue
              );
            }
          }
        );
        this.transactionData = filteredData;
        this.selectStatus = event.value.viewValue;
        this.totalTransactions = this.transactionData.length;
        return this.totalTransactions;
      }
    }
  }
  onReportSelection(event: any) {
    switch (event.viewValue) {
      case 'kfwCapitalAccount': {
        this.showTableOne = 0;
        this.showTableTwo = 1;
        this.selectedReportTypes = event.viewValue;
        this.selectedOverViewId = event.value;

        this.isTableDataLoading = true;
        if (
          this.selectedYearFromYearList != undefined &&
          this.selectedQuarterFromList != undefined &&
          this.capitalTab === 'overview'
        ) {
          this.location.replaceState(
            `lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/overview/${this.selectedYearFromYearList}/Q${this.selectedQuarterFromList}/${this.selectedOverViewId}`
          );
        }
        return this.getCapitalOverviewKFWCapitalAccount(
          this.lpsid,
          this.vehicleId,
          this.selectedYearFromYearList,
          this.selectedQuarterFromList,
          this.selectedOverViewId
        );
      }
      case 'ifrsVertical': {
        this.showTableOne = 1;
        this.showTableTwo = 0;
        this.selectedReportTypes = event.viewValue;
        this.selectedOverViewId = event.value;
        this.isTableDataLoading = true;
        if (
          this.selectedYearFromYearList != undefined &&
          this.selectedQuarterFromList != undefined &&
          this.capitalTab === 'overview'
        ) {
          this.location.replaceState(
            `lp/${this.lpsid}/vehicle/${this.vehicleId}/capital-account/overview/${this.selectedYearFromYearList}/Q${this.selectedQuarterFromList}/${this.selectedOverViewId}`
          );
        }
        return this.getCapitalOverviewIFRSVertical(
          this.lpsid,
          this.vehicleId,
          this.selectedYearFromYearList,
          this.selectedQuarterFromList,
          this.selectedOverViewId
        );
      }
    }
  }

  getTransactionDocument(lpsId: any, vehicleId: any, documentId: any) {
    this.apiHttpService
      .get(
        this.documentsService.getDocumentById(lpsId, vehicleId, documentId),
        { responseType: 'arraybuffer' }
      )
      .subscribe(
        (response) => {
          const blob = new Blob([response], {
            type: 'application/pdf; charset=utf-8'
          });
          this.filePath = blob;
          if (blob.size === 0) {
            this.filePath = '';
          }
          this.isLoading = false;
          this.isSelectedTransactionLoading = false;
          this.destroyAndReload();
        },
        (error) => {
          this.filePath = '';
          this.isLoading = false;
          this.isSelectedTransactionLoading = false;
        }
      );
  }

  moveToSelectedTab(tabName: string) {
    for (
      let i = 0;
      i < document.querySelectorAll('.mat-tab-label-content').length;
      i++
    ) {
      if (
        (<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i])
          .innerText == tabName
      ) {
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
      }
    }
  }

  openDialog() {
    this.showFilter = !this.showFilter;
  }
  getUniqByvalue(string: any) {
    return _.uniqBy(string, function (e: any) {
      return e.viewValue;
    }).map(function (data: any) {
      return data;
    });
  }
  getPdfFileName(documentId: string) {
    const fileExtension = '.pdf';
    let decodedString = Base64.decode(documentId);
    let decodedDocumentName = decodedString.substring(
      decodedString.lastIndexOf('/') + 1
    );
    let extension = decodedDocumentName.substring(
      decodedDocumentName.lastIndexOf('.')
    );

    if (extension === fileExtension) {
      return decodedDocumentName;
    } else {
      return '';
    }
  }
  openModelDialog(event: boolean, redirectURL: any) {
    if (event) {
      let dialogRef = this.dialog.open(DialogComponent, {
        data: <ModalDialogData>{
          titleKey: 'Access Denied',
          contentKey: 'access denied',
          positiveMessageKey: 'OK',
          negativeMessageKey: '',
          isValid: '1'
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === '1') {
          this.notificationService.sendNotification({ isInaccessible: false });
          if (redirectURL != '') {
            this.router.navigate([redirectURL]);
          }
        }
      });
    }
  }

  handleTransactionDownload() {
    const isFilteredData =
      this.selectStatus.value !== 'allstatus' ||
      this.selectType.value !== 'alltype' ||
      this.dateControlSelected === true;
    const baseConfigData = this.config.baseData.data;
    const investor = baseConfigData.lps.find(
      (item: any) => item.id === this.lpsid
    );
    this.selectedVehicle = baseConfigData.vehicles.find(
      (item: any) => item.id === this.vehicleId
    );

    let parsedResponse = this.transactionData;
    if (parsedResponse.length > 0) {
      const workbook = new ExcelJS.Workbook();
      const sheetName = `Transactions`;
      const worksheet = workbook.addWorksheet(sheetName, {
        pageSetup: { paperSize: 9, orientation: 'portrait' }
      });
      const fileNameSuffix = isFilteredData ? ' - filtered' : '';
      const fileName = `${this.selectedVehicle.name} - Capital account transactions${fileNameSuffix}`;

      // Set column widths
      worksheet.columns = [
        { width: 15 },
        { width: 15 },
        { width: 13 },
        { width: 13 },
        { width: 15 },
        { width: 10 }
      ];
      worksheet.addRow([`${this.selectedVehicle.name} - Transactions`]);
      worksheet.addRow([`${investor.name}`]);
      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.mergeCells('A1:B1'); // Merge cells A1 and B1

      worksheet.getRow(1).font = {
        name: 'Calibri (Body)',
        size: 14,
        bold: true
      };
      worksheet.getRow(2).font = { size: 11, bold: false };

      const excludedKeys = ['id', 'documentId'];
      const keys = Object.keys(parsedResponse[0]).filter(
        (key) => !excludedKeys.includes(key)
      );
      const headerRow = worksheet.addRow([
        'Title',
        'Type',
        'Sent date',
        'Due date',
        `Amount (in ${this.selectedVehicle.currency})`,
        'Status'
      ]);
      headerRow.font = { name: 'Calibri (Body)', size: 12, bold: true };
      headerRow.eachCell((cell) => {
        cell.border = { bottom: { style: 'thin' } };
      });

      const statusColors: any = {
        OPEN: { bgColor: 'F7A859', fontColor: 'FFFFFF' },
        PAID: { bgColor: '47C091', fontColor: 'FFFFFF' },
        PENDING: { bgColor: 'FF6666', fontColor: 'FFFFFF' }
      };

      parsedResponse.forEach(
        ({
          title,
          type,
          sendDate,
          dueDate,
          amountWithoutFormat,
          status
        }: any) => {
          const formatSendDate = sendDate
            ? this.datepipe.transform(sendDate, 'dd/MMM/yyyy')
            : '-';
          const formatDueDate = dueDate
            ? this.datepipe.transform(dueDate, 'dd/MMM/yyyy')
            : '-';

          const itemStatus =
            status === 'error' ? 'PENDING' : status.toUpperCase();
          const typeMap: any = {
            capitalCall: 'Capital call',
            cashDistribution: 'Cash distribution'
          };
          const formattedType = typeMap[type] || type;
          const row = worksheet.addRow([
            title,
            formattedType,
            formatSendDate,
            formatDueDate,
            amountWithoutFormat,
            itemStatus
          ]);

          // Right-align columns C and D, F in all rows
          row.getCell(3).alignment = { horizontal: 'right' };
          row.getCell(4).alignment = { horizontal: 'right' };
          row.getCell(6).alignment = { horizontal: 'center' };

          const { bgColor, fontColor } = statusColors[itemStatus] || {};
          if (bgColor) {
            row.getCell(6).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: bgColor }
            };
          }

          if (fontColor) {
            row.getCell(6).font = {
              color: { argb: fontColor }
            };
          }
          let cell5 = row.getCell(5);
          cell5.numFmt = '#,##0.00';
        }
      );

      workbook.xlsx.writeBuffer().then((buffer) => {
        // Save the Excel file
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        saveAs(blob, `${fileName}.xlsx`);
      });
    } else {
      console.log('Download Capital Transaction data available...');
    }
  }
}
