<div class="insights" *ngFor="let investment of portfolioInvestments" [id]="investment.labelKey">
  <div class="insights-title">
    {{ portFolioInvestmentSegments[investment.labelKey] }}
  </div>
  <mat-grid-list [cols]="
          (isSmallHandset$ | async)
            ? 1
            : (isHandset$ | async)
            ? 1
            : (isSmallScreen$ | async)
            ? 1
            : 2
        " [rowHeight]="'435px'" gutterSize="24px">
    <mat-grid-tile *ngFor="let box of investment.boxes" class="insights-card">
      <mat-card class="insights-card">
        <div class="card-container">
          <div class="cardHead">
            <div class="cardHeadLeft">
              {{ box.labelKey }}
            </div>
            <div class="cardHeadRight">
              <div *ngIf="box.views.length > 1" class="chart-dropdown">
                <mat-select [value]="box.views[0]" (valueChange)="handleViewChange($event, box)">
                  <mat-option *ngFor="let view of box.views" [value]="view">
                    {{ view.labelKey }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
          <ng-container *ngIf="box.views.length === 1">
            <div class="cardBody">
              <div class="insight-head">
                <div *ngFor="let kpi of box.views[0].kpis" class="item">
                  <p class="value">
                    {{ convertCurrency(kpi) }}
                  </p>
                  <p class="title">{{ kpi.labelKey }}</p>
                </div>
              </div>
              <div class="insight-chart">
                <app-chart *ngIf="box.views[0].type === 'chart'" [chartData]="box.views[0].data"
                  [legendColors]="legendColors"></app-chart>
              </div>
              <div *ngIf="box.views[0].data" class="insight-legends">
                <div *ngFor="
                        let dataset of box.views[0].data.data
                          .datasets;
                        let i = index
                      ">
                  <span class="legend-tile" [ngStyle]="{
                          'background-color':
                            dataset.label !== 'Exits'
                              ? legendColors[i]
                              : '#9ba6b0'
                        }"></span><span class="legend-title">{{
                    dataset.label
                    }}</span>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="
                  box.labelKey ===
                  'Initial investments vs. follow-on investments'
                ">
            <ng-container *ngIf="isInitialVsFollowOnUpdated$ | async">
              <div class="cardBody">
                <div class="insight-head">
                  <div *ngFor="
                          let kpi of selectedInitialAndFollowOnInvestments.kpis
                        " class="item">
                    <p class="value">
                      {{ convertCurrency(kpi) }}
                    </p>
                    <p class="title">{{ kpi.labelKey }}</p>
                  </div>
                </div>
                <div class="insight-chart">
                  <app-chart *ngIf="
                          selectedInitialAndFollowOnInvestments.type ===
                          'chart'
                        " [chartData]="
                          selectedInitialAndFollowOnInvestments.data
                        " [legendColors]="legendColors"></app-chart>
                </div>
                <div *ngIf="
                        selectedInitialAndFollowOnInvestments.data
                      " class="insight-legends">
                  <div *ngFor="
                          let dataset of selectedInitialAndFollowOnInvestments
                            .data.data.datasets;
                          let i = index
                        ">
                    <span class="legend-tile" [ngStyle]="{
                            'background-color':
                              dataset.label !== 'Exits'
                                ? legendColors[i]
                                : '#9ba6b0'
                          }"></span><span class="legend-title">{{
                      dataset.label
                      }}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="
                  box.labelKey ===
                  'Inital investment pacing (No. of deals)'
                ">
            <ng-container *ngIf="isInitialPacingUpdated$ | async">
              <div class="cardBody">
                <div class="insight-head">
                  <div *ngFor="
                          let kpi of selectedInitialInvestmentPacing.kpis
                        " class="item">
                    <p class="value">
                      {{ convertCurrency(kpi) }}
                    </p>
                    <p class="title">{{ kpi.labelKey }}</p>
                  </div>
                </div>
                <div class="insight-chart">
                  <app-chart *ngIf="
                          selectedInitialInvestmentPacing.type ===
                          'chart'
                        " [chartData]="
                          selectedInitialInvestmentPacing.data
                        " [legendColors]="legendColors"></app-chart>
                </div>
                <div *ngIf="selectedInitialInvestmentPacing.data" class="insight-legends">
                  <div *ngFor="
                          let dataset of selectedInitialInvestmentPacing
                            .data.data.datasets;
                          let i = index
                        ">
                    <span class="legend-tile" [ngStyle]="{
                            'background-color':
                              dataset.label !== 'Exits'
                                ? legendColors[i]
                                : '#9ba6b0'
                          }"></span><span class="legend-title">{{
                      dataset.label
                      }}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="
                  box.labelKey ===
                  'Follow-on investment pacing (No. of deals)'
                ">
            <ng-container *ngIf="isFollowOnPacingUpdated$ | async">
              <div class="cardBody">
                <div class="insight-head">
                  <div *ngFor="
                          let kpi of selectedFollowOnInvestmentPacing.kpis
                        " class="item">
                    <p class="value">
                      {{ convertCurrency(kpi) }}
                    </p>
                    <p class="title">{{ kpi.labelKey }}</p>
                  </div>
                </div>
                <div class="insight-chart">
                  <app-chart *ngIf="
                          selectedFollowOnInvestmentPacing.type ===
                          'chart'
                        " [chartData]="
                          selectedFollowOnInvestmentPacing.data
                        " [legendColors]="legendColors"></app-chart>
                </div>
                <div *ngIf="selectedFollowOnInvestmentPacing.data" class="insight-legends">
                  <div *ngFor="
                          let dataset of selectedFollowOnInvestmentPacing
                            .data.data.datasets;
                          let i = index
                        ">
                    <span class="legend-tile" [ngStyle]="{
                            'background-color':
                              dataset.label !== 'Exits'
                                ? legendColors[i]
                                : '#9ba6b0'
                          }"></span><span class="legend-title">{{
                      dataset.label
                      }}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="
                  box.labelKey ===
                  'Average holding period per month'
                ">
            <ng-container *ngIf="isAverageHoldingPeriodUpdated$ | async">
              <div class="cardBody">
                <div class="insight-head">
                  <div *ngFor="
                          let kpi of selectedAverageHoldingPeriod.kpis
                        " class="item">
                    <p class="value">
                      {{ convertCurrency(kpi) }}
                    </p>
                    <p class="title">{{ kpi.labelKey }}</p>
                  </div>
                </div>
                <div class="insight-chart">
                  <app-chart *ngIf="
                          selectedAverageHoldingPeriod.type ===
                          'chart'
                        " [chartData]="
                          selectedAverageHoldingPeriod.data
                        " [legendColors]="legendColors"></app-chart>
                </div>
                <div *ngIf="selectedAverageHoldingPeriod.data" class="insight-legends">
                  <div *ngFor="
                          let dataset of selectedAverageHoldingPeriod
                            .data.data.datasets;
                          let i = index
                        ">
                    <span class="legend-tile" [ngStyle]="{
                            'background-color':
                              dataset.label !== 'Exits'
                                ? legendColors[i]
                                : '#9ba6b0'
                          }"></span><span class="legend-title">{{
                      dataset.label
                      }}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>