export const convertWithDecimal = (
  value: any,
  selectedVehicle: any,
  paramKey: any = false,
  roundOfRequired: boolean = false,
  title: string = ''
) => {
  const MAXIMUM_AMOUNT = 1000000000; // 1 Billion
  if (paramKey === true && value === null) {
    return '-';
  }
  let format;

  if (value != null || value != undefined) {
    if (selectedVehicle) {
      if (
        selectedVehicle.separators &&
        selectedVehicle.separators.thousands === '.' &&
        selectedVehicle.separators.decimal === ','
      ) {
        format = 'de-DE';
      } else {
        format = 'en-US';
      }
    }

    let convertedAmount;

    if (title !== 'IRR') {
      convertedAmount = value.toLocaleString(format, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    } else {
      convertedAmount = value.toLocaleString(format, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
      });
    }

    if (roundOfRequired) {
      if (value >= MAXIMUM_AMOUNT) {
        convertedAmount = value.toLocaleString(format, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
      }
    }

    if (selectedVehicle.negativeNumbers === 'parentheses') {
      let newConvertedAmount =
        convertedAmount.toString().charAt(0) === '-'
          ? '(' +
            convertedAmount
              .toString()
              .substring(1, convertedAmount.toString().length) +
            ')'
          : convertedAmount;
      return newConvertedAmount;
    } else {
      return convertedAmount;
    }
  }
  return 0.0;
};
export const convert = (value: any, type: any, selectedVehicle: any) => {
  if (value != null || value != undefined) {
    let format;
    if (selectedVehicle) {
      if (
        selectedVehicle.separators &&
        selectedVehicle.separators.thousands === '.' &&
        selectedVehicle.separators.decimal === ','
      ) {
        format = 'de-DE';
      } else {
        format = 'en-US';
      }
    }
    const numFor = new Intl.NumberFormat(format);
    let val: any = Math.round(value);
    let convertedAmount = numFor.format(val);
    if (selectedVehicle.negativeNumbers === 'parentheses') {
      const currentval = numFor.format(parseInt(val));
      convertedAmount =
        currentval.toString().charAt(0) === '-'
          ? '(' +
            currentval.toString().substring(1, currentval.toString().length) +
            ')'
          : currentval;
      return convertedAmount;
    } else {
      convertedAmount = numFor.format(parseInt(val));
      return convertedAmount;
    }
  }
  return 0;
};

export const unitConvert = (value: any, selectedVehicle: any) => {
  if (value != null) {
    let format;
    if (selectedVehicle) {
      if (
        selectedVehicle.separators &&
        selectedVehicle.separators.thousands === '.' &&
        selectedVehicle.separators.decimal === ','
      ) {
        format = 'de-DE';
      } else {
        format = 'en-US';
      }
    }
    const numFor = new Intl.NumberFormat(format);
    var val: any = Math.round(value);
    let convertedAmount = { value: numFor.format(val), unit: '' };
    if (selectedVehicle.negativeNumbers === 'parentheses') {
      if (val >= 100000000000 || val <= -100000000000) {
        val = val / 1000000000;
        const currentval = numFor.format(parseInt(val));
        convertedAmount = {
          value:
            currentval.toString().charAt(0) === '-'
              ? '(' +
                currentval
                  .toString()
                  .substring(1, currentval.toString().length) +
                ')'
              : currentval,
          unit: selectedVehicle.abbreviations
            ? selectedVehicle.abbreviations.billion
            : 'bn'
        };
      } else if (val >= 100000000 || val <= -100000000) {
        val = val / 1000000;
        const currentval = numFor.format(parseInt(val));
        convertedAmount = {
          value:
            currentval.toString().charAt(0) === '-'
              ? '(' +
                currentval
                  .toString()
                  .substring(1, currentval.toString().length) +
                ')'
              : currentval,
          unit: selectedVehicle.abbreviations
            ? selectedVehicle.abbreviations.million
            : 'mn'
        };
      } else if (val >= 100000 || val <= -100000) {
        val = val / 1000;

        const currentval = numFor.format(parseInt(val));
        convertedAmount = {
          value:
            currentval.toString().charAt(0) === '-'
              ? '(' +
                currentval
                  .toString()
                  .substring(1, currentval.toString().length) +
                ')'
              : currentval,
          unit: selectedVehicle.abbreviations
            ? selectedVehicle.abbreviations.thousand
            : 'k'
        };
      } else {
        const currentval = numFor.format(parseInt(val));
        convertedAmount = {
          value:
            currentval.toString().charAt(0) === '-'
              ? '(' +
                currentval
                  .toString()
                  .substring(1, currentval.toString().length) +
                ')'
              : currentval,
          unit: ''
        };
      }
      return convertedAmount;
    } else {
      if (val >= 100000000000 || val <= -100000000000) {
        val = val / 1000000000;
        convertedAmount = {
          value: numFor.format(parseInt(val)),
          unit: selectedVehicle.abbreviations
            ? selectedVehicle.abbreviations.billion
            : 'bn'
        };
      } else if (val >= 100000000 || val <= -100000000) {
        val = val / 1000000;
        convertedAmount = {
          value: numFor.format(parseInt(val)),
          unit: selectedVehicle.abbreviations
            ? selectedVehicle.abbreviations.million
            : 'mn'
        };
      } else if (val >= 100000 || val <= -100000) {
        val = val / 1000;

        convertedAmount = {
          value: numFor.format(parseInt(val)),
          unit: selectedVehicle.abbreviations
            ? selectedVehicle.abbreviations.thousand
            : 'k'
        };
      }
      return convertedAmount;
    }
  }
  return { value: '', unit: '' };
};
