import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-folder-template',
  templateUrl: './folder-template.component.html',
  styleUrls: ['./folder-template.component.scss']
})
export class FolderTemplateComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
