import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators
} from '@angular/forms';
import { Column } from 'src/app/shared/components/table/column';
import { TableComponent } from 'src/app/shared/components/table/table.component';
import { UserSettingElements } from './user_settings';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  form: FormGroup;
  isActiveOne:boolean = true
  isActiveTwo:boolean = false
  isActiveThree:boolean = false
  checked!: boolean;
  showOption: boolean = false;
  selected: boolean = false;
  menuTitle: string = 'settings';
  defaultAccess: Array<any> = [
    { label: 'Dashboard', value: 'dashboard', isAuthorized: true },
    { label: 'Insights', value: 'insights', isAuthorized: false },
    { label: 'Capital Account', value: 'capital-account', isAuthorized: true },
    { label: 'Reports', value: 'reports', isAuthorized: false },
    { label: 'Documents', value: 'documents', isAuthorized: true }
  ];

  fundAccessSettings: Array<any> = [
    {
      fund: 'fund_1',
      label: 'Fund 1',
      accessSettings: [
        { label: 'Dashboard', value: 'dashboard', isAuthorized: true },
        { label: 'Insights', value: 'insights', isAuthorized: false },
        {
          label: 'Capital Account',
          value: 'capital-account',
          isAuthorized: false
        },
        { label: 'Reports', value: 'reports', isAuthorized: true },
        { label: 'Documents', value: 'documents', isAuthorized: true }
      ]
    },
    {
      fund: 'fund_2',
      label: 'Fund 2',
      accessSettings: [
        { label: 'Dashboard', value: 'dashboard', isAuthorized: true },
        { label: 'Insights', value: 'insights', isAuthorized: false },
        {
          label: 'Capital Account',
          value: 'capital-account',
          isAuthorized: false
        },
        { label: 'Reports', value: 'reports', isAuthorized: true },
        { label: 'Documents', value: 'documents', isAuthorized: false }
      ]
    },
    {
      fund: 'fund_3',
      label: 'Fund 3',
      accessSettings: [
        { label: 'Dashboard', value: 'dashboard', isAuthorized: false },
        { label: 'Insights', value: 'insights', isAuthorized: true },
        {
          label: 'Capital Account',
          value: 'capital-account',
          isAuthorized: true
        },
        { label: 'Reports', value: 'reports', isAuthorized: false },
        { label: 'Documents', value: 'documents', isAuthorized: true }
      ]
    },
    {
      fund: 'fund_4',
      label: 'Fund 4',
      accessSettings: [
        { label: 'Dashboard', value: 'dashboard', isAuthorized: true },
        { label: 'Insights', value: 'insights', isAuthorized: true },
        {
          label: 'Capital Account',
          value: 'capital-account',
          isAuthorized: false
        },
        { label: 'Reports', value: 'reports', isAuthorized: true },
        { label: 'Documents', value: 'documents', isAuthorized: true }
      ]
    }
  ];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private notificationService: NotificationService
  ) {
    this.form = this.fb.group({
      checkArray: this.fb.array([], [Validators.required])
    });
  }

  openDialog() {
    this.showOption = !this.showOption;
  }
  userSettingsColumn: Array<Column> = [
    {
      columnDef: 'fund',
      header: 'Fund',
      cell: (element: Record<string, any>) => `${element['fund']}`,
      handler: (e: any, element: Record<string, any>) => {
        
      }
    },
    {
      columnDef: 'dashboard',
      header: 'Dashboard',
      isCheckbox: true,
      cell: (element: Record<string, any>) => `${element['dashboard']}`,
      handler: (e: any, element: Record<string, any>) => {
        this.userSettingsData = this.userSettingsData.map(
          (fundSetting: UserSettingElements) => {
            if (fundSetting.fund === element['fund']) {
              fundSetting['dashboard'] = e.checked;
            }

            return fundSetting;
          }
        );
      }
    },
    {
      columnDef: 'insights',
      header: 'Insights',
      isCheckbox: true,
      cell: (element: Record<string, any>) => `${element['insights']}`,
      handler: (e: any, element: Record<string, any>) => {
        this.userSettingsData = this.userSettingsData.map(
          (fundSetting: UserSettingElements) => {
            if (fundSetting.fund === element['fund']) {
              fundSetting['insights'] = e.checked;
            }

            return fundSetting;
          }
        );
      }
    },
    {
      columnDef: 'capital-account',
      header: 'Capital account',
      isCheckbox: true,
      cell: (element: Record<string, any>) => `${element['capital-account']}`,
      handler: (e: any, element: Record<string, any>) => {
        this.userSettingsData = this.userSettingsData.map(
          (fundSetting: UserSettingElements) => {
            if (fundSetting.fund === element['fund']) {
              fundSetting['capital-account'] = e.checked;
            }

            return fundSetting;
          }
        );
      }
    },
    {
      columnDef: 'reports',
      header: 'Reports',
      isCheckbox: true,
      cell: (element: Record<string, any>) => `${element['reports']}`,
      handler: (e: any, element: Record<string, any>) => {
        this.userSettingsData = this.userSettingsData.map(
          (fundSetting: UserSettingElements) => {
            if (fundSetting.fund === element['fund']) {
              fundSetting['reports'] = e.checked;
            }

            return fundSetting;
          }
        );
      }
    },
    {
      columnDef: 'documents',
      header: 'Documents',
      isCheckbox: true,
      cell: (element: Record<string, any>) => {
      },
      handler: (e: any, element: Record<string, any>) => {
        this.userSettingsData = this.userSettingsData.map(
          (fundSetting: UserSettingElements) => {
            if (fundSetting.fund === element['fund']) {
              fundSetting['documents'] = e.checked;
            }

            return fundSetting;
          }
        );
      }
    },
    {
      columnDef: 'more',
      header: 'More',
      isMoreOption: true,
      moreOptions: ['Select all', 'Select none', 'Set to default'],
      cell: (element: Record<string, any>) => `${element['more']}`,
      handler: (option: string, element: Record<string, any>) => {

        let access = option == 'Select all' ? true : false;
        let isDefault = option == 'Set to default' ? true : false;
        let defaultAccess = this.userSettingsData.find(
          (fund) => fund['fund'] == 'Defaults'
        );
        if (!element) {
          this.userSettingsData = this.userSettingsData.map(
            (fundSetting: UserSettingElements) => {
              fundSetting['dashboard'] =
                isDefault && defaultAccess
                  ? defaultAccess['dashboard']
                  : access;
              fundSetting['capital-account'] =
                isDefault && defaultAccess
                  ? defaultAccess['capital-account']
                  : access;
              fundSetting['reports'] =
                isDefault && defaultAccess ? defaultAccess['reports'] : access;
              fundSetting['insights'] =
                isDefault && defaultAccess ? defaultAccess['insights'] : access;
              fundSetting['documents'] =
                isDefault && defaultAccess
                  ? defaultAccess['documents']
                  : access;
              return fundSetting;
            }
          );
        } else {
          this.userSettingsData = this.userSettingsData.map(
            (fundSetting: UserSettingElements) => {
              if (fundSetting.fund === element['fund']) {
                fundSetting['dashboard'] =
                  isDefault && defaultAccess
                    ? defaultAccess['dashboard']
                    : access;
                fundSetting['capital-account'] =
                  isDefault && defaultAccess
                    ? defaultAccess['capital-account']
                    : access;
                fundSetting['reports'] =
                  isDefault && defaultAccess
                    ? defaultAccess['reports']
                    : access;
                fundSetting['insights'] =
                  isDefault && defaultAccess
                    ? defaultAccess['insights']
                    : access;
                fundSetting['documents'] =
                  isDefault && defaultAccess
                    ? defaultAccess['documents']
                    : access;
              }

              return fundSetting;
            }
          );
        }

      }
    }
  ];

  userSettingsData: Array<UserSettingElements> = [
    {
      fund: 'Defaults',
      dashboard: true,
      'capital-account': true,
      reports: true,
      insights: true,
      documents: true,
      more: true,
      disableButtons: ['Set to default']
    },
    {
      fund: 'Fund 1',
      dashboard: true,
      'capital-account': true,
      reports: true,
      insights: true,
      documents: true,
      more: true,
      disableButtons: []
    },
    {
      fund: 'Fund 2',
      dashboard: true,
      'capital-account': false,
      reports: true,
      insights: true,
      documents: true,
      more: true,
      disableButtons: []
    },
    {
      fund: 'Fund 3',
      dashboard: true,
      'capital-account': true,
      reports: true,
      insights: true,
      documents: true,
      more: true,
      disableButtons: []
    },
    {
      fund: 'Fund 4',
      dashboard: true,
      'capital-account': true,
      reports: true,
      insights: true,
      documents: true,
      more: true,
      disableButtons: []
    }
  ];

  onCheckboxChange(e: any) {
    this.defaultAccess = this.defaultAccess.map((settings: any) =>
      settings.value === e.source.name
        ? { ...settings, isAuthorized: e.checked }
        : settings
    );
  }

  onFundAccessCheckboxChange(e: any) {
    this.fundAccessSettings = this.fundAccessSettings.map((fund) => {
      fund.accessSettings = fund.accessSettings.map((settings: any) =>
        fund.fund + '-' + settings.value === e.source.name
          ? { ...settings, isAuthorized: e.checked }
          : settings
      );
      return fund;
    });
  }

  setAllToDefalut() {
    const defaultAccess = this.defaultAccess;
    this.fundAccessSettings = this.fundAccessSettings.map((fund) => {
      fund.accessSettings = defaultAccess;
      return fund;
    });
  }

  AddOrRemoveFundAccess(fundValue: string, access: boolean) {
    this.fundAccessSettings = this.fundAccessSettings.map((fund) => {
      if (fund.fund == fundValue) {
        fund.accessSettings = fund.accessSettings.map((settings: any) => ({
          ...settings,
          isAuthorized: access
        }));
      }
      return fund;
    });
  }

  submitForm() {}

  ngOnInit(): void {
    let messageToSend = 'settings.users';
    this.notificationService.sendNotification({prefix: messageToSend, suffix:''});
  }

  public tabChanged(tabChangeEvent: any): void {
    let messageToSend =
      tabChangeEvent.index === 0
        ? 'settings.users'
        : 'settings.investor';
    this.notificationService.sendNotification({prefix: messageToSend, suffix:''});
  }
  addRemoveClass (type:any) {
    if(type === 1) {
      this.isActiveOne = true
      this.isActiveTwo = false
      this.isActiveThree = false
    }
    if(type === 2) {
      this.isActiveOne = false
      this.isActiveTwo = true
      this.isActiveThree = false
    }
    if(type === 3) {
      this.isActiveOne = false
      this.isActiveTwo = false
      this.isActiveThree = true
    }

  }
}
