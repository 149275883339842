<mat-form-field appearance="fill" class="select-container">
  <mat-select [(value)]="selectedItem" (selectionChange)="onSelectEvent($event)">
    <mat-option [disabled]="item.disabled" *ngFor="let item of items" [value]="item">
      <ng-container *ngIf="translateText">
        <span *ngIf="item.viewValue  === 'kfwCapitalAccount'  || item.viewValue  === 'ifrsVertical'">
          {{ 'translations.dashboard.capital' +'.' + item.viewValue| translate }}
        </span>
      </ng-container>
      <ng-container *ngIf="!translateText">
        {{item.viewValue}}
      </ng-container>
    </mat-option>
  </mat-select>
</mat-form-field>