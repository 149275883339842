import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  maxDate: Date = new Date();
  @ViewChild('fromInput', {
    read: MatInput
  })
  fromInput!: MatInput;

  @Input()
  startDate: any;

  @Output()
  dateSelected: EventEmitter<any> = new EventEmitter<any>();

  selectedDate: any = '';
  constructor() {}

  resetForm() {
    this.fromInput.value = '';
  }
  public onDate(event: any): void {
    this.dateSelected.emit(event.value);
    this.selectedDate = event.value;
  }
  ngOnInit(): void {}
}
