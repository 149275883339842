import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-metric-dialog',
  templateUrl: './metric-dialog.component.html',
  styleUrls: ['./metric-dialog.component.scss']
})
export class MetricDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data:any) { 

  
    
  }


 

}

