import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
 
export class UserService {
  
  constructor() {}
  userDetails:any

  setUserConfig(name:any,interfaceLanguage:any,userId:any) {
    if (name && userId && interfaceLanguage) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          name: name,
          languageCode: interfaceLanguage,
          id: userId
        })
      );
    }
    if (localStorage.getItem('user')) {
      
    } else {
      localStorage.setItem(
        'user',
        JSON.stringify({
          name: 'Wendelin Luther',
          languageCode: 'en',
          id: 1
        })
      );
    }

    this.userDetails = localStorage.getItem('user');
  }

  getUserConfig() {
    return this.userDetails;
  }
}
