export const metrics = [
    { char :'A', metricTitle: 'metricTitle1', metricDiscription: 'metricDiscription1' },
    { char :'B',metricTitle: 'metricTitle2', metricDiscription: 'metricDiscription2' },
    { char :'C',metricTitle: 'metricTitle3', metricDiscription: 'metricDiscription3' },
    { char :'D',metricTitle: 'metricTitle4', metricDiscription: 'metricDiscription4' },
    { char :'E',metricTitle: 'metricTitle5', metricDiscription: 'metricDiscription5' },
    { char :'F',metricTitle: 'metricTitle6', metricDiscription: 'metricDiscription6' }
  ];
  export const calculationsMultiples = [
    { title: 'multiples'},
    { title: 'moic'},
    { title: 'realizedMoic'},
    { title: 'unRealizedMoic'},
    { title: 'groessTvpi'},
    { title: 'netTvpi'},
    { title: 'rvpi'},
    { title: 'dpi'}
  ];
  export const calculationsIRR = [
    { title: 'irr'},
    { title: 'grossirr'},
    { title: 'grossRealizedIrr'},
    { title: 'netIrr'},
    { title: 'netRealizedirr'},
   
  ];

