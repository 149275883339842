import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class CapitalAccountService {

  constructor  (
    private apiEndpointsService: ApiEndpointsService) { 
  }

public getCapitalTransactionData(lpsId: string, vehicleId: string): string {
  return this.apiEndpointsService.createUrl(`lps/${lpsId}/vehicles/${vehicleId}/transactions`, false);
}
public getCapitalOverviewIFRSVertical(lpsId: string, vehicleId: string,year:string,quarter:string,overViewid:string): string{
  return this.apiEndpointsService.createUrl(`lps/${lpsId}/vehicles/${vehicleId}/capital-account-overviews/${year}/${quarter}/${overViewid}`, false);
}
public getCapitalOverviewKFWCapitalAccount(lpsId: string, vehicleId: string,year:string,quarter:string,overViewid:string): string{
  return this.apiEndpointsService.createUrl(`lps/${lpsId}/vehicles/${vehicleId}/capital-account-overviews/${year}/${quarter}/${overViewid}`, false);
}
public getCapitalAccountOverview(lpsId: string, vehicleId: string): string{
  return this.apiEndpointsService.createUrl(`lps/${lpsId}/vehicles/${vehicleId}/capital-account-overviews`, false);
}
public getCapitalAccountFormats(lpsId: string, vehicleId: string): string{

  return this.apiEndpointsService.createUrl(`lps/${lpsId}/vehicles/${vehicleId}/capital-account-formats`, false);
}
}