<table
  mat-table
  [dataSource]="dataSource"
  matSort
  *ngIf="enableSorting; else withoutSorting"
  disableClear="true"
>
  <ng-container
    *ngFor="let disCol of tableColumns; let colIndex = index"
    matColumnDef="{{ disCol.columnDef }}"
  >
    <ng-container *ngIf="disCol.header !== 'Send date'; else unsortedColumn">
      <th
        (click)="handleSort()"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="
          disCol.columnDef !== 'sendDate' && disCol.columnDef !== 'dueDate'
        "
        [ngClass]="[disCol.isCheckbox ? 'checkbox' : 'normal-data']"
        [ngClass]="disCol.columnDef === 'amount' ? 'amount-right-align' : ''"
      >
        <span *ngIf="disCol.header !== 'More'; else otherHeader">
          {{
            'translations.' + [menuTitle] + '.' + disCol.columnDef | translate
          }}
          <span *ngIf="disCol.columnDef === 'amount'">
            (in {{ currentVehicle.currency }})</span
          >
        </span>

        <ng-template #otherHeader>
          <span *ngIf="disCol.isMoreOption" class="option">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button
                *ngFor="
                  let menuOption of disCol.moreOptions;
                  let optIndex = index
                "
                (click)="disCol.handler?.(menuOption)"
                mat-menu-item
              >
                <span>{{
                  'translations.settings.' +
                    menuOption.split(' ').join('').toLocaleLowerCase()
                    | translate
                }}</span>
              </button>
            </mat-menu>
          </span>
        </ng-template>
      </th>
    </ng-container>

    <ng-template #unsortedColumn>
      <th
        (click)="handleSort()"
        mat-sort-header
        mat-header-cell
        *matHeaderCellDef
        [ngClass]="[disCol.isCheckbox ? 'checkbox' : 'normal-data']"
      >
        <span *ngIf="disCol.header !== 'More'; else otherHeader">
          {{
            'translations.' + [menuTitle] + '.' + disCol.columnDef | translate
          }}
        </span>

        <ng-template #otherHeader>
          <span>
            <mat-icon>more_vert</mat-icon>
          </span>
        </ng-template>
      </th>
    </ng-template>

    <td
      mat-cell
      *matCellDef="let element"
      [ngClass]="
        disCol.isCheckbox ? 'checkbox' : element === 'amount' ? 'amount' : ''
      "
    >
      <ng-container
        *ngIf="disCol.cell(element) !== 'undefined'; else cellLoader"
      >
        <span *ngIf="disCol.isCheckbox" class="checkbox">
          <mat-checkbox
            [(ngModel)]="element[disCol.columnDef]"
            [checked]="element[disCol.columnDef]"
            [name]="disCol.columnDef"
            (change)="disCol.handler?.($event,element)"
          >
          </mat-checkbox>
        </span>

        <span
          *ngIf="disCol.isStatus"
          class="status"
          [ngClass]="
            disCol.cell(element) === 'open'
              ? 'open'
              : disCol.cell(element) === 'error'
              ? 'error'
              : 'paid'
          "
        >
          {{
            'translations.' +
              [menuTitle] +
              '.' +
              disCol.cell(element).toLowerCase() | translate
          }}
        </span>

        <span *ngIf="disCol.isMoreOption" class="option">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button
              *ngFor="
                let menuOption of disCol.moreOptions;
                let optIndex = index
              "
              (click)="disCol.handler?.(menuOption,element)"
              disabled="{{ element.disableButtons.indexOf(menuOption) !== -1 }}"
              mat-menu-item
            >
              <span>{{
                'translations.settings.' +
                  menuOption.split(' ').join('').toLocaleLowerCase() | translate
              }}</span>
            </button>
          </mat-menu>
        </span>

        <span
          *ngIf="
            disCol.columnDef === 'sendDate' || disCol.columnDef === 'dueDate'
          "
        >
          {{
            disCol.cell(element) === 'null'
              ? '-'
              : (disCol.cell(element) | date : 'dd. MMM yyyy')
          }}
        </span>

        <span
          *ngIf="
            !disCol.isStatus &&
            !disCol.isCheckbox &&
            !disCol.isMoreOption &&
            !disCol.isDefault &&
            disCol.columnDef != 'dueDate' &&
            disCol.columnDef != 'sendDate'
          "
          [ngClass]="disCol.cell(element) === 'Defaults' ? 'default' : 'funds'"
        >
          <ng-container
            *ngIf="
              !isEnclosedWithParantheses(disCol.cell(element));
              else amountData
            "
          >
            <ng-container *ngIf="disCol.cell(element) === 'Defaults'">
              <span class="otherData">{{
                'translations.settings.' + disCol.cell(element).toLowerCase()
                  | translate
              }}</span>
            </ng-container>
            <span *ngIf="disCol.header === 'Type'">
              {{
                'translations.dashboard.capital' + '.' + disCol.cell(element)
                  | translate
              }}
            </span>
            <ng-container
              *ngIf="
                disCol.cell(element) !== 'Defaults' && disCol.header !== 'Type'
              "
            >
              <span
                class=""
                *ngIf="disCol.columnDef !== 'documentId'; else viewTransaction"
              >
                {{ disCol.cell(element) }}
              </span>

              <ng-template #viewTransaction>
                <img
                  *ngIf="
                    disCol.cell(element) &&
                    disCol.cell(element) !== 'null' &&
                    disCol.cell(element) !== 'undefined'
                  "
                  src="../../../../assets/view-transaction-arrow.svg"
                />
              </ng-template>
            </ng-container>
          </ng-container>
          <ng-template #amountData>
            <span
              [ngClass]="
                isEnclosedWithParantheses(disCol.cell(element))
                  ? 'negativeValue'
                  : 'nonNegativeValue'
              "
            >
              {{ disCol.cell(element) }}
            </span>
          </ng-template>
        </span>
      </ng-container>

      <ng-template #cellLoader>
        <ngx-skeleton-loader
          *ngIf="
            disCol.columnDef === 'reportTitle' ||
            disCol.columnDef === 'sendDate' ||
            disCol.columnDef === 'dueDate'
          "
          count="1"
          [theme]="{
            width: '100px',
            height: '25px',
            'margin-bottom': '0px',
            'margin-top': '6px'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          *ngIf="disCol.columnDef === 'type'"
          count="1"
          [theme]="{
            width: '150px',
            height: '25px',
            'margin-bottom': '0px',
            'margin-top': '6px'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          *ngIf="disCol.columnDef === 'amount'"
          count="1"
          [theme]="{
            width: '150px',
            height: '25px',
            'margin-bottom': '0px',
            'margin-top': '6px'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          *ngIf="disCol.columnDef === 'status'"
          count="1"
          [theme]="{
            width: '70px',
            height: '25px',
            'margin-bottom': '0px',
            'margin-top': '6px'
          }"
        ></ngx-skeleton-loader>
      </ng-template>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="handleSelection(row)"
    [ngClass]="{
      hovered: isClickable && row.hovered,
      highlighted: isClickable && selection.isSelected(row)
    }"
    (mouseover)="row.hovered = true"
    (mouseout)="row.hovered = false"
  ></tr>
</table>

<ng-template #withoutSorting>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    #empTbSort="matSort"
    disableClear="true"
  >
    <ng-container
      *ngFor="let disCol of tableColumns; let colIndex = index"
      matColumnDef="{{ disCol.columnDef }}"
    >
      <th
        (click)="handleSort()"
        mat-header-cell
        *matHeaderCellDef
        [ngClass]="[disCol.isCheckbox ? 'checkbox' : '']"
      >
        <ng-container *ngIf="disCol.header !== ''; else headerLoader">
          <span
            *ngIf="disCol.header !== 'More'; else otherHeader"
            [ngClass]="
              disCol.columnDef === 'totalFund' ||
              disCol.columnDef === 'yourShare'
                ? 'column-right-align'
                : ''
            "
          >
            {{
              translateWithDate(
                'translations.dashboard.capital' + '.' + disCol.header
                  | translate,
                lastDayOfQuarter
                  | date
                    : 'dd. MMM yyyy'
                    : ''
                    : (languageCode === 'de' ? 'de' : 'en'),
                lastDayOfLastQuarter
                  | date
                    : 'dd. MMM yyyy'
                    : ''
                    : (languageCode === 'de' ? 'de' : 'en')
              )
            }}
          </span>
          <ng-template #otherHeader>
            <span>
              <mat-icon>more_vert</mat-icon>
            </span>
          </ng-template>
        </ng-container>
        <ng-template #headerLoader>
          <ngx-skeleton-loader
            *ngIf="disCol.columnDef !== 'title'"
            count="1"
            [theme]="{
              width: '100px',
              height: '25px',
              'margin-bottom': '0px',
              'margin-top': '6px'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            *ngIf="disCol.columnDef === 'title'"
            count="1"
            [theme]="{
              width: '300px',
              height: '25px',
              'margin-bottom': '0px',
              'margin-top': '6px'
            }"
          ></ngx-skeleton-loader>
        </ng-template>
      </th>

      <td
        mat-cell
        *matCellDef="let element"
        [ngClass]="disCol.isCheckbox ? 'checkbox' : 'normal-data'"
      >
        <ng-container *ngIf="disCol.cell(element) !== ''; else cellLoader">
          <span *ngIf="disCol.isCheckbox" class="checkbox">
            <mat-checkbox></mat-checkbox>
          </span>

          <span
            *ngIf="disCol.isStatus"
            class="status"
            [ngClass]="disCol.cell(element) === 'open' ? 'open' : 'paid'"
          >
            {{ disCol.cell(element) }}
          </span>

          <span *ngIf="disCol.isMoreOption" class="option">
            <mat-icon>more_vert</mat-icon>
          </span>

          <span
            *ngIf="
              !disCol.isStatus &&
                !disCol.isCheckbox &&
                !disCol.isMoreOption &&
                isEnclosedWithParantheses(disCol.cell(element));
              else otherData
            "
            [ngClass]="
              (disCol.columnDef === 'totalFund' ||
                disCol.columnDef === 'yourShare') &&
              isEnclosedWithParantheses(disCol.cell(element))
                ? 'negativeValue'
                : 'nonNegativeValue'
            "
          >
            {{ disCol.cell(element) }}
          </span>
          <ng-template #otherData>
            <span
              *ngIf="
                disCol.columnDef !== 'totalFund' &&
                disCol.columnDef !== 'yourShare'
              "
            >
              {{
                translateWithDate(
                  'translations.dashboard.capital' + '.' + disCol.cell(element)
                    | translate,
                  lastDayOfQuarter
                    | date
                      : 'dd. MMM yyyy'
                      : ''
                      : (languageCode === 'de' ? 'de' : 'en'),
                  lastDayOfLastQuarter
                    | date
                      : 'dd. MMM yyyy'
                      : ''
                      : (languageCode === 'de' ? 'de' : 'en')
                )
              }}
            </span>
            <span
              *ngIf="
                disCol.columnDef === 'totalFund' ||
                disCol.columnDef === 'yourShare'
              "
            >
              {{ disCol.cell(element) }}
            </span>
          </ng-template>
        </ng-container>
        <ng-template #cellLoader>
          <ngx-skeleton-loader
            *ngIf="
              disCol.columnDef === 'totalFund' ||
              disCol.columnDef === 'yourShare'
            "
            count="1"
            [theme]="{
              width: '100px',
              height: '25px',
              'margin-bottom': '0px',
              'margin-top': '6px'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            *ngIf="disCol.columnDef === 'title'"
            count="1"
            [theme]="{
              width: '300px',
              height: '25px',
              'margin-bottom': '0px',
              'margin-top': '6px'
            }"
          ></ngx-skeleton-loader>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="handleSelection(row)"
      [ngClass]="{
        hovered: isClickable && row.hovered,
        highlighted: isClickable && selection.isSelected(row),
        bold: row.highlighted ? 'boldRow' : ''
      }"
      (mouseover)="row.hovered = true"
      (mouseout)="row.hovered = false"
    ></tr>
  </table>
</ng-template>
