import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor  (
    private apiEndpointsService: ApiEndpointsService) { 
  }

  public getDocumentById(lpsId: string, vehicleId: string, documentId: string): string {
    return this.apiEndpointsService.createUrl(`lps/${lpsId}/vehicles/${vehicleId}/documents/${documentId}`, false);
  }

  public getDocumentData(lpsId: string, vehicleId: string): string {
    return this.apiEndpointsService.createUrl(`lps/${lpsId}/vehicles/${vehicleId}/documents`, false);
  }
}
