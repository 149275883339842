import { Injectable } from '@angular/core';
import { ApiEndpointsService } from '../core/services/api-endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {

  constructor(
    private apiEndpointsService: ApiEndpointsService
  ) { }
  public getPortfolioCompanyList(lpsId: string, vehicleId: string): any {
    return this.apiEndpointsService.createUrl(`lps/${lpsId}/vehicles/${vehicleId}/insights/portfolio-companies`,true)
  }
  public getCompanyDetail(lpsId: string, vehicleId: string,companyId:string): any {
    return this.apiEndpointsService.createUrl(`lps/${lpsId}/vehicles/${vehicleId}/insights/portfolio-companies/${companyId}`,true)
  }
  public getCompanyOverviewDetail(lpsId: string, vehicleId: string,companyId:string): any {
    return this.apiEndpointsService.createUrl(`lps/${lpsId}/vehicles/${vehicleId}/insights/portfolio-companies/${companyId}/ownership-overview`,true)
  }
}
