import { Component, OnInit, Input } from '@angular/core';
import { bodyType, CardItem, headRightType } from 'src/app/models/card.model';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  constructor() {
    this.cardDetails = {
      title: 'Events',
      headRight: headRightType.toggle,
      bodyContent: bodyType.list
    };
  }

  @Input() cardDetails: CardItem;
  ngOnInit(): void {}
}
