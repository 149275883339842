import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public notificationSubject = new Subject<string>();
  public menuNotificationSubject = new Subject<string>();

  constructor() {}

  sendClickEvent(message: any) {
    this.menuNotificationSubject.next(message);
  }
  getClickEvent(): Observable<any> {
    return this.menuNotificationSubject.asObservable();
  }
  
  sendNotification(message:object) {
    this.notificationSubject.next(JSON.stringify(message));
  }
}
