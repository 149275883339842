<!-- <mat-grid-tile [colspan]="(isSmallHandset$ | async) ? 2 : (isMediumScreen$ | async) ? 2 : 1" [rowspan]="(isXLargeScreen$ | async) ? 4 : (isLargeScreen$ | async) ? 2 :
(isHandset$ | async)
? 3
: (isSmallHandset$ | async)
? 3
: 2"> -->
  <mat-card class="dashboard-card">
    <div class="card-container">
      <div class="cardHead">
        <div class="tile"></div>
        <div class="cardHeadLeft">
          {{ 'translations.dashboard.upcomingEvents.title' | translate }}
        </div>
      </div>
      <div class="cardBody eventsContainer">
        <div class="eventTile">
          <div class="eventTileLeft">
            <p class="day">25</p>
            <p class="month">OCT</p>
          </div>
          <div class="eventTileRight">
            <p class="time">15:00</p>
            <p class="eventName">Quarterly Investor-Call</p>
          </div>
        </div>
        <div class="eventTile">
          <div class="eventTileLeft">
            <p class="day">2</p>
            <p class="month">NOV</p>
          </div>
          <div class="eventTileRight">
            <p class="time">15:00</p>
            <p class="eventName">Quarterly Investor-Call</p>
          </div>
        </div>
        <div class="eventTile">
          <div class="eventTileLeft">
            <p class="day">2</p>
            <p class="month">NOV</p>
          </div>
          <div class="eventTileRight">
            <p class="time">15:00</p>
            <p class="eventName">Quarterly Investor-Call</p>
          </div>
        </div>
        <div class="eventTile">
          <div class="eventTileLeft">
            <p class="day">2</p>
            <p class="month">NOV</p>
          </div>
          <div class="eventTileRight">
            <p class="time">15:00</p>
            <p class="eventName">Quarterly Investor-Call</p>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
<!-- </mat-grid-tile> -->



