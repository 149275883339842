import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor  (
    private apiEndpointsService: ApiEndpointsService) { 
  }

public getQuarterlyReports(lpsId: string, vehicleId: string): string {
  return this.apiEndpointsService.createUrl(`lps/${lpsId}/vehicles/${vehicleId}/quarterly-reports`, false);
}
}
