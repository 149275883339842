<!-- // Desktop screen -->
<div *ngIf="(isHandset$ | async) === false">
  <div class="documents-container">
    <div class="list-container" *ngIf="showlistView">
      <mat-card class="list-card">
        <div class="head">
          <div *ngIf="!showLoader; else backButtonLoader" class="backButton" [ngClass]="isRoot ? 'disabled' : ''">
            <span class="back-img" (click)="handleBack()">
              <img src="../../../assets/images/back_icon.svg" height="24px" width="24px" />
              {{ 'translations.documents.back' | translate }}
            </span>
          </div>
        </div>
        <div class="body">
          <div>
            <ng-container *ngIf="!showLoader; else folderLoader">
              <div *ngIf="showFolders" class="folderList">
                <div class="folderStructure" *ngFor="let folder of folders; index as i"
                  (click)="onFolderNavigation(folder)">
                  <div class="row">
                    <img height="24px" width="24px" src="../../../../assets/images/folder_icon.svg" />
                    <div class="file-metadata">
                      <div>{{ folder.name }}</div>
                    </div>
                  </div>
                  <img height="24px" width="24px" src="../../../../assets/images/arrow_right.svg" />
                </div>
              </div>
              <div *ngIf="showFiles">
                <mat-selection-list [multiple]="false" [(ngModel)]="selectedFile" [compareWith]="compareFiles"
                  class="reportsList" *ngIf="(isHandset$ | async) === false" hideSingleSelectionIndicator="true">
                  <mat-list-option class="fileStructure"
                    [ngClass]="!!selectedFile && selectedFile.name === file.name ? 'selected' : ''"
                    *ngFor="let file of files; index as i" (click)="showWebViewer(file)">
                    <div class="row">
                      <div class="icon" [ngClass]="{'active-icon': !!selectedFile && selectedFile.name === file.name}">
                        <svg width="18" height="18" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6 0.00318003V0H16.9978C17.5513 0 18 0.45531 18 0.9918V19.0082C18 19.556 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5501 0 18.9932V6L6 0.00318003ZM2.82918 6H6V2.83086L2.82918 6ZM8 2V7C8 7.55228 7.5523 8 7 8H2V18H16V2H8Z"
                            fill="#828282" />
                        </svg>
                      </div>
                      <div class="file-metadata">
                        <div>{{ file.name }}</div>
                        <div class="datePostFile">
                          {{ file.creationTime | date : 'dd. MMM yyyy' }}
                        </div>
                      </div>
                    </div>
                  </mat-list-option>
                </mat-selection-list>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-card>
    </div>

    <div class="container-middle-border" *ngIf="!filePath && !isLoading"></div>

    <div class="content-container" *ngIf="showPdfView">
      <div class="content-body" *ngIf="!isLoading; else pdfLoader"
        [ngClass]="{ previewContainer: showViewer === false || !filePath }">
        <div class="viewerTile" *ngIf="filePath" [ngClass]="{ hideViewerContainer: showViewer === false }">
          <div class="viewer">
            <ng-container *ngIf="(isVisible$ | async) && filePath">
              <app-pdf-viewer [filePath]="filePath" [fileName]="selectedFile?.name"></app-pdf-viewer>
            </ng-container>
          </div>
        </div>
        <div class="previewImgContainer" *ngIf="showViewer === true && !filePath && initialLoad" [ngClass]="{
            hidePreviewContainer: showViewer === true && !!filePath
          }">
          <div>
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="56" height="56" rx="28" fill="#F4F4F4" />
              <path
                d="M28.0003 19C33.3924 19 37.8784 22.8798 38.8189 28C37.8784 33.1202 33.3924 37 28.0003 37C22.6081 37 18.1222 33.1202 17.1816 28C18.1222 22.8798 22.6081 19 28.0003 19ZM28.0003 35C32.2359 35 35.8603 32.052 36.7777 28C35.8603 23.948 32.2359 21 28.0003 21C23.7646 21 20.1402 23.948 19.2228 28C20.1402 32.052 23.7646 35 28.0003 35ZM28.0003 32.5C25.515 32.5 23.5003 30.4853 23.5003 28C23.5003 25.5147 25.515 23.5 28.0003 23.5C30.4855 23.5 32.5003 25.5147 32.5003 28C32.5003 30.4853 30.4855 32.5 28.0003 32.5ZM28.0003 30.5C29.381 30.5 30.5003 29.3807 30.5003 28C30.5003 26.6193 29.381 25.5 28.0003 25.5C26.6196 25.5 25.5003 26.6193 25.5003 28C25.5003 29.3807 26.6196 30.5 28.0003 30.5Z"
                fill="#828282" />
            </svg>
          </div>
          <p class="selectMessage">No documents are currently provided for this vehicle</p>
        </div>
        <div class="previewImgContainer" *ngIf="!selectedFile && isVisible$"
          [ngClass]="{ hidePreviewContainer: showViewer === true }">
          <div>
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="56" height="56" rx="28" fill="#F4F4F4" />
              <path
                d="M28.0003 19C33.3924 19 37.8784 22.8798 38.8189 28C37.8784 33.1202 33.3924 37 28.0003 37C22.6081 37 18.1222 33.1202 17.1816 28C18.1222 22.8798 22.6081 19 28.0003 19ZM28.0003 35C32.2359 35 35.8603 32.052 36.7777 28C35.8603 23.948 32.2359 21 28.0003 21C23.7646 21 20.1402 23.948 19.2228 28C20.1402 32.052 23.7646 35 28.0003 35ZM28.0003 32.5C25.515 32.5 23.5003 30.4853 23.5003 28C23.5003 25.5147 25.515 23.5 28.0003 23.5C30.4855 23.5 32.5003 25.5147 32.5003 28C32.5003 30.4853 30.4855 32.5 28.0003 32.5ZM28.0003 30.5C29.381 30.5 30.5003 29.3807 30.5003 28C30.5003 26.6193 29.381 25.5 28.0003 25.5C26.6196 25.5 25.5003 26.6193 25.5003 28C25.5003 29.3807 26.6196 30.5 28.0003 30.5Z"
                fill="#828282" />
            </svg>
          </div>

          <p class="selectMessage">
            {{ 'translations.documents.selectDocument' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- new mobile screen design -->
 <ng-container *ngIf="(isHandset$ | async)">
  <div class="mobile-view-list-container" *ngIf="showlistView && !openPdfSideBar">
    <div class="mob-list-card">
      <div class="head">
        <div *ngIf="!showLoader; else backButtonLoader" class="backButton" [ngClass]="isRoot ? 'disabled' : ''">
          <span class="back-img" (click)="handleBack()">
            <img src="../../../assets/images/back_icon.svg" height="24px" width="24px" />
            {{ 'translations.documents.back' | translate }}
          </span>
        </div>
      </div>
      <div class="mobile-container-body">
        <ng-container *ngIf="!showLoader; else folderLoader">
          <ng-container *ngIf="showFolders">
            <div class="mob-folderStructure" *ngFor="let folder of folders; index as i"
              (click)="onFolderNavigation(folder)">
              <div class="folder-row">
                <div class="folder-container">
                  <img height="24px" width="24px" src="../../../../assets/images/folder_icon.svg" />
                  <div class="folder-name">
                   {{ folder.name }}
                  </div>
                </div>
                <img height="24px" width="24px" src="../../../../assets/images/arrow_right.svg" />
              </div>
            </div>
          </ng-container>
          <div *ngIf="showFiles">
            <mat-selection-list [multiple]="false" [(ngModel)]="selectedFile" [compareWith]="compareFiles"
              class="reportsList" *ngIf="(isHandset$ | async)" hideSingleSelectionIndicator="true">
              <mat-list-option class="fileStructure"
                [ngClass]="!!selectedFile && selectedFile.name === file.name ? 'selected' : ''"
                *ngFor="let file of files; index as i" (click)="showWebViewer(file)">
                <div class="row">
                  <div class="icon" [ngClass]="{'active-icon': !!selectedFile && selectedFile.name === file.name}">
                    <svg width="18" height="18" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6 0.00318003V0H16.9978C17.5513 0 18 0.45531 18 0.9918V19.0082C18 19.556 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5501 0 18.9932V6L6 0.00318003ZM2.82918 6H6V2.83086L2.82918 6ZM8 2V7C8 7.55228 7.5523 8 7 8H2V18H16V2H8Z"
                        fill="#828282" />
                    </svg>
                  </div>
                  <div class="file-metadata">
                    <div>{{ file.name }}</div>
                    <div class="datePostFile">
                      {{ file.creationTime | date : 'dd. MMM yyyy' }}
                    </div>
                  </div>
                  <div class="icon" style="height: 24px; width: 24px;" [ngClass]="{'active-icon': !!selectedFile && selectedFile.name === file.name}">
                    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.0003 0C16.3924 0 20.8784 3.87976 21.8189 9C20.8784 14.1202 16.3924 18 11.0003 18C5.60812 18 1.12215 14.1202 0.181641 9C1.12215 3.87976 5.60812 0 11.0003 0ZM11.0003 16C15.2359 16 18.8603 13.052 19.7777 9C18.8603 4.94803 15.2359 2 11.0003 2C6.7646 2 3.14022 4.94803 2.22278 9C3.14022 13.052 6.7646 16 11.0003 16ZM11.0003 13.5C8.51498 13.5 6.50026 11.4853 6.50026 9C6.50026 6.51472 8.51498 4.5 11.0003 4.5C13.4855 4.5 15.5003 6.51472 15.5003 9C15.5003 11.4853 13.4855 13.5 11.0003 13.5ZM11.0003 11.5C12.381 11.5 13.5003 10.3807 13.5003 9C13.5003 7.6193 12.381 6.5 11.0003 6.5C9.6196 6.5 8.50026 7.6193 8.50026 9C8.50026 10.3807 9.6196 11.5 11.0003 11.5Z" fill="#828282"/>
                    </svg>
                  </div>
                </div>
              </mat-list-option>
            </mat-selection-list>

          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- show pdf for mobile -->
  <!-- ((isVisible$ | async) && filePath && selectedFile && !isLoading) -->
  <mat-sidenav-container class="task-details-container" hasBackdrop="true"  *ngIf="openPdfSideBar">
    <mat-sidenav #drawer mode="over" position="start" role="dialog" class="full-screen-sidenav" 
    [opened]=" openPdfSideBar ? true : false">
      <div class="pdf-container">
        <div class="pdf-header">
          <img src="../../../assets/images/close_icon.svg" height="24px" width="24px" alt="close" class="image-logo"
          (click)="drawer.close();closepdfView()"/>
          <div class="pdf-name">
            {{selectedFile?.name}}
          </div>
          <img src="../../../assets/images/download_icon.svg" height="24px" width="24px" alt="download" style="visibility: hidden;"/>
        </div>
        <div class="margin-pdf"></div>
        <ng-container *ngIf="!isLoading; else mobPdfLoader">
          <div class="viewerTile" *ngIf="filePath" [ngClass]="{ hideViewerContainer: showViewer === false }">
            <div class="viewer">
              <ng-container *ngIf="(isVisible$ | async) && filePath">
                <app-pdf-viewer [filePath]="filePath" [fileName]="selectedFile?.name"></app-pdf-viewer>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-sidenav>
</mat-sidenav-container>
</ng-container>

<ng-template #pdfLoader>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
      width: '100%',
      height: '100%',
      margin: '0px',
      padding: '18px 0 24px 0'
    }"></ngx-skeleton-loader>
</ng-template>

<ng-template #mobPdfLoader>
  <div class="viewerTile">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
      width: '100%',
      height: '95%',
      margin: '0px',
      padding: '18px 0 24px 0'
    }"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #folderLoader>
  <div class="folderLoader" *ngFor="let number of [0, 1, 2, 3]">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '7%',
        'margin-top': '10px',
        'margin-left': '4%',
        height: '25px'
      }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        'margin-top': '10px',
        'margin-left': '2%',
        width: '80%',
        height: '25px'
      }"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #backButtonLoader>
  <div class="backbutton-loader">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '85px',
        height: '38px',
        marginBottom: 0
      }"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #pdfLoaderMobile>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '100%',
        height: 'calc(100vh - 170px)',
        margin: 0,
      }"></ngx-skeleton-loader>
</ng-template>