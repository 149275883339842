<div>
  <mat-card class="card">
    <div class="card-head">
      <div class="left">
        <div class="tile"></div>
        <div class="card-title">
          {{ 'Heading' | translate }}
        </div>
      </div>
      <div class="right">
        <ng-container
          *ngIf="cardDetails.title === 'Events'; then toggleInHead"
        ></ng-container>
        <ng-container
          *ngIf="cardDetails.title === 'Timeline'; then categoriesInHead"
        ></ng-container>
        <ng-container
          *ngIf="
            cardDetails.title === 'Performace' ||
              cardDetails.title === 'Insights';
            then seeDetails
          "
        ></ng-container>
      </div>
    </div>
    <div class="card-body">
      <div>
        <ng-container
          *ngIf="cardDetails.title === ''; then horizontalList"
        ></ng-container>
        <ng-container
          *ngIf="cardDetails.title === ''; then verticallList"
        ></ng-container>
        <ng-container
          *ngIf="cardDetails.title === ''; then newsCard"
        ></ng-container>
      </div>
    </div>
  </mat-card>
</div>

<ng-template #toggleInHead> </ng-template>

<ng-template #categoriesInHead> </ng-template>

<ng-template #seeDetails> </ng-template>

<ng-template #horizontalList> </ng-template>

<ng-template #verticallList> </ng-template>

<ng-template #newsCard> </ng-template>
