<div class="terms-of-use-container">
  <div mat-dialog-content class="popup-container">
    <div class="terms-of-use-card">
      <div class="card-header">
        <span class="heading">LP Portal Terms of use</span>
      </div>
      <div class="card-body">
        <div class="section">
          <div class="title">1. General Provisions</div>
          <br />
          <div class="content">
            1.1 Scope of these Terms. These terms and conditions (as amended by
            ACE Alternatives GmbH from time to time, these “Terms”) govern the
            access to, and use of, this investor portal website, including the
            information, materials and functions contained or embodied therein
            (collectively, the “Site”).
            <br />
            <br />
            These Terms are between (i) ACE Alternatives, together with its
            affiliates (collectively, “ACE Alternatives” or “we” or “our”), (ii)
            you, (iii) the person(s) on whose behalf you are accessing the Site
            (such as an investor or prospective investor), and anyone authorized
            to access the Site on your behalf (collectively “you”).
            <br />
            <br />
            1.2 Intended Use. ACE Alternatives GmbH operates this Site solely on
            behalf of its investment management client(s) (each a “Client” and
            collectively “Clients”). This Site is solely intended for access and
            for use by parties who have subscribed for an investment and been
            accepted as investor(s) (an “Investor” and collectively “Investors”)
            in a certain alternative investment fund(s) or other structure(s)
            managed by Client(s) (hereinafter: the “Fund” or the collectively
            “Funds”; such investment hereinafter the “Investment” and
            collectively “Investments”) and being serviced for Client(s) by ACE
            Alternatives and its affiliates.
            <br />
            <br />
            1.3 Password Protected Access. The Site is password-protected and
            accessible only to users who have been issued passwords by an
            authorized representative of ACE Alternatives. Passwords are issued
            only to current Investors in a Fund, and certain other parties
            approved by ACE Alternatives who are deemed authorized for access to
            the Site pursuant to these Terms.
            <br />
            <br />
            1.4 Authorized User; Responsibility for Users. The Investor has
            confirmed to us that you are an appointed officer, an employee, an
            agent or an independent contractor of the Investor authorized by
            Investor to access the Site, in the name and on behalf of the
            Investor, to review certain information related to the Investor and
            the Investment made by the Investor in the Fund (“Authorized User”).
            <br />
            <br />

            Investor(s) will ensure at all times that:
            <br />
            <ul>
              <li>- only Authorized Users can access and use the Site;</li>
              <li>- all Authorized Users comply with these Terms;</li>
              <li>
                - each Authorized User is assigned a unique login/password by
                utilizing the appropriate authentication and password assignment
                tools which are available the Site;
              </li>
              <li>
                - Authorized User login/password details are kept confidential;
                and
              </li>
              <li>
                - The Site is not used or accessed in relation to any investment
                other than those within a Fund for which the Investor’s
                subscription has been accepted.
              </li>
            </ul>
            For the purposes of these Terms, all activities of (i) Authorized
            Users and (ii) any persons accessing the Site via the login/password
            of an Authorized User (whether or not legitimately) shall be deemed
            the activities of Investor(s).
            <br />
            <br />
            As an Authorized User, by accessing this Site, you (including on
            behalf of any entity you represent) agree to be bound by of these
            Terms.
            <br />
            <br />
            1.5 Compromise or Loss of Credentials. As Authorized User, you are
            responsible for ensuring the security and confidentiality of your
            login credentials, including not sharing those with any other
            person. You shall immediately notify ACE Alternatives at
            support&#64;ace-alternatives.com of any information or event that may
            compromise the security of the Site, including loss or compromise of
            your login credentials. You accept full responsibility for any use
            of your login credentials. ACE Alternatives may suspend or revoke
            your access to and use of the Site at any time in its sole
            discretion.
            <br />
            <br />
            1.6 No separate contractual relationship with you; no investment
            advice; regional regulatory limitations. Your access to this Site is
            being provided as an accommodation to and at the request of an
            Investor to a Fund of a Client being serviced by ACE Alternatives.

            <br />
            <br />
            By accessing this Site, you are acknowledging and agreeing that your
            grant of access and use of the Site and its functionality does not
            establish a contractual, business, client, agency, or third party
            beneficiary relationship between you and ACE Alternatives or its
            affiliates and ACE Alternatives and its affiliates expressly
            disclaim any liability for damages or losses (whether asserted on
            the basis of contract, tort or otherwise) related to your access and
            use of this Site. Investor’s use of group email addresses with
            respect to access to this Site is solely at the risk of the investor
            and ACE Alternatives accepts no responsibility regarding such use by
            the investor.

            <br />
            <br />
            FURTHERMORE, THE CONTENTS HEREOF ARE NOT INTENDED FOR ACCESS BY,
            DISTRIBUTION TO, OR USE BY, ANY PERSON OR ENTITY IN ANY JURISDICTION
            OR COUNTRY WHERE SUCH ACCESS, DISTRIBUTION OR USE WOULD BE CONTRARY
            TO LOCAL LAW OR REGULATION.
          </div>
        </div>
        <div class="section">
          <div class="title">2. Important Disclosures and Disclaimers</div>
          <br />
          <div class="content">
            2.1 Review Everything Carefully and Completely. The Site may contain
            performance information for the Funds managed by Clients of ACE
            Alternatives. An investment in any Fund may not be suitable to all
            investors, and neither the Clients nor ACE Alternatives guarantee
            their performance. The returns from such an investment may rise and
            fall and are not guaranteed, and investors may lose all or some of
            their initial capital invested. There can be no assurance that any
            Fund will achieve its investment objective or avoid substantial
            losses, and performance may be volatile. You acknowledge that past
            performance is not indicative of future results.
            <br />
            <br />
            2.2 No Reliance. While ACE Alternatives and its Clients use
            reasonable efforts to update the information contained in the Site,
            the Clients and ACE Alternatives make no representations or
            warranties as to the accuracy, reliability or completeness of any
            information on the Site. Any content on the Site is subject to
            change without notice.
            <br />
            <br />
            2.3 No Advice. The Site is not intended to supply tax, legal,
            accounting or investment advice, and the materials (or any part of
            them) are not intended to be construed as a recommendation to invest
            in any Fund. If you are in any doubt as to the matters or contents
            contained on the Site you should obtain independent professional
            advice. By accepting these Terms, you acknowledge that the Site and
            any of its materials are for your private, non-commercial
            informational purposes only and that the use and interpretation of
            the Site and any of its materials require a specific and in-depth
            knowledge of the financial markets.
            <br />
            <br />
            2.4 Confidentiality. By accessing the Site, you agree that you will
            maintain as confidential all information, materials and functions
            contained or embodied therein, including information concerning the
            Client(s) of ACE Alternatives and the Funds (including all reports
            and notices received for a Fund). You will not disclose such
            information to any person, except for information that is otherwise
            publicly available or required to be disclosed by law, and you will
            not use any such information in any manner which could reasonably be
            expected to be adverse to the best interests of the respective
            Client of ACE Alternatives or any Fund. You agree to use your best
            efforts to safeguard such information and to prevent unauthorized,
            negligent or inadvertent use or disclosure thereof. You may,
            however, share such information with the relevant Investor’s
            investment advisers, beneficial owners, accountants and/or
            attorneys, provided that such other persons undertake: (a) to hold
            such information confidential to the same extent set forth in these
            Terms; and (b) not to use any such information in any manner which
            could reasonably be expected to be adverse to the best interests of
            the Client of ACE Alternatives or any Fund. For the avoidance of
            doubt, you may not provide information concerning the Client of ACE
            Alternatives or the Funds to any third party, knowing that such
            third party may use such information in any form of printed,
            electronic or “on-line” publication, newsletter or circular, whether
            publicly or privately distributed. For the avoidance of doubt, these
            obligations are in addition to any confidentiality obligations to
            which you may otherwise be subject.
          </div>
        </div>
        <div class="section">
          <div class="title">3. LIMITATION OF LIABILITY</div>
          <br />
          <div class="content">
            3.1. General limitation of liability.
            <br />
            <br />
            ACE ALTERNATIVES DOES NOT MAKE ANY REPRESENTATION, WARRANTY OR
            PROMISE THAT THE SITE OR ANY MATERIALS OR FEATURES AVAILABLE ON OR
            THROUGH THE SITE WILL BE ACCURATE, SECURE, COMPLETE, ERROR-FREE,
            TIMELY OR UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED.
            <br />
            <br />
            THE INFORMATION AND CONTENT ON THIS SITE ARE PROVIDED ON AN "AS-IS",
            "AS AVAILABLE" BASIS.
            <br />
            <br />
            TO THE EXTENT PERMISSIBLE BY APPLICABLE LAW, ACE ALTERNATIVES
            DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY
            WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND
            FITNESS FOR A PARTICULAR PURPOSE WITH RESPECT TO THE SITE AND THE
            MATERIALS AND FEATURES AVAILABLE ON OR THROUGH THE SITE, AND FREEDOM
            FROM VIRUSES OR OTHER CONTAMINATION. YOU ASSUME TOTAL RESPONSIBILITY
            FOR YOUR USE OF THE SITE.
            <br />
            <br />
            TO THE EXTENT PERMISSIBLE BY APPLICABLE LAW, THE LIABILITY OF ACE
            ALTERNATIVES, ITS CLIENT(S) OR FUNDS SERVICED BY ACE ALTERNATIVES
            FOR ITS CLIENTS, WILL BE EXCLUDED FOR ANY CONSEQUENTIAL, INCIDENTAL,
            SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES ARISING OUT OF ANY USE OF OR
            INABILITY TO USE THE SITE, REGARDLESS OF WHETHER ACE ALTERNATIVES,
            THE CLIENT OR THE FUND SERVICED BY ACE ALTERNATIVES HAS BEEN
            APPRISED OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING AND REGARDLESS
            OF THE FORM OF ACTION, WHETHER IN CONTRACT, WARRANTY, TORT
            (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE.
            <br />
            <br />

            3.2 Third-Party Content. The Site may include services, general news
            and information, commentary, research and other data supplied by
            companies that are not affiliated with ACE Alternatives or its
            clients (“Third Party Content”). ACE Alternatives has not been
            involved in the preparation or editing of Third Party Content and
            does not explicitly or implicitly endorse or approve such content or
            services. ACE Alternatives does not guarantee the accuracy,
            timeliness, completeness or utility of Third Party Content, and ACE
            Alternatives shall not be responsible or liable for any such content
            or services. Your access to Third Party Content or other websites is
            at your own risk and is subject to their own privacy policies.
            <br />
            <br />
            3.3 Third-Party Links. ACE Alternatives may provide a link to other
            sites through this Site. ACE Alternatives does not control any
            third-party site and makes no representations concerning the content
            of any such site and expressly disclaims any liability with respect
            thereto.
            <br />
            <br />
            3. Access.
            <br />
            <br />
            ACE Alternatives takes reasonable and appropriate measures to
            protect information we receive through the Site and to ensure that
            information is only disclosed to you. However, the Internet is an
            open system and there is no absolute guarantee that the information
            you have sent to us or retrieve will not be intercepted by others
            and you assume that risk in accessing this Site.

            <br />
            <br />
            ACE Alternatives reserves the right to monitor and audit all
            transactions and data (including e-mail messages) for any purpose
            sent over this network and your use of this Site unless prohibited
            by law. You agree that a printed version of these terms and
            conditions shall be admissible in judicial or administrative
            proceedings to the same extent as other business documents and
            records.

            <br />
            <br />
            Neither ACE Alternatives nor its relevant Client(s) guarantee that
            the Site, and any feature thereof, will always be available.

            <br />
            <br />
            ACE Alternatives may suspend or terminate your access to this Site,
            or may terminate provision of this Site or any portion thereof, for
            any or no reason at any time, subject to the terms of any separate
            written agreement between the Client of ACE, the Fund or other
            investment structure which has requested your access to this Site.
          </div>
        </div>
        <div class="section">
          <div class="title">4. Intellectual Property Rights and License</div>
          <br />
          <div class="content">
            4.1 ACE Alternatives retains any and all intellectual property and
            other proprietary rights (including moral rights) in and to the
            Site, together with any and all upgrades, updates, enhancements,
            improvements, modifications and derivative works thereof. The Site
            is protected by applicable copyright, trade dress, patent and
            trademark laws, international conventions and other laws protecting
            intellectual property and related proprietary rights. Subject to
            your agreement and compliance with these Terms, ACE Alternatives
            grants you a limited, revocable, non-exclusive, non-transferable,
            non-sublicensable license to access and use the Site strictly and
            solely for the prupose as described in section 1. above.
            <br />
            <br />
            4.2 You shall not, nor shall you cause or permit any other party to:
            (i) modify, adapt, copy, alter, publish, print, disclose, download,
            translate or create derivative works of the Site; (ii) disable,
            disrupt, circumvent or otherwise interfere with any feature,
            functionality or control that prevents, restricts or otherwise
            governs access to, or use of, any aspect of the Site; (iii) build a
            product or service using similar ideas, features, functions or
            graphics of, or otherwise contained within, the Site; (iv)
            sublicense, resell, rent, lease, transfer or assign the Site or the
            use thereof, or to offer the Site on a time-share basis to any third
            party; (v) reverse engineer, decompile, decode or disassemble the
            Site (or any part thereof); (vi) attempt to derive the source code
            for the Site (or any part thereof) or attempt to gain access to any
            underlying code used to implement or deploy the Site, or (vii)
            otherwise exploit or use the Site in any way except as strictly set
            forth in these Terms.
          </div>
        </div>
        <div class="section">
          <div class="title">5. Miscellaneous Matters</div>
          <br />
          <div class="content">
            5.1 Privacy. Information about how ACE Alternatives processes your
            personal information in relation to the use of the Site is located
            in our Privacy Notice [here], which are incorporated into and forms
            a part of these Terms.
            <br />
            <br />
            5.2 Completeness. These Terms constitute the entire understanding
            between ACE Alternatives, the Client, the Investor, and you as
            Authorized User, concerning the Site; except that nothing in these
            Terms is intended to supersede or abrogate any provision of a Fund’s
            constituent or governing documents or relevant subscription
            documents, or terms applicable to materials available on the Site.
            These Terms do not govern the terms of any investment the Investor,
            or you, may make with a Client of ACE Alternatives, or any other
            aspect of relationship with a Client of ACE Alternatives, beyond
            your use of or access to the Site.
            <br />
            <br />
            5.3 Amendments. These Terms are subject to change. ACE Alternatives
            reserves the right to modify these Terms, which it may do by posting
            changes to the Site. If you do not agree with these terms and
            conditions, please do not access the Site.
            <br />
            <br />
            5.4 Governing Law and Place of Venue. The access to and the use of
            the Site by Authorized Users Site in accordance with these Terms,
            and these Terms including the limitation of liability, are governed
            by the laws of the Federal Republic of Germany, notwithstanding any
            principles of conflicts of law. Any dispute in connection with the
            Site and these Terms will be exclusively settled by the courts of
            Berlin in the Federal Republic of Germany.
            <br />
            <br />
            5.5 Questions and Notice. For information, support or questions or
            to provide notice pursuant to these Terms, please contact us at:
            <span class="mailTo" (click)="mailTo()"
              >support&#64;ace-alternatives.com</span
            >
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="checkbox" *ngIf="!data.confirmedTermsOfUse">
          <mat-checkbox class="agreement" (change)="toggleCheckbox($event)">
            I confirm that I agree to these terms of use.
          </mat-checkbox>
        </div>
        <div class="acknowledgement" *ngIf="data.confirmedTermsOfUse">
          <span class="message">
            You agreed to these terms of use on
            <span class="date">
              {{ data.confirmedTermsOfUse | date : 'dd MMM, yyyy' }}
            </span>
            at
            <span>
              {{ data.confirmedTermsOfUse | date : 'h:mm a' }}
            </span>
          </span>
        </div>
        <mat-dialog-actions class="actions">
          <div>
            <button
              class="button-warn"
              [disabled]="!checkboxChecked && !data.confirmedTermsOfUse"
              mat-button
              (click)="submitTermsOfUse()"
            >
              OK
            </button>
          </div>
        </mat-dialog-actions>
      </div>
    </div>
  </div>
</div>
