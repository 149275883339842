<div class="portfolio-companies-overview">
    <mat-tab-group dynamicHeight mat-align-tabs="start" class="invisible-headers">
        <mat-tab label="First Page">
            <div class="company-overview-container">
                <div class="back-button">
                    <button mat-raised-button (click)="backNavigation()">
                        <img src="../../../../assets/arrow-back-gray.svg" />
                        <span class="text">Back</span></button>
                </div>
               
                <div class="overview-container" >
                    <mat-grid-list cols="2" rowHeight="20px" gutterSize="20px">
                        <mat-grid-tile rowspan="13">
                            <mat-card>
                                <div class="card-container">
                                    <div class="cardHead">
                                        <div>
                                            <div class="tile"></div>
                                            <div class="cardHeadLeft">
                                                <span *ngIf="companyOverviewData; else companyNameLoader">{{companyOverviewData.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cardBody" *ngIf="companyOverviewData">
                                        <div class="company-details">
                                            <div class="logo-description">
                                                <div class="logo">
                                                    <img src={{companyOverviewData.logoPath}} alt="Company Logo">
                                                </div>
                                                <div class="description">
                                                    <p>
                                                        {{companyOverviewData.summary}}
                                                        <!-- “36Zero Vision” is an AI-powered visual quality inspection solution that offers fully automated, end-to-end, part tracking and fault identification for optimized agility and quality, factory throughput and capital spending. -->
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="other-details">
                                                <div class="detail legal-name">
                                                    <div class="icon">
                                                        <img src="../../../../../assets/home-icon.png" alt="Legal Name">
                                                    </div>
                                                    <div class="content">
                                                        <p class="value"> {{companyOverviewData.legalName}} </p>
                                                        <p class="title"> Legal name </p>
                                                    </div>
                                                </div>
                                                <div class="detail location">
                                                    <div class="icon">
                                                        <img src="../../../../../assets/location-icon.png" alt="Legal Name">
                                                    </div>
                                                    <div class="content">
                                                        <p class="value"> {{companyOverviewData.domicile}}</p>
                                                        <p class="title"> Domicile </p>
                                                    </div>
                                                </div>
                                                <div class="detail website">
                                                    <div class="icon">
                                                        <img src="../../../../../assets/website-icon.png" alt="Legal Name">
                                                    </div>
                                                    <div class="content">
                                                        <p class="value"> {{companyOverviewData.websiteUrl}} </p>
                                                        <p class="title"> Website </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile rowspan="14">
                            <mat-card>
                                <div class="card-container">
                                    <div class="cardHead">
                                        <div>
                                            <div class="tile"></div>
                                            <div class="cardHeadLeft">
                                                Business metrics
                                                <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cardBody">
                                        <div class="business-metrics-container">
                                            <div class="business-metrics-table">
                                                <div class="table-header">
                                                    <div class="column"><span class="table-title name">Name</span></div>
                                                    <div class="column"><span class="table-title">Current values</span></div>
                                                    <div class="column"><span class="table-title">Plan vs. actual</span></div>
                                                    <div class="column"><span class="table-title">Previous values</span></div>
                                                    <div class="column"><span class="table-title">QoQ</span></div>
                                                    <div class="column"><span class="table-title">YoY</span></div>
                                                </div>
                                                <div class="table-body-container">
                                                    <ng-container *ngIf="companyOverviewData; else businessMetricTableLoader">
                                                        <div class="table-body" tabindex="-1" [ngClass]="'row-'+ i" *ngFor="let data of companyOverviewData.businessMetrics; let i = index;">
                                                            <div class="column column-0"><span class="table-data">{{data.name}}</span></div>
                                                            <div class="column column-1"><span class="table-data">{{data.value.current}}</span></div>
                                                            <div class="column column-2"><span class="table-data">{{data.comparison.planVsActual}}</span></div>
                                                            <div class="column column-3"><span class="table-data">{{data.value.previous}}</span></div>
                                                            <div class="column column-4"><span class="table-data">{{data.comparison.quarterOnQuarter}}</span></div>
                                                            <div class="column column-5"><span class="table-data">{{data.comparison.yearOnYear}}</span></div>
                                                        </div>
                                                    </ng-container>

                                                    <ng-template #businessMetricTableLoader>
                                                        <div class="table-body" tabindex="-1" [ngClass]="'row-'+ i" *ngFor="let data of [{name: 'ARR'}, {name: 'EBITDA'}, {name: 'Headcount'}, {name: 'Headcount2'}]; let i = index;">
                                                            <div class="column column-0"><span class="table-data">{{data.name}}</span></div>
                                                            <div class="column column-1"><span class="table-data">
                                                                <ngx-skeleton-loader count="1" [theme]="{
                                                                    'width': '70px',
                                                                    'height': '20px',
                                                                    'margin': '1em 0 0 0'
                                                                  }" ></ngx-skeleton-loader>
                                                            </span></div>
                                                            <div class="column column-2"><span class="table-data">
                                                                <ngx-skeleton-loader count="1" [theme]="{
                                                                    'width': '70px',
                                                                    'height': '20px',
                                                                    'margin': '1em 0 0 0'
                                                                  }" ></ngx-skeleton-loader>
                                                            </span></div>
                                                            <div class="column column-3"><span class="table-data">
                                                                <ngx-skeleton-loader count="1" [theme]="{
                                                                    'width': '70px',
                                                                    'height': '20px',
                                                                    'margin': '1em 0 0 0'
                                                                  }" ></ngx-skeleton-loader>
                                                            </span></div>
                                                            <div class="column column-4"><span class="table-data">
                                                                <ngx-skeleton-loader count="1" [theme]="{
                                                                    'width': '70px',
                                                                    'height': '20px',
                                                                    'margin': '1em 0 0 0'
                                                                  }" ></ngx-skeleton-loader>
                                                            </span></div>
                                                            <div class="column column-5"><span class="table-data">
                                                                <ngx-skeleton-loader count="1" [theme]="{
                                                                    'width': '70px',
                                                                    'height': '20px',
                                                                    'margin': '1em 0 0 0'
                                                                  }" ></ngx-skeleton-loader>
                                                            </span></div>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="companyOverviewData; else businessMetricGraphLoader">
                                                <div class="business-metrics-graph">
                                                    <img src="../../../../../assets/ARR.svg" alt="ARR">
                                                </div>
                                            </ng-container>
                                           <ng-template #businessMetricGraphLoader>
                                            <div class="loader-container">
                                                <div class="loader-head">
                                                    <ngx-skeleton-loader count="1" [theme]="{
                                                        'width': '80px',
                                                        'height': '25px'
                                                      }" ></ngx-skeleton-loader>
                                                </div>
                                                <div class="loader-body">
                                                  <div>
                                                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                      'height': '10px',
                                                      'border-radius': '0px'
                                                    }"></ngx-skeleton-loader>
                                                  </div>
                                                  <div>
                                                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                      'height': '20px',
                                                      'border-radius': '0px'
                                                    }"></ngx-skeleton-loader>
                                                  </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                        'height': '30px',
                                                        'border-radius': '0px'
                                                      }"></ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                        'height': '40px',
                                                        'border-radius': '0px'
                                                      }"></ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                        'height': '50px',
                                                        'border-radius': '0px'
                                                      }"></ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                        'height': '60px',
                                                        'border-radius': '0px'
                                                      }"></ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                        'height': '70px',
                                                        'border-radius': '0px'
                                                      }"></ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                        'height': '80px',
                                                        'border-radius': '0px'
                                                      }"></ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                      'height': '90px',
                                                      'border-radius': '0px'
                                                    }"></ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                        'height': '100px',
                                                        'border-radius': '0px'
                                                      }"></ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                        'height': '110px',
                                                        'border-radius': '0px'
                                                      }"></ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                        'height': '120px',
                                                        'border-radius': '0px'
                                                      }"></ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                      'height': '130px',
                                                      'border-radius': '0px'
                                                    }"></ngx-skeleton-loader>
                                                  </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                        'height': '140px',
                                                        'border-radius': '0px'
                                                      }"></ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                        'height': '150px',
                                                        'border-radius': '0px'
                                                      }"></ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                        'height': '160px',
                                                        'border-radius': '0px'
                                                      }"></ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                        'height': '170px',
                                                        'border-radius': '0px'
                                                      }"></ngx-skeleton-loader>
                                                    </div>
                                                    <div>
                                                      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                        'height': '180px',
                                                        'border-radius': '0px'
                                                      }"></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                              </div>
                                           </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile rowspan="10">
                            <mat-card>
                                <div class="card-container">
                                    <div class="cardHead">
                                        <div>
                                            <div class="tile"></div>
                                            <div class="cardHeadLeft">
                                                Ownership overview
                                                <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                                            </div>
                                        </div>
                                        <div class="cardHeadRight">
                                            <a (click)="handleMenuClick('stakeholders')">
                                              {{ 'translations.dashboard.seeDetails' | translate }}
                                            </a>
                                          </div>
                                    </div>
                                    <div class="cardBody" >
                                        <div class="ownership-overview">
                                            <div class="segment">
                                                <div class="heading"><span>Outstanding</span></div>
                                                <div class="ownership-data" *ngIf="companyOverviewData; else ownershipOverviewLoader;">
                                                    <p class="value">{{companyOverviewData.outstanding.ownedShares}}</p>
                                                    <p class="title">Owned shares</p>
                                                </div>
                                                <div class="ownership-data" *ngIf="companyOverviewData; else ownershipOverviewLoader;">
                                                    <p class="value">{{convertWithDecimal(companyOverviewData.outstanding.fundOwnership)}}%</p>
                                                    <p class="title">Found ownership</p>
                                                </div>
                                                <div class="ownership-data" *ngIf="companyOverviewData; else ownershipOverviewLoader;">
                                                    <p class="value">{{companyOverviewData.outstanding.totalNumberOfShares}}</p>
                                                    <p class="title">Total number of shares</p>
                                                </div>
                                            </div>
                                            <div class="segment">
                                                <div class="heading"><span>Fully Diluted</span></div>
                                                <div class="ownership-data" *ngIf="companyOverviewData; else ownershipOverviewLoader;">
                                                    <p class="value">{{companyOverviewData.fullyDiluted.ownedShares}}</p>
                                                    <p class="title">Owned shares</p>
                                                </div>
                                                <div class="ownership-data" *ngIf="companyOverviewData; else ownershipOverviewLoader;">
                                                    <p class="value">{{convertWithDecimal(companyOverviewData.fullyDiluted.fundOwnership)}}%</p>
                                                    <p class="title">Found ownership</p>
                                                </div>
                                                <div class="ownership-data" *ngIf="companyOverviewData; else ownershipOverviewLoader;">
                                                    <p class="value">{{companyOverviewData.fullyDiluted.totalNumberOfShares}}</p>
                                                    <p class="title">Total number of shares</p>
                                                </div>
                                            </div>
                                            <div class="segment">
                                                <div class="heading heading-3"><span>Board Representation</span></div>
                                                <div class="ownership-data" *ngIf="companyOverviewData; else ownershipOverviewLoader;">
                                                    <p class="value">{{companyOverviewData.board.numberOfSeats}}</p>
                                                    <p class="title">Number of board seats</p>
                                                </div>
                                                <div class="ownership-data" *ngIf="companyOverviewData; else ownershipOverviewLoader;">
                                                    <p class="value">{{companyOverviewData.board.numberOfSeats}}</p>
                                                    <p class="title">Board member</p>
                                                </div>
                                                <div class="ownership-data" *ngIf="companyOverviewData; else ownershipOverviewLoader;">
                                                    <p class="value">{{companyOverviewData.board.members}}</p>
                                                    <p class="title">Board member</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile rowspan="9">
                            <mat-card>
                                <div class="card-container">
                                    <div class="cardHead">
                                        <div>
                                            <div class="tile"></div>
                                            <div class="cardHeadLeft">
                                                Top 5 stakeholders
                                            </div>
                                        </div>
                                        <div class="cardHeadRight">
                                            <a (click)="handleMenuClick('ownership-overview')">
                                              {{ 'translations.dashboard.seeDetails' | translate }}
                                            </a>
                                          </div>
                                    </div>
                                    <div class="cardBody" >
                                        <div class="stakeholders">
                                            <div class="stakeholders-table">
                                                <div class="table-header">
                                                    <div class="column">
                                                       <span>Name</span>
                                                    </div>
                                                    <div class="column">
                                                       <span>Owned shares (FD)</span>
                                                    </div>
                                                    <div class="column">
                                                        <span>Ownership (FD)</span>
                                                    </div>
                                                </div>
                                                <ng-container *ngIf="companyOverviewData; else stakeholderLoader">
                                                    <div class="table-body">
                                                        <div class="row" *ngFor="let data of companyOverviewData.topStakeholders">
                                                            <div class="column">
                                                                <span>{{data.name}}</span>
                                                             </div>
                                                             <div class="column">
                                                                <span>{{convertToDigits(data.ownedShares)}}</span>
                                                             </div>
                                                             <div class="column">
                                                                 <span>{{convertWithDecimal(data.ownership)}}%</span>
                                                             </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-template #stakeholderLoader>
                                                    <div class="table-body">
                                                        <div class="row" *ngFor="let data of [{}, {}, {}, {}, {}]">
                                                            <div class="column">
                                                                <ngx-skeleton-loader count="1" [theme]="{
                                                                    'width': '120px',
                                                                    'height': '25px'
                                                                  }" ></ngx-skeleton-loader>
                                                             </div>
                                                             <div class="column">
                                                                <ngx-skeleton-loader count="1" [theme]="{
                                                                    'width': '120px',
                                                                    'height': '25px',
                                                                    'float': 'right'
                                                                  }" ></ngx-skeleton-loader>
                                                             </div>
                                                             <div class="column">
                                                                <ngx-skeleton-loader count="1" [theme]="{
                                                                    'width': '120px',
                                                                    'height': '25px',
                                                                    'float': 'right'
                                                                  }" ></ngx-skeleton-loader>
                                                             </div>
                                                        </div>
                                                    </div>
                                                   
                                                  </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>
                        </mat-grid-tile>
                    </mat-grid-list>
    
                </div>
                <div class="noRecord" *ngIf="!companyOverviewData || companyOverviewData.length==0">No records found</div>
            </div>
            
        </mat-tab>
       
        <mat-tab label="Second Page">
            <div class="back-button">
                <button mat-raised-button (click)="backToOverview()">
                    <img src="../../../../assets/arrow-back-gray.svg" />
                    <span class="text">Back</span></button>
            </div>
            <div class="ownershipOverviewPage" *ngIf="selectedPage === 'ownership-overview'">
                <mat-grid-list cols="30" rowHeight="10px" gutterSize="20px">
                    <mat-grid-tile rowspan="17" colspan="17">
                        <mat-card>
                            <div class="card-container">
                                <div class="cardHead">
                                    <div>
                                        <div class="tile"></div>
                                        <div class="cardHeadLeft">
                                            Cap table
                                            <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                                        </div>
                                    </div>
                                </div>
                                <div class="cardBody">
                                    <div class="cap-tab-container">
                                        <div class="table-header">
                                            <div class="column">
                                                <span>Stakeholder</span>
                                            </div>
                                            <div class="column">
                                                <span>
                                                    <select>
                                                        <option value="series-a">
                                                            Series A
                                                        </option>
                                                    </select>
                                                </span>
                                            </div>
                                            <div class="column">
                                                <span>
                                                    Total shares OS
                                                </span>
                                            </div>
                                            <div class="column">
                                                <span>Total shares FD</span>
                                            </div>
                                            <div class="column">
                                                <span>
                                                    Ownership % OS
                                                </span>
                                            </div>
                                            <div class="column">
                                                <span>
                                                    Ownership % FD
                                                </span>
                                            </div>
                                        </div>
                                        <div class="table-body">
                                            <div class="row" *ngFor="let data of capTableData">
                                                <div class="column">
                                                    <span>{{data.stakeholder}}</span>
                                                </div>
                                                <div class="column">
                                                    <span>{{data.investmentRound}}</span>
                                                </div>
                                                <div class="column">
                                                    <span>{{data.totalSharesOS}}</span>
                                                </div>
                                                <div class="column">
                                                    <span>{{data.totalSharesFD}}</span>
                                                </div>
                                                <div class="column">
                                                    <span>{{data.ownershipOS}}</span>
                                                </div>
                                                <div class="column">
                                                    <span>{{data.ownershipFD}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile rowspan="15" colspan="13">
                        <mat-card>
                            <div class="card-container">
                                <div class="cardHead">
                                    <div>
                                        <div class="tile"></div>
                                        <div class="cardHeadLeft">
                                            Cap table evolution
                                            <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                                        </div>
                                    </div>
                                </div>
                                <div class="cardBody">
                                    <div class="cap-table-evolution-container">
                                        <div class="evolution-graph">
                                             <img src="../../../../../assets/cap-table-evolution.svg" alt="Cap Table Evolution"> 
                                        </div>
                                        <div class="evolution-legends">
                                            <div class="legends-1">
                                                <div>
                                                    <span class="tile"></span>
                                                    <span class="title">ABC Ventures</span>
                                                </div>
                                                <div>
                                                    <span class="tile"></span>
                                                    <span class="title">Greth Ventures</span>
                                                </div>
                                                <div>
                                                    <span class="tile"></span>
                                                    <span class="title">Always Capital</span>
                                                </div>
                                            </div>
                                            <div class="legends-2">
                                                <div>
                                                    <span class="tile"></span>
                                                    <span class="title">VC Adventures</span>
                                                </div>
                                                <div>
                                                    <span class="tile"></span>
                                                    <span class="title">UniBank</span>
                                                </div>
                                                <div>
                                                    <span class="tile"></span>
                                                    <span class="title">Neu VC</span>
                                                </div>
                                                <div>
                                                    <span class="tile"></span>
                                                    <span class="title">FutureNext</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
            <div class="stakeholdersPage" *ngIf="selectedPage === 'stakeholders'">
                <mat-grid-list cols="30" rowHeight="10px" gutterSize="20px">
                    <mat-grid-tile rowspan="14" colspan="14">
                        <mat-card>
                            <div class="card-container">
                                <div class="cardHead">
                                    <div>
                                        <div class="tile"></div>
                                        <div class="cardHeadLeft">
                                            Latest investment
                                            <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                                        </div>
                                    </div>
                                </div>
                                <div class="cardBody">
                                        <div class="latestInvestments">
                                            <div class="segment">
                                                <div class="heading"><span>Details</span></div>
                                                <div class="ownership-data">
                                                    <p class="value"> {{companyOverviewDetails.latestInvestment.date | date: 'dd MMM yyyy'}}</p>
                                                    <p class="title">Date</p>
                                                </div>
                                                <div class="ownership-data">
                                                    <p class="value">{{companyOverviewDetails.latestInvestment.securityType}}</p>
                                                    <p class="title">Security type</p>
                                                </div>
                                                <div class="ownership-data">
                                                    <p class="value"></p>
                                                    <p class="title"></p>
                                                </div>
                                            </div>
                                            <div class="segment">
                                                <div class="heading"><span>Company Values</span></div>
                                                <div class="ownership-data">
                                                    <p class="value">{{convertWithDecimal(companyOverviewDetails.latestInvestment.preMoneyValuation)}}</p>
                                                    <p class="title">Pre-money valuation</p>
                                                </div>
                                                <div class="ownership-data">
                                                    <p class="value">{{convertWithDecimal(companyOverviewDetails.latestInvestment.roundSize)}}</p>
                                                    <p class="title">Round size</p>
                                                </div>
                                                <div class="ownership-data">
                                                    <p class="value">{{convertWithDecimal(companyOverviewDetails.latestInvestment.postMoneyValuation)}}</p>
                                                    <p class="title">Post money valuation</p>
                                                </div>
                                            </div>
                                            <div class="segment">
                                                <div class="heading heading-3"><span>Acquired Value</span></div>
                                                <div class="ownership-data">
                                                    <p class="value">{{convertWithDecimal(companyOverviewDetails.latestInvestment.purchasedSecurities)}}</p>
                                                    <p class="title">Purchased securities</p>
                                                </div>
                                                <div class="ownership-data">
                                                    <p class="value">{{convertWithDecimal(companyOverviewDetails.latestInvestment.pricePerShare)}}</p>
                                                    <p class="title">Price per share</p>
                                                </div>
                                                <div class="ownership-data">
                                                    <p class="value">{{convertWithDecimal(companyOverviewDetails.latestInvestment.acquiredValue)}}</p>
                                                    <p class="title">Acquired Value</p>
                                                </div>
                                            </div>
                                            <div class="segment">
                                                <div class="heading heading-3"><span>Value Creation</span></div>
                                                <div class="ownership-data">
                                                    <p class="value">{{convertWithDecimal(companyOverviewDetails.latestInvestment.changeInUnrealizedValue)}}</p>
                                                    <p class="title">Change in unrealized value</p>
                                                </div>
                                                <div class="ownership-data">
                                                    <p class="value">{{convertWithDecimal(companyOverviewDetails.latestInvestment.realizedProceeds)}}</p>
                                                    <p class="title">Realized proceeds</p>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile rowspan="8" colspan="16">
                        <mat-card>
                            <div class="card-container">
                                <div class="cardHead">
                                    <div>
                                        <div class="tile"></div>
                                        <div class="cardHeadLeft">
                                            Fund return
                                            <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                                        </div>
                                    </div>
                                </div>
                                <div class="cardBody">
                                    <div class="fund-return-container">
                                        <div class="fund-return">
                                            <div class="totalCommitment box">
                                                <p class="value">$ {{companyOverviewDetails.fundReturn.totalCommitment}}</p>
                                                <p class="title">Total commitment</p>
                                            </div>
                                            <div class="proceeds box">
                                                <p class="value">$ {{companyOverviewDetails.fundReturn.proceeds}}</p>
                                                <p class="title">Proceeds</p>
                                            </div>
                                            <div class="totalCapitalGain box">
                                                <p class="value">$ {{companyOverviewDetails.fundReturn.totalCapitalGain}}</p>
                                                <p class="title">Total capital gain</p>
                                            </div>
                                        </div>
                                        <div class="fundOwnership">
                                            <span>Fund ownership {{companyOverviewDetails.fundReturn.fundOwnership}}%</span>
                                        </div>
                                    </div>
                                </div>
                                </div>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile rowspan="10" colspan="16">
                        <mat-card>
                            <div class="card-container">
                                <div class="cardHead">
                                    <div>
                                        <div class="tile"></div>
                                        <div class="cardHeadLeft">
                                            Financing rounds
                                            <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                                        </div>
                                    </div>
                                </div>
                                <div class="cardBody">
                                    <div class="financing-rounds-container">
                                        <div class="financing-rounds-table">
                                            <div class="table-header">
                                                <div class="column"><span class="table-title item">Item</span></div>
                                                <div class="column"><span class="table-title">Date</span></div>
                                                <div class="column"><span class="table-title">Fund</span></div>
                                                <div class="column"><span class="table-title">Total</span></div>
                                                
                                            </div>
                                            <div class="table-body" [ngClass]="'row-'+ i" *ngFor="let data of companyOverviewDetails.financingRounds; let i = index;">
                                                <div class="column column-0"><span class="table-data">{{data.type}}</span></div>
                                                <div class="column column-1"><span class="table-data">{{data.date | date: 'dd MMM yyyy'}}</span></div>
                                                <div class="column column-2"><span class="table-data value-data">$ {{data.fundAmount}} m</span></div>
                                                <div class="column column-2"><span class="table-data value-data">$ {{data.totalAmount}} m</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                        </mat-card>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
            
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #companyNameLoader>
    <ngx-skeleton-loader count="1" [theme]="{
      'width': '100px',
      'height': '25px'
    }" ></ngx-skeleton-loader>
  </ng-template>

  <ng-template #ownershipOverviewLoader>
   <div class="ownership-data">
       <p class="value" style="margin: 0 !important;">
        <ngx-skeleton-loader count="1" [theme]="{
            'width': '150px',
            'height': '25px'
          }" ></ngx-skeleton-loader>
       </p>
       <p class="title" style="margin: 0 !important;">
        <ngx-skeleton-loader count="1" [theme]="{
            'width': '100px',
            'height': '20px'
          }" ></ngx-skeleton-loader>
       </p>
   </div>
  </ng-template>

 
 
