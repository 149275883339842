import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { ApiHttpService } from '../../services/api-http.service';
import { DocumentsService } from 'src/app/services/documents.service';
import { Base64 } from 'js-base64';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalDialogData } from 'src/app/models/modelDialog.model';

interface FolderTreeSchema {
  value: string;
  viewValue: Array<string>;
}
@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, AfterViewChecked {
  isVisible$ = new BehaviorSubject(true);
  currentNavigation: any;
  filePath: any;
  selectedFolderValue: any = {};
  selectedFile: any;
  showLoader: boolean = false;
  isLoading: boolean = false;
  showFolders: boolean = true;
  showFiles: boolean = true;
  showViewer: boolean = false;
  pageNumber: number = 1;
  initialLoad: boolean = false;
  currentFolder: any;
  isRoot: boolean = true;
  documentId: any;
  files: any;
  fileNames: Array<any> = ['Document1.pdf', 'Document2.pdf'];
  fileList: Array<any> | undefined;
  documentData: any = { name: 'root', type: 'folder' };
  folders: any;
  suffix: string = '';
  documentRoutes: string = '/Documents';
  lpId: any;
  vehicleId: any;
  isInaccessible: boolean = false;
  openPdfSideBar: boolean = false;
  destroyAndReload() {
    this.isVisible$.next(false);
    setTimeout(() => {
      this.isVisible$.next(true);
    }, 0);
  }

  showlistView: boolean = true;
  showPdfView: boolean = true;

  showlistViewFunction() {
    this.showlistView = true;
    this.showPdfView = false;
  }

  showPdfViewFunction() {
    this.showPdfView = true;
    this.showlistView = false;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.XSmall, Breakpoints.Small])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private notificationService: NotificationService,
    private apiHttpService: ApiHttpService,
    private documentsService: DocumentsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _location: Location,
    public dialog: MatDialog
  ) { }
  ngAfterViewChecked(): void {
    this.documentId && this.scrollToview();
  }
  onFolderNavigation(folderValue: any): void {
    this.filePath = '';
    let children: any = folderValue.content;
    this.selectedFile = null;
    this.showViewer = false;
    this.isRoot = false;
    if (children != undefined) {
      this.folders = children.filter((data: any) => data.type === 'folder');
      this.files = children.filter((data: any) => data.type === 'file');

      this.showFiles = true;
      this.showFolders = true;
      this.selectedFolderValue = folderValue;

      const suffixSplitted = this.suffix.split(' / ');
      this.suffix = this.getFileExtension(suffixSplitted[suffixSplitted.length - 1]) === 'pdf' ? `${suffixSplitted.slice(0, -1).join(' / ')} / ${folderValue.name}` : `${this.suffix} / ${folderValue.name}`;

      const documentRoutesSplitted = this.documentRoutes.split('/');
      this.documentRoutes = this.getFileExtension(documentRoutesSplitted[documentRoutesSplitted.length - 1]) === 'pdf' ? `${documentRoutesSplitted.slice(0, -1).join('/')}/${folderValue.name}` : `${this.documentRoutes}/${folderValue.name}`;

      let encodedString = Base64.encode(this.documentRoutes);
      this._location.replaceState(
        `lp/${this.lpId}/vehicle/${this.vehicleId}/documents/${encodedString}`
      );
      this.notificationService.sendNotification({
        prefix: '',
        suffix: this.suffix
      });
    }
  }

  async handleBack() {
    this.openPdfSideBar = false;
    let parentFound = this.findParentPath(
      this.documentData,
      this.selectedFolderValue.name
    );
    if (parentFound) {
      this.selectedFolderValue = parentFound;
    }
    this.selectedFile = null;
    this.showViewer = false;
    this.destroyAndReload();
    if (this.selectedFolderValue.name === 'root') {
      this.documentRoutes = '/Documents';
      //this.router.navigate([`lp/${this.lpId}/vehicle/${this.vehicleId}/documents`])
      this._location.replaceState(
        `lp/${this.lpId}/vehicle/${this.vehicleId}/documents`
      );
      this.files = this.documentData.content.filter(
        (data: any) => data.type === 'file'
      );
      this.folders = this.documentData.content.filter(
        (data: any) => data.type === 'folder'
      );
      this.selectedFolderValue = null;
      this.suffix = '';
      this.isRoot = true;
    } else {
      let encodedString = Base64.encode(this.documentRoutes);
      //this.router.navigate([`lp/${this.lpId}/vehicle/${this.vehicleId}/documents/${encodedString}`])
      this._location.replaceState(
        `lp/${this.lpId}/vehicle/${this.vehicleId}/documents/${encodedString}`
      );
      this.files = this.selectedFolderValue.content.filter(
        (data: any) => data.type === 'file'
      );
      this.folders = this.selectedFolderValue.content.filter(
        (data: any) => data.type === 'folder'
      );
    }
    this.showFiles = true;
    this.showFolders = true;
    this.notificationService.sendNotification({
      prefix: '',
      suffix: this.suffix
    });
    this.filePath = ''
  }

  showWebViewer(file: any) {
    this.isLoading = true;
    this.openPdfSideBar = true;
    if (!this.showViewer) {
      this.suffix += ` / ${file.name}`;
      this.documentRoutes += `/${file.name}`;
    } else {
      let suffices = this.suffix.split('/ ');
      let currentFile = suffices[suffices.length - 1];
      if (currentFile !== file.name) {
        let currentDocumentRoutes = this.documentRoutes.split('/');
        currentDocumentRoutes[currentDocumentRoutes.length - 1] = file.name;
        suffices[suffices.length - 1] = file.name;
        this.suffix = suffices.join('/ ');
        this.documentRoutes = currentDocumentRoutes.join('/');
      }
    }
    let encodedString = Base64.encode(this.documentRoutes);
    //this.router.navigate([`lp/${this.lpId}/vehicle/${this.vehicleId}/documents/${encodedString}`])
    this._location.replaceState(
      `lp/${this.lpId}/vehicle/${this.vehicleId}/documents/${encodedString}`
    );
    this.selectedFile = file;
    this.showViewer = true;
    this.pageNumber = 1;
    this.showViewer = true;
    this.pageNumber = 1;
    this.getDocumentById(this.lpId, this.vehicleId, file.documentId);
    this.initialLoad = true;
    if (this.selectedFolderValue) {
      this.notificationService.sendNotification({
        prefix: '',
        suffix: this.suffix
      });
    } else {
      this.notificationService.sendNotification({
        prefix: '',
        suffix: this.suffix
      });
    }
  }

  findParentPath(data: any, name: any): any {
    if (data.content) {
      for (let child of data.content) {
        const childMatching = data.content.filter(
          (current: any) => current.name === name
        );
        if (childMatching.length) {
          this.suffix = ` / ${data.name}`;
          this.documentRoutes = `/Documents/${data.name}`;
          return data;
        }
        const result = this.findParentPath(child, name);
        if (result) {
          return result;
        }
      }
    }
  }

  ngOnInit() {
    this.lpId = this.activatedRoute.snapshot.params['lpId'];
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.documentId = this.activatedRoute.snapshot.params['documentParams'];
    const { lps, vehicle, navigationData } = history.state;
    this.showLoader = true;
    this.getDocumentData(this.lpId, this.vehicleId);
    this.currentNavigation = navigationData;
  }

  compareFiles(o1: any, o2: any) {
    if (o1.documentId == o2.documentId && o1.name == o2.name) return true;
    else return false;
  }

  getFileExtension(filename: any) {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? '' : ext[1];
  }

  getDocumentData(lpsId: any, vehicleId: any) {
    this.apiHttpService
      .get(this.documentsService.getDocumentData(lpsId, vehicleId))
      .subscribe(
        (response) => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.documentData.content = parsedResponse.data;
          this.folders = this.documentData.content.filter(
            (data: any) => data.type === 'folder'
          );
          this.files = this.documentData.content.filter(
            (data: any) => data.type === 'file'
          );
          this.showLoader = false;
          let showInvalidmessage = false;

          if (this.documentId) {
            let isValidDocumentPath = Base64.decode(this.documentId);
            showInvalidmessage = isValidDocumentPath.length > 0 ? false : true;
            if (isValidDocumentPath.length > 0) {
              let documentPath = Base64.decode(this.documentId);
              const documentPathSplitted = documentPath.split('/');
              const fileName =
                this.getFileExtension(
                  documentPathSplitted[documentPathSplitted.length - 1]
                ) === 'pdf'
                  ? documentPathSplitted[documentPathSplitted.length - 1]
                  : undefined;
              const folderNames = fileName
                ? documentPathSplitted.slice(2, -1)
                : documentPathSplitted.slice(2, documentPathSplitted.length);
              showInvalidmessage = folderNames.length > 0 ? false : true;
              if (folderNames.length) {
                for (let folderNow of folderNames) {
                  let selectedFolder = this.folders.find(
                    (folder: any) => folder.name === folderNow
                  );
                  if (selectedFolder) this.onFolderNavigation(selectedFolder);
                  else {
                    showInvalidmessage = true;
                  }
                }
                if (fileName) {
                  const selectedFile = this.files.find(
                    (file: any) => file.name === fileName
                  );
                  this.showWebViewer(selectedFile);
                }
              }
            }
          }
          //to show invlid popup on invalid path
          if (showInvalidmessage) {
            this.notificationService.sendNotification({ isInaccessible: true });
            this.openDialog(true);
          }
        },
        (error) => {
          this.filePath = '';
        }
      );
  }

  getDocumentById(lpsId: any, vehicleId: any, documentId: any) {
    this.apiHttpService
      .get(
        this.documentsService.getDocumentById(lpsId, vehicleId, documentId),
        { responseType: 'arraybuffer' }
      )
      .subscribe(
        (response) => {
          const blob = new Blob([response], {
            type: 'application/pdf; charset=utf-8'
          });
          this.filePath = blob;
          this.isLoading = false;
          this.destroyAndReload();
        },
        (error) => {
          this.isLoading = false;
          this.filePath = '';
        }
      );
  }
  openDialog(event: boolean) {
    if (event) {
      this.isInaccessible = true;
      let dialogRef = this.dialog.open(DialogComponent, {
        data: <ModalDialogData>{
          titleKey: 'Access Denied',
          contentKey: 'access denied',
          positiveMessageKey: 'OK',
          negativeMessageKey: '',
          isValid: '1'
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === '1') {
          this.notificationService.sendNotification({ isInaccessible: false });
          this.router.navigate([
            `lp/${this.lpId}/vehicle/${this.vehicleId}/documents`
          ]);
        }
      });
    }
  }
  scrollToview() {
    const elementToScrollTo = document.querySelector('.selected');
    if (elementToScrollTo) {
      elementToScrollTo?.scrollIntoView();
    }
  }
  closepdfView() {
    this.filePath = ''; 
    this.selectedFile = '';
    this.openPdfSideBar = false;
    const path = this.suffix
      .split('/')
      .map(x => x.trim())
      .filter(x => x)
      .slice(0, 1)
      .map(x => x.split(' ').join('-'))
      .join('/');
     const encodedString = Base64.encode(`/Documents/${this.suffix.split('/').map(x => x.trim()).filter(x => x).slice(0, 1).map(x => x.trim()).join('/')}`)
    this._location.replaceState(
      `lp/${this.lpId}/vehicle/${this.vehicleId}/documents/${encodedString}`
    );
    // this.router.navigate([`lp/${this.lpId}/vehicle/${this.vehicleId}/documents/${encodedString}`]);
    this.notificationService.sendNotification({
      prefix: '',
      suffix: path
    });
  }
}
