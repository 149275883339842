import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  constructor(private httpClient: HttpClient) {}

  getLanguages() {
    let languages = [
      {
        languageName: 'English',
        languageCode: 'en'
      },
      {
        languageName: 'German',
        languageCode: 'de'
      }
    ];

    return languages;
  }
}
