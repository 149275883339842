import { BehaviorSubject } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReportsService } from 'src/app/services/reports.service';
import { ApiHttpService } from '../../services/api-http.service';
import { DocumentsService } from 'src/app/services/documents.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, Observable, shareReplay } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalDialogData } from 'src/app/models/modelDialog.model';
import { ConfigService } from 'src/app/initializer/config.service';

interface FolderTreeSchema {
  value: string;
  viewValue: Array<string>;
}

interface DropdownSchema {
  value: string;
  viewValue: string;
}
interface tableOfContentSchema {
  value: string;
  viewValue: string;
  pageToBeShown: number;
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  currentNavigation: any;
  isVisible$ = new BehaviorSubject(true);
  frontendBaseUrl: string | undefined;
  isLoading: boolean = false;
  lpsid: any;
  vehicleId: any;
  quarterlyReports: any;

  showlistView: boolean = true;
  showPdfView: boolean = true;
  tocToBeHighlighted: any;
  isInaccessible: boolean = false;
  isDropdownChanged: boolean = false;

  showlistViewFunction() {
    this.showlistView = true;
    this.showPdfView = false;
  }

  showPdfViewFunction() {
    this.showPdfView = true;
    this.showlistView = false;
  }
  selectedReport: any;
  reportsByYear: any;
  quartersByYear: any;
  filteredQuarters: any;
  selectedReportData: any;

  destroyAndReload() {
    this.isVisible$.next(false);
    setTimeout(() => {
      this.isVisible$.next(true);
    }, 0);
  }
  filePath: any;
  showFolders: boolean = true;
  showFiles: boolean = false;
  showViewer: boolean = true;
  showAnnualViewer: boolean = false;
  currentTab: number = 1;
  reportTitle: string = '';
  folderValue: string = '';
  selectedFile: string = '';
  selectedTabType: number = 0;
  currentTOCFromChild: any;
  showNoReports = true;

  public tabChanged(tabChangeEvent: any): void {
    if (tabChangeEvent.index) {
      this.selectedTabType = tabChangeEvent.index;
      const translatedMessageAnnual = 'reports.annual';
      this.notificationService.sendNotification({
        prefix: translatedMessageAnnual,
        suffix: ''
      });
    } else {
      this.selectedTabType = tabChangeEvent.index;
      this.reportTitle = this.reportsList[0].value;
      this.notificationService.sendNotification({
        prefix: '',
        suffix: this.selectedYear + ' / ' + this.selectedQuarter.viewValue
      });
    }

    if (tabChangeEvent.index === 1) {
      this.showViewer = false;
      this.currentTab = tabChangeEvent.index;
    } else {
      this.showViewer = true;
      this.showFolders = true;
      this.showFiles = false;
      this.currentTab = tabChangeEvent.index;
    }
    this.destroyAndReload();
  }

  folderList: FolderTreeSchema[] = [
    { value: '2021', viewValue: ['JCF1-0061_Tax_Report_2021'] }
  ];

  fileList: Array<string> = [];

  years: DropdownSchema[] = [];
  reportsList: any[] = [
    { value: 'fundOverview', title: 'Fund Overview', page: 4 },
    {
      value: 'fundPerformanceStatus',
      title: 'Fund Performance and Status',
      page: 9
    },
    {
      value: 'fundFinancialStatements',
      title: 'Fund Financial Statements and Reconciliations',
      page: 11
    },
    {
      value: 'scheduleOfInvestmentsAndFairValue',
      title: 'Schedule of Investments and Fair Value',
      page: 14
    },
    {
      value: 'portfolioCompanies',
      title: 'Portfolio Companies',
      page: 17
    },
    {
      value: 'capitalAccountsTable',
      title: 'Capital Accounts Table',
      page: 19
    },
    { value: 'definitions', title: 'Definitions', page: 21 }
  ];

  selectedYear: any;
  selectedQuarter: any;
  selectedTitle = this.reportsList[0].title;
  pageNumber: any;

  onPageChange(
    page: number,
    reportTitle: string,
    pageScroll: boolean = false
  ): void {
    this.tocToBeHighlighted = this.selectedQuarter.value.toc.findLast(
      (content: any) => content.page <= page
    );
    if (reportTitle) {
      this.reportTitle = reportTitle;
    }
    if (page) {
      if (this.pageNumber === page) {
        this.pageNumber = Math.abs(page) * -1;
      } else {
        this.pageNumber = page;
      }
    }
    if (pageScroll) {
      this.cdr.detectChanges();
    }
  }

  handlePageChangeFromChild(page: any) {
    this.tocToBeHighlighted = this.selectedQuarter.value.toc.findLast(
      (content: any) => content.page <= page
    );
    this.cdr.detectChanges();

    const currentTOC = this.selectedQuarter.value.toc.find(
      (content: any) => content.page === page
    );
    if (currentTOC) {
      this.currentTOCFromChild = currentTOC;
      this.reportTitle = this.currentTOCFromChild.title;
    }
  }

  onFolderNavigation(folderValue: string): void {
    this.folderValue = folderValue;

    let childrens: FolderTreeSchema | undefined = this.folderList.find(
      (folder) => folder.value == folderValue
    );

    if (childrens != undefined && 'viewValue' in childrens) {
      this.fileList = childrens['viewValue'];
      this.showFiles = true;
      this.showFolders = false;
      const translatedMessage = 'reports.annual';
      this.notificationService.sendNotification({
        prefix: translatedMessage,
        suffix: folderValue
      });
    }
  }

  handleBack() {
    this.showFiles = false;
    this.showFolders = true;
    this.notificationService.sendNotification({ prefix: 'remove', suffix: '' });
  }

  showWebViewer(file: string) {
    this.selectedFile = file;
    const translatedMessage = 'reports.annual';
    this.notificationService.sendNotification({
      prefix: translatedMessage,
      suffix: this.folderValue + ' / ' + this.selectedFile
    });
    this.showViewer = true;
    this.showAnnualViewer = false;
    this.pageNumber = 1;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(['(max-width: 600px)'])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private notificationService: NotificationService,
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ReportsService,
    private breakpointObserver: BreakpointObserver,
    private documentsService: DocumentsService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private config: ConfigService
  ) {
    this.frontendBaseUrl = environment.frontendBaseUrl;
  }

  ngOnInit() {
    let baseconfigData = this.config.baseData.data;
    this.lpsid = this.activatedRoute.snapshot.params['lpId'];
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];

    let selectedInvestor = baseconfigData.lps.find(
      (item: any) => item.id === this.lpsid
    );
    this.lpsid =
      selectedInvestor == undefined ? baseconfigData.lps[0].id : this.lpsid;

    if (
      this.activatedRoute.snapshot.params['year'] &&
      this.activatedRoute.snapshot.params['quarter']
    ) {
      this.selectedYear = parseInt(
        this.activatedRoute.snapshot.params['year'].replace(/^\D+/g, '')
      );
      this.selectedQuarter = parseInt(
        this.activatedRoute.snapshot.params['quarter'].replace(/^\D+/g, '')
      );
    }

    this.selectedTabType = 0;
    const { navigationData } = history.state;
    this.currentNavigation = navigationData;

    this.getQuarterlyReports(this.lpsid, this.vehicleId);
    this.reportTitle = this.reportsList[0].value;
  }

  getReportDocumentById(lpsId: any, vehicleId: any, documentId: any) {
    this.apiHttpService
      .get(
        this.documentsService.getDocumentById(lpsId, vehicleId, documentId),
        { responseType: 'arraybuffer' }
      )
      .subscribe(
        (response) => {
          const blob = new Blob([response], {
            type: 'application/pdf; charset=utf-8'
          });
          this.filePath = blob;
          this.isLoading = false;
          this.destroyAndReload();
        },
        (error) => {
          this.filePath = '';
          this.isLoading = false;
        }
      );
  }

  getQuarterlyReports(lpsId: any, vehicleId: any) {
    this.apiHttpService
      .get(this.apiEndpointsService.getQuarterlyReports(lpsId, vehicleId))
      .subscribe((response: any) => {
        if (response?.data.length > 0) {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.quarterlyReports = parsedResponse.data;
          this.years = this.quarterlyReports
            .filter(
              (obj: any, index: any) =>
                this.quarterlyReports.findIndex(
                  (item: any) => item.year === obj.year
                ) === index
            )
            .map((obj: any) => {
              return { value: obj.year, viewValue: obj.year };
            })
            .sort((a: any, b: any) => parseInt(b.value) - parseInt(a.value));
          if (
            !this.years.find((year: any) => year.value === this.selectedYear)
          ) {
            this.selectedYear = this.years[0].value;
          }
          if (this.selectedYear) {
            let navigatedYear = this.years.find(
              (year: any) => year.value === this.selectedYear
            );
            if (navigatedYear) {
              this.selectedYear = navigatedYear.value;
            }
          } else {
            this.selectedYear = this.years[0].value;
          }

          const groupByYear = this.groupBy('year');
          this.reportsByYear = groupByYear(this.quarterlyReports);
          this.quartersByYear = Object.keys(this.reportsByYear).map(
            (year: any) => {
              const reports = this.reportsByYear[year];
              if (reports.length === 1) {
                let quarters = [
                  { value: reports[0], viewValue: `Q${reports[0].quarter}` }
                ];
                return { year: year, quarters: quarters };
              }
              return {
                year: year,
                quarters: this.yearsAndQuarters(year)(reports)
              };
            }
          );
          this.onYearChange({ value: this.selectedYear });
        } else {
          this.showNoReports = false;
        }
      });
  }

  groupBy = (key: any) => (array: any) =>
    array.reduce((objectsByKeyValue: any, obj: any) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  yearsAndQuarters = (key: any) => (array: any) =>
    array.reduce((objectsByKeyValue: any, obj: any) => {
      if (
        array.filter((data: any) => obj.quarter === data.quarter).length > 1
      ) {
        objectsByKeyValue.push({
          value: obj,
          viewValue: `Q${obj.quarter} (${obj.title})`
        });
      } else {
        objectsByKeyValue.push({ value: obj, viewValue: `Q${obj.quarter}` });
      }
      return objectsByKeyValue;
    }, []);

  onYearChange(data: any) {
    this.isLoading = true;
    this.reportTitle = this.reportsList[0].value;
    this.filteredQuarters = this.quartersByYear.find(
      (obj: any) => obj.year == data.value
    );
    this.filteredQuarters.quarters.sort(this.sorter);

    if (this.selectedQuarter) {
      let navigatedQuarter = this.filteredQuarters.quarters.find(
        (quarter: any) => quarter.value.quarter === this.selectedQuarter
      );
      if (navigatedQuarter) {
        this.selectedQuarter = navigatedQuarter;
      } else {
        this.selectedQuarter = this.filteredQuarters.quarters[0];
      }
    } else {
      this.selectedQuarter = this.filteredQuarters.quarters[0];
    }
    this.location.replaceState(
      `lp/${this.lpsid}/vehicle/${this.vehicleId}/reports/${this.selectedYear}/${this.selectedQuarter.viewValue}`
    );
    this.getReportDocumentById(
      this.lpsid,
      this.vehicleId,
      this.selectedQuarter.value.documentId
    );
    this.pageNumber = this.selectedQuarter.value.toc[0].page;
    this.tocToBeHighlighted = this.selectedQuarter.value.toc[0];

    this.notificationService.sendNotification({
      prefix: '',
      suffix: ' / ' + this.selectedYear + ' / ' + this.selectedQuarter.viewValue
    });
  }

  sorter = (a: any, b: any) => {
    if (a.value.year !== b.value.year) {
      return b.value.year - a.value.year;
    } else if (
      a.value.year === b.value.year &&
      a.value.quarter !== b.value.quarter
    ) {
      return b.value.quarter - a.value.quarter;
    } else {
      return a.value.title.toUpperCase() > b.value.title.toUpperCase() ? 1 : -1;
    }
  };
  onQuarterChange(data: any) {
    this.isLoading = true;
    this.reportTitle = this.reportsList[0].value;
    this.selectedQuarter = data.value;
    this.location.replaceState(
      `lp/${this.lpsid}/vehicle/${this.vehicleId}/reports/${this.selectedYear}/${this.selectedQuarter.viewValue}`
    );
    this.getReportDocumentById(
      this.lpsid,
      this.vehicleId,
      this.selectedQuarter.value.documentId
    );
    this.notificationService.sendNotification({
      prefix: '',
      suffix: ' / ' + this.selectedYear + ' / ' + this.selectedQuarter.viewValue
    });
  }
  openDialog(event: boolean) {
    if (event) {
      this.isInaccessible = true;
      let dialogRef = this.dialog.open(DialogComponent, {
        data: <ModalDialogData>{
          titleKey: 'Access Denied',
          contentKey: 'access denied',
          positiveMessageKey: 'OK',
          negativeMessageKey: '',
          isValid: '1'
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === '1') {
          this.isInaccessible = false;
        }
      });
    }
  }
}
