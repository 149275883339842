<app-pagenotfound
  *ngIf="error == 'ACCESS_DENIED'; else LPNav"
></app-pagenotfound>

<ng-template #LPNav>
  <app-nav
    *ngIf="isAuthenticated"
    [baseData]="baseData"
    (handleLPS)="handleLPS($event)"
    (handleVehicle)="handleVehicle($event)"
  ></app-nav>
</ng-template>
