export interface CardItem {
  title: string;
  headRight: headRightType;
  bodyContent: bodyType;
}

export enum headRightType {
  toggle = 'toggle',
  category = 'category'
}

export enum bodyType {
  list = 'list',
  news = 'news',
  chart = 'chart',
  image = 'image'
}
