<div class="metrics-container">
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
      <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
  </mat-dialog-actions>

  <div mat-dialog-content class="popup-container">
    <mat-card class="metrics-card">
      <mat-card-header>
        <div class="tile"></div>
        {{ 'translations.dashboard.calculationsMultiples.metrics' | translate }}
      </mat-card-header>
      <mat-card-content>
        <div *ngFor="let e of data.metricData" class="metrics-container">
          <div class="metrics-left">
            <span class="circle-text">
              <p>{{ e.char }}</p>
            </span>
          </div>
          <div class="metrics-right">
            <span class="metricTitle">{{
              'translations.dashboard.metric.' + e.metricTitle | translate
            }}</span
            ><br />
            <label class="metricDiscription">{{
              'translations.dashboard.metric.' + e.metricDiscription | translate
            }}</label>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="calculations-card">
      <mat-card-header>
        <div class="tile"></div>
        {{
          'translations.dashboard.calculationsMultiples.calculations'
            | translate
        }}
      </mat-card-header>
      <mat-card-content>
        <div class="calculations-container">
          <div class="metrics-multiples">
            <div class="metrics-title">
              {{
                'translations.dashboard.calculationsMultiples.multiples'
                  | translate
              }}
            </div>

            <div class="container">
              <div class="darkGary">
                {{
                  'translations.dashboard.calculationsMultiples.moic'
                    | translate
                }}
              </div>
              <div class="formula">
                (
                <span class="small-circle-text">
                  <p>E</p>
                </span>
                +
                <span class="small-circle-text">
                  <p>F</p>
                </span>
                ) /
                <span class="small-circle-text">
                  <p>D</p>
                </span>
              </div>
              <div class="darkGary">
                {{
                  'translations.dashboard.calculationsMultiples.realizedMoic'
                    | translate
                }}
              </div>
              <div class="formula">
                <span class="small-circle-text">
                  <p>E</p>
                </span>
                /
                <span class="small-circle-text">
                  <p>D</p>
                </span>
              </div>
              <div class="darkGary">
                {{
                  'translations.dashboard.calculationsMultiples.unRealizedMoic'
                    | translate
                }}
              </div>
              <div class="formula">
                <span class="small-circle-text">
                  <p>F</p>
                </span>
                /
                <span class="small-circle-text">
                  <p>D</p>
                </span>
              </div>
              <div class="darkGary">
                {{
                  'translations.dashboard.calculationsMultiples.grossTvpi'
                    | translate
                }}
              </div>
              <div class="formula">
                (
                <span class="small-circle-text">
                  <p>E</p>
                </span>
                +
                <span class="small-circle-text">
                  <p>F</p>
                </span>
                ) /
                <span class="small-circle-text">
                  <p>A</p>
                </span>
              </div>
              <div class="gary">
                {{
                  'translations.dashboard.calculationsMultiples.netTvpi'
                    | translate
                }}
              </div>
              <div class="formula">
                (
                <span class="small-circle-text">
                  <p>B</p>
                </span>
                +
                <span class="small-circle-text">
                  <p>C</p>
                </span>
                ) /
                <span class="small-circle-text">
                  <p>A</p>
                </span>
              </div>
              <div class="gary">
                {{
                  'translations.dashboard.calculationsMultiples.rvpi'
                    | translate
                }}
              </div>
              <div class="formula">
                <span class="small-circle-text">
                  <p>C</p>
                </span>
                /
                <span class="small-circle-text">
                  <p>A</p>
                </span>
              </div>
              <div class="gary">
                {{
                  'translations.dashboard.calculationsMultiples.dpi' | translate
                }}
              </div>
              <div class="formula">
                <span class="small-circle-text">
                  <p>B</p>
                </span>
                /
                <span class="small-circle-text">
                  <p>A</p>
                </span>
              </div>
            </div>
          </div>
          <div class="metrics-irr">
            <div class="metrics-title">
              {{ 'translations.dashboard.calculationsIRR.irr' | translate }}
            </div>

            <div class="wrapper">
              <div class="column">
                <div class="darkGary">
                  {{
                    'translations.dashboard.calculationsIRR.grossirr'
                      | translate
                  }}
                </div>
                <div class="darkGary">
                  {{
                    'translations.dashboard.calculationsIRR.grossRealizedIrr'
                      | translate
                  }}
                </div>
                <div class="gary">
                  {{
                    'translations.dashboard.calculationsIRR.netIrr' | translate
                  }}
                </div>
                <div class="gary">
                  {{
                    'translations.dashboard.calculationsIRR.netRealizedirr'
                      | translate
                  }}
                </div>
              </div>
              <div class="column">
                <div class="darkGary">
                  {{
                    'translations.dashboard.calculationsIRR.outflows'
                      | translate
                  }}
                </div>
                <div class="darkGary">
                  {{
                    'translations.dashboard.calculationsIRR.inflows' | translate
                  }}
                </div>
                <div class="darkGary">
                  {{
                    'translations.dashboard.calculationsIRR.outflows'
                      | translate
                  }}
                </div>
                <div class="darkGary">
                  {{
                    'translations.dashboard.calculationsIRR.inflows' | translate
                  }}
                </div>
                <div class="gary">
                  {{
                    'translations.dashboard.calculationsIRR.outflows'
                      | translate
                  }}
                </div>
                <div class="gary">
                  {{
                    'translations.dashboard.calculationsIRR.inflows' | translate
                  }}
                </div>
                <div class="gary">
                  {{
                    'translations.dashboard.calculationsIRR.outflows'
                      | translate
                  }}
                </div>
                <div class="gary">
                  {{
                    'translations.dashboard.calculationsIRR.inflows' | translate
                  }}
                </div>
              </div>
              <div class="column">
                <div class="formula">
                  <span class="small-circle-text">
                    <p>D</p>
                  </span>
                  or
                  <span class="small-circle-text">
                    <p>A</p>
                  </span>
                </div>
                <div class="formula">
                  <span class="small-circle-text">
                    <p>E</p>
                  </span>
                  &
                  <span class="small-circle-text">
                    <p>F</p>
                  </span>
                </div>
                <div class="formula">
                  <span class="small-circle-text">
                    <p>D</p>
                  </span>
                  or
                  <span class="small-circle-text">
                    <p>A</p>
                  </span>
                </div>
                <div class="formula">
                  <span class="small-circle-text">
                    <p>E</p>
                  </span>
                </div>
                <div class="formula">
                  <span class="small-circle-text">
                    <p>A</p>
                  </span>
                </div>
                <div class="formula">
                  <span class="small-circle-text">
                    <p>B</p>
                  </span>
                  or
                  <span class="small-circle-text">
                    <p>C</p>
                  </span>
                </div>
                <div class="formula">
                  <span class="small-circle-text">
                    <p>A</p>
                  </span>
                </div>
                <div class="formula">
                  <span class="small-circle-text">
                    <p>B</p>
                  </span>
                </div>
              </div>
            </div>

            <div class="realized-unrealized">
              <div class="realized">
                <span class="investmentTiles realized"></span>
                <span> Gross </span>
              </div>
              <div class="unrealized">
                <span class="investmentTiles unrealized"></span>
                <span> Net </span>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
