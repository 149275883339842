import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Renderer2,
  HostListener
} from '@angular/core';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Observable, throwError } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApiHttpService } from '../../services/api-http.service';
import { ApiEndpointsService } from '../../services/api-endpoints.service';
import { createEventData, download } from 'src/app/event-download.utils';
import { DatePipe } from '@angular/common';
import {
  convertWithDecimal,
  unitConvert
} from 'src/app/utils/numberConvertion.util';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MetricDialogComponent } from 'src/app/shared/components/dialog/metric-dialog/metric-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  calculationsIRR,
  calculationsMultiples,
  metrics
} from './dashboardMetricJson';
import { ConfigService } from 'src/app/initializer/config.service';
import { TooltipPosition } from 'src/app/shared/components/tooltip/tooltip.enums';
import { environment } from 'src/environments/environment';
import { Base64 } from 'js-base64';
import * as ics from 'ics';

@Component({
  selector: 'app-dash',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  selected: boolean = false;
  languageCode: string = '';
  dashboardBaseData: any = {};
  dashboardEventData: any = {};
  dashboardNewsData: any = {};
  dashboardUpdateData: any = {};
  sortedTimelineEvents: any;
  filteredUpdates: any;
  selectedLP: any = {};
  selectedVehicle: any = {};
  currency: any;
  @ViewChild('timelineEvents', { static: false })
  timelineEvents!: ElementRef;
  eventsToShow: any = [];

  displayedColumns: string[] = ['title'];
  timeLineData: any;
  dateNow: any = new Date();
  isEventLoading!: boolean;
  isNewsLoading!: boolean;
  isUpdateloaded!: boolean;
  showTimelineEvents: boolean = false;
  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  hideCapitalAccount: boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    setTimeout(() => {
      this.stackingTooltipEvents();
    }, 100);
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }
  
  ngOnInit(): void {
    const { languageCode } = JSON.parse(this.userService.getUserConfig());
    const lpId = this.activatedRoute.snapshot.params['lpId'];
    const vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    let baseconfigData = this.config.baseData.data;
    if (environment.hideCapitalAccount === true) {
      this.hideCapitalAccount = false;
    }

    this.selectedLP = baseconfigData.lps.find((item: any) => item.id === lpId);
    this.selectedVehicle = baseconfigData.vehicles.find(
      (item: any) => item.id === vehicleId
    );
    this.languageCode = languageCode;
    const { lps, vehicle } = history.state;

    setTimeout(() => {
      this.currency = this.selectedVehicle.currency;
    }, 400);

    this.getDashboardData(this.selectedLP.id, this.selectedVehicle.id);
    this.getDashboardEventData(this.selectedLP.id, this.selectedVehicle.id);
    this.getDashboardNewsData(this.selectedLP.id, this.selectedVehicle.id);
    this.getDashboardUpdateData(this.selectedLP.id, this.selectedVehicle.id);
  }

  isSmallHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Small])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isSmallScreen$: Observable<boolean> = this.breakpointObserver
    .observe(['(min-width:678px) and (max-width:959.98px)'])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isMediumScreen$: Observable<boolean> = this.breakpointObserver
    .observe(['(min-width:600px) and (max-width:677.98px)'])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isTablet$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Medium])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isLargeScreen$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Large])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isXLargeScreen$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XLarge])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isXXLargeScreen$: Observable<boolean> = this.breakpointObserver
    .observe(['(min-width: 2200px)'])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private userService: UserService,
    private notificationService: NotificationService,
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService,
    public datepipe: DatePipe,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog,
    private config: ConfigService,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2
  ) {}
  getDashboardData(lpsId: string, vehicleId: string) {
    this.apiHttpService
      .get(this.apiEndpointsService.getDashboardData(lpsId, vehicleId))
      .subscribe((response: any) => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        this.dashboardBaseData = parsedResponse.data;
        this.timeLineData = this.dashboardBaseData.timeline;
        this.sortedTimelineEvents = this.dashboardBaseData.timeline.events
          .map((event: any) => {
            return { ...event, date: new Date(event.date) };
          })
          .sort(
            (event1: any, event2: any) =>
              Number(event1.date) - Number(event2.date)
          );
      });
  }
  convert(value: any) {
    return unitConvert(value, this.selectedVehicle);
  }
  performanceValue(value: any, title: string = '') {
    return convertWithDecimal(value, this.selectedVehicle, false, false, title);
  }
  getPercentage(realized: any, unrealized: any) {
    if (realized || unrealized) {
      const total = realized + unrealized;
      return Math.floor((realized / total) * 100);
    }
    return 0;
  }
  handleMenuClick(menuItemTitle: string) {
    let menuDetails: any = '';
    if (menuItemTitle === 'capital-account') {
      menuDetails = {
        value: menuItemTitle,
        viewValue: '',
        imgURL: ''
      };
    }
    if (menuItemTitle === 'insights') {
      menuDetails = { value: menuItemTitle, viewValue: '', imgURL: '' };
    }
    this.notificationService.sendClickEvent(menuDetails);
    this.router.navigate(
      [
        `/lp/${this.selectedLP.id}/vehicle/${this.selectedVehicle.id}/${menuItemTitle}`
      ],
      {
        state: {
          lps: this.selectedLP,
          vehicle: this.selectedVehicle,
          baseData: history.state.baseData
        }
      }
    );
  }
  getDashboardEventData(lpsId: string, vehicleId: string) {
    this.isEventLoading = false;
    this.apiHttpService
      .get(this.apiEndpointsService.getDashboardEventData(lpsId, vehicleId))
      .subscribe((response) => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        this.dashboardEventData = parsedResponse.data;
        this.isEventLoading = true;
      });
    catchError((err: HttpErrorResponse) => {
      return throwError(() => {
        if (err.status == 404) {
          this.isEventLoading = true;
        }
      });
    });
  }
  getDashboardNewsData(lpsId: string, vehicleId: string) {
    this.isNewsLoading = false;
    this.apiHttpService
      .get(this.apiEndpointsService.getDashboardNewsData(lpsId, vehicleId))
      .subscribe(
        (response) => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.dashboardNewsData = parsedResponse.data;
          this.isNewsLoading = true;
        },
        (error) => {
          if (error.status == 404 || 500) {
            this.dashboardNewsData = [];
            this.isNewsLoading = true;
          }
        }
      );

    // catchError((err: HttpErrorResponse) => {
    //   return throwError(() => {
    //     if (err.status == 404 || 500) {
    //       this.isNewsLoading=false;
    //     }
    //   });
    // });;
  }
  getDashboardUpdateData(lpsId: string, vehicleId: string) {
    this.isUpdateloaded = false;
    this.apiHttpService
      .get(this.apiEndpointsService.getDashboardUpdateData(lpsId, vehicleId))
      .subscribe((response) => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        parsedResponse.data.forEach((response: any, index: number) => {
          if (response?.context?.documentId) {
            let decodedString = Base64.decode(response.context.documentId);
            const splitStr = decodedString?.split('/LPs/');
            if (splitStr.length > 1) {
              const lpDesignation = splitStr[1]?.split('/')[0];
              const replacedString = decodedString.replace(
                `/LPs/${lpDesignation}`,
                '/Limited Partner'
              );
              parsedResponse.data[index]['context']['documentId'] =
                Base64.encode(replacedString);
            }
          }
        });
        this.dashboardUpdateData = parsedResponse.data;
        this.filteredUpdates = this.dashboardUpdateData;
        this.isUpdateloaded = true;
      });
    catchError((err: HttpErrorResponse) => {
      return throwError(() => {
        if (err.status == 404) {
          this.isUpdateloaded = true;
        }
      });
    });
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  getEventPosition(start: any, end: any, current?: any) {
    start = new Date(start);
    end = new Date(end);
    current = current ? new Date(current) : new Date();
    return Math.round(((current - start) / (end - start)) * 100);
  }

  getImage(docType: any) {
    if (docType == 'newDocument') {
      return `./assets/${'picture_as_pdf.svg'}`;
    } else if (docType == 'newQuarterlyReport') {
      return `./assets/${'capital_account.svg'}`;
    } else {
      return `./assets/${'capital_account.svg'}`;
    }
  }

  iCalDownload(_event: any) {
    // const ics = require('ics');
    let event: any;
    event = createEventData(_event);
    if (event) {
      ics.createEvent(event, (error: any, value: any) => {
        if (error) {
          console.log(error);
          return;
        }
        download('InvestorEvent.ics', value);
      });
    }
  }

  getTooltipText(data: any) {
    return { type: 'tiles', content: `${data.title}` };
  }
  getTitlename(data: any) {
    switch (data.type) {
      case 'newDocument': {
        return { type: 'tiles', content: `${data.context.filename}` };
      }
      case 'newQuarterlyReport': {
        return {
          type: 'tiles',
          content:
            this.translate.instant(
              'translations.dashboard.updates.quarterlyreport'
            ) +
            ' ' +
            'Q' +
            data.context.quarter +
            ' ' +
            data.context.year
        };
      }
      case 'newTransaction': {
        return {
          type: 'tiles',
          content:
            data.context.type == 'capitalCall'
              ? 'New' +
                ' ' +
                this.translate.instant(
                  'translations.dashboard.capital.capitalCall'
                )
              : 'New' +
                ' ' +
                this.translate.instant(
                  'translations.dashboard.capital.cashDistribution'
                )
        };
      }
      case 'newCapitalAccountOverview': {
        return {
          type: 'tiles',
          content:
            this.translate.instant(
              'translations.dashboard.updates.capitalAccountOverview'
            ) +
            ' ' +
            'Q' +
            data.context.quarter +
            ' ' +
            data.context.year
        };
      }
      case 'dueTransaction': {
        return {
          type: 'tiles',
          content:
            data.context.type == 'capitalCall'
              ? 'Due' +
                ' ' +
                this.translate.instant(
                  'translations.dashboard.capital.capitalCall'
                )
              : 'Due' +
                ' ' +
                this.translate.instant(
                  'translations.dashboard.capital.cashDistribution'
                )
        };
      }
    }
    return { type: 'tiles', content: `${data.context.filename}` };
  }

  onUpdatesChange(updateBy: any) {
    return (this.filteredUpdates = this.dashboardUpdateData.filter(
      (data: any) => !!data[updateBy.value]
    ));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.stackingTooltipEvents();
    }, 1000);
  }

  stackingTooltipEvents(): void {
    let x = 0;
    this.eventsToShow = [];
    const eventElements = this.timelineEvents.nativeElement.children;

    for (let i = eventElements.length - 1; i >= 0; ) {
      let skip = 1;
      let mergedEvents = [];
      const [currentEventDate, currentEventTitle, currentEventType] =
        eventElements[i].id.split('---');
      mergedEvents.push(
        this.sortedTimelineEvents.find(
          (ev: any) =>
            ev.date == currentEventDate &&
            ev.title == currentEventTitle &&
            ev.type == currentEventType
        )
      );
      for (let j = 1; j < eventElements.length; j++) {
        if (eventElements[i] && eventElements[i - j]) {
          const isOverlapping = this.eventsOverlap(
            eventElements[i],
            eventElements[i - j]
          );
          if (isOverlapping) {
            eventElements[i - j].style.visibility = 'hidden';
            const [previousEventDate, previousEventTitle, previousEventType] =
              eventElements[i - j].id.split('---');
            const overlappedEvent = this.sortedTimelineEvents.find(
              (ev: any) =>
                ev.date == previousEventDate &&
                ev.title == previousEventTitle &&
                ev.type == previousEventType
            );
            mergedEvents.push(overlappedEvent);
            skip = mergedEvents.length;
          } else {
            eventElements[i - j].style.visibility = 'visible';
          }
        } else {
          break;
        }
      }
      let currentEvent = {
        eventDate: currentEventDate,
        tooltipEvent: mergedEvents
      };
      this.eventsToShow.push(currentEvent);
      i = i - skip;
    }
    this.showTimelineEvents = true;
  }

  eventsOverlap(event1: any, event2: any) {
    const domRect1 = event1.getBoundingClientRect();
    const domRect2 = event2.getBoundingClientRect();
    return !(
      domRect1.top > domRect2.bottom ||
      domRect1.right < domRect2.left ||
      domRect1.bottom < domRect2.top ||
      domRect1.left > domRect2.right
    );
  }

  navigateTo(update: any) {
    switch (update.type) {
      case 'newDocument': {
        return `/lp/${this.selectedLP.id}/vehicle/${this.selectedVehicle.id}/documents`;
      }
      case 'newQuarterlyReport': {
        return `/lp/${this.selectedLP.id}/vehicle/${this.selectedVehicle.id}/reports`;
      }
      case 'newTransaction': {
        return `/lp/${this.selectedLP.id}/vehicle/${this.selectedVehicle.id}/capital-account`;
      }
      case 'newCapitalAccountOverview': {
        return `/lp/${this.selectedLP.id}/vehicle/${this.selectedVehicle.id}/capital-account`;
      }
      case 'dueTransaction': {
        return `/lp/${this.selectedLP.id}/vehicle/${this.selectedVehicle.id}/capital-account`;
      }
      default:
        return `/lp/${this.selectedLP.id}/vehicle/${this.selectedVehicle.id}/dashboard`;
    }
  }

  getTimeLineTooltip(data: any) {
    const tooltipData = this.eventsToShow.find(
      (ev: any) => ev.eventDate == data.date
    );
    let tooltipContent = [];
    if (tooltipData) {
      tooltipContent = tooltipData.tooltipEvent
        .sort((a: any, b: any) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        })
        .map((event: any) => {
          let currentMonth = this.datepipe.transform(event.date, 'MMM');
          const currentDate = this.datepipe.transform(event.date, 'dd');
          const currentYear = this.datepipe.transform(event.date, 'yyyy');

          return {
            date: `${currentDate + '. ' + currentMonth + ' ' + currentYear}`,
            title: event.title
          };
        });
    }

    if (tooltipContent.length > 3) {
      tooltipContent = tooltipContent.reverse();
    }
    return { type: 'timeline', content: [...tooltipContent] };
  }

  navigateUpdate(update: any) {
    switch (update.type) {
      case 'newDocument': {
        this.notificationService.sendClickEvent({
          value: 'documents',
          viewValue: '',
          imgURL: ''
        });
        return this.router.navigate(
          [
            `/lp/${this.selectedLP.id}/vehicle/${this.selectedVehicle.id}/documents/${update.context.documentId}`
          ],
          {
            state: {
              lps: this.selectedLP,
              vehicle: this.selectedVehicle,
              baseData: history.state.baseData,
              navigationData: update.context
            }
          }
        );
      }
      case 'newQuarterlyReport': {
        this.notificationService.sendClickEvent({
          value: 'reports',
          viewValue: '',
          imgURL: ''
        });
        return this.router.navigate(
          [
            `/lp/${this.selectedLP.id}/vehicle/${this.selectedVehicle.id}/reports/${update.context.year}/Q${update.context.quarter}`
          ],
          {
            state: {
              lps: this.selectedLP,
              vehicle: this.selectedVehicle,
              baseData: history.state.baseData,
              navigationData: update.context
            }
          }
        );
      }
      case 'newTransaction': {
        this.notificationService.sendClickEvent({
          value: 'capital-account',
          viewValue: '',
          imgURL: ''
        });
        return this.router.navigate(
          [
            `/lp/${this.selectedLP.id}/vehicle/${this.selectedVehicle.id}/capital-account/transactions/${update.context.transactionId}`
          ],
          {
            state: {
              lps: this.selectedLP,
              vehicle: this.selectedVehicle,
              baseData: history.state.baseData,
              navigationData: update.context
            }
          }
        );
      }
      case 'newCapitalAccountOverview': {
        this.notificationService.sendClickEvent({
          value: 'capital-account',
          viewValue: '',
          imgURL: ''
        });
        return this.router.navigate(
          [
            `/lp/${this.selectedLP.id}/vehicle/${this.selectedVehicle.id}/capital-account/overview/${update.context.year}/Q${update.context.quarter}`
          ],
          {
            state: {
              lps: this.selectedLP,
              vehicle: this.selectedVehicle,
              baseData: history.state.baseData,
              navigationData: update.context
            }
          }
        );
      }
      case 'dueTransaction': {
        this.notificationService.sendClickEvent({
          value: 'capital-account',
          viewValue: '',
          imgURL: ''
        });
        return this.router.navigate(
          [
            `/lp/${this.selectedLP.id}/vehicle/${this.selectedVehicle.id}/capital-account/transactions`
          ],
          {
            state: {
              lps: this.selectedLP,
              vehicle: this.selectedVehicle,
              baseData: history.state.baseData,
              navigationData: update.context
            }
          }
        );
      }
    }
    return this.router.navigate(
      [
        `/lp/${this.selectedLP.id}/vehicle/${this.selectedVehicle.id}/dashboard`
      ],
      {
        state: {
          lps: this.selectedLP,
          vehicle: this.selectedVehicle,
          baseData: history.state.baseData
        }
      }
    );
  }
  showMetrics() {
    let dialogRef = this.dialog.open(MetricDialogComponent, {
      disableClose: false,
      data: {
        metricData: metrics,
        calculationsMultiplesData: calculationsMultiples,
        calculationsIRRData: calculationsIRR
      }
    });
  }
}
