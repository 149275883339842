import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-template',
  templateUrl: './file-template.component.html',
  styleUrls: ['./file-template.component.scss']
})
export class FileTemplateComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
