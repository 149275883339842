import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { Location } from '@angular/common';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalDialogData } from 'src/app/models/modelDialog.model';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import {
  convert,
  convertWithDecimal
} from 'src/app/utils/numberConvertion.util';
import { ConfigService } from 'src/app/initializer/config.service';

@Component({
  selector: 'app-company-overview',
  templateUrl: './company-overview.component.html',
  styleUrls: ['./company-overview.component.scss']
})
export class CompanyOverviewComponent implements OnInit {
  selectedPage: any;
  lpId: any;
  vehicleId: any;
  companyDetails: any;
  companyOverviewDetails: any;
  companyId: any;
  portfolioTab: any;
  selectedVehicle: any;
  @Input() companyOverviewData: any;

  capTableData: any = [
    {
      stakeholder: 'ARR',
      investmentRound: '2483',
      totalSharesOS: '2232',
      totalSharesFD: '2389',
      ownershipOS: '45%',
      ownershipFD: '49%'
    },
    {
      stakeholder: 'Greth Ventures',
      investmentRound: '1223',
      totalSharesOS: '1899',
      totalSharesFD: '1229',
      ownershipOS: '39%',
      ownershipFD: '33%'
    },
    {
      stakeholder: 'Always Capital',
      investmentRound: '1223',
      totalSharesOS: '232',
      totalSharesFD: '1090',
      ownershipOS: '34%',
      ownershipFD: '30%'
    },
    {
      stakeholder: 'Always Capital',
      investmentRound: '1223',
      totalSharesOS: '232',
      totalSharesFD: '1090',
      ownershipOS: '34%',
      ownershipFD: '30%'
    },
    {
      stakeholder: 'Always Capital',
      investmentRound: '1223',
      totalSharesOS: '232',
      totalSharesFD: '1090',
      ownershipOS: '34%',
      ownershipFD: '30%'
    },
    {
      stakeholder: 'Always Capital',
      investmentRound: '1223',
      totalSharesOS: '232',
      totalSharesFD: '1090',
      ownershipOS: '34%',
      ownershipFD: '30%'
    },
    {
      stakeholder: 'Always Capital',
      investmentRound: '1223',
      totalSharesOS: '232',
      totalSharesFD: '1090',
      ownershipOS: '34%',
      ownershipFD: '30%'
    },
    {
      stakeholder: 'Always Capital',
      investmentRound: '1223',
      totalSharesOS: '232',
      totalSharesFD: '1090',
      ownershipOS: '34%',
      ownershipFD: '30%'
    },
    {
      stakeholder: 'Total',
      investmentRound: '2370',
      totalSharesOS: '1299',
      totalSharesFD: '1787',
      ownershipOS: '39%',
      ownershipFD: '23%'
    }
  ];

  constructor(
    private router: Router,
    private apiHttpService: ApiHttpService,
    private activatedRoute: ActivatedRoute,
    private portfoliosService: PortfolioService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private location: Location,
    private config: ConfigService
  ) {}
  ngOnInit(): void {
    const baseConfigData = this.config.baseData.data;
    this.lpId = this.activatedRoute.snapshot.params['lpId'];
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.selectedVehicle = baseConfigData.vehicles.find(
      (item: any) => item.id === this.vehicleId
    );
    this.companyId = this.getCompanyIdFromUrl(window.location.href);
    this.portfolioTab = this.activatedRoute.snapshot.params['portfolioTab'];
    if (this.companyId) {
      if (this.companyId.length < 34 || this.companyId.length > 38) {
        this.notificationService.sendNotification({ isInaccessible: true });
        this.openDialog(true);
      }
      this.getCompanyOverviewDetail(this.lpId, this.vehicleId, this.companyId);
    }
  }

  getCompanyIdFromUrl(url: string): string | null {
    const match = url.match(/\/portfolio-companies\/([^\/]+)/);
    return match ? match[1] : null;
  }
  getCompanyDetails(lpId: any, vehicleId: any, companyId: string) {
    this.apiHttpService
      .get(this.portfoliosService.getCompanyDetail(lpId, vehicleId, companyId))
      .subscribe(
        (response) => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.companyDetails = parsedResponse.data;
        },
        (error) => {
          if (error.status == '404') {
            console.log('error', error);
          }
          console.log('Error:', error);
        }
      );
  }
  getCompanyOverviewDetail(lpId: any, vehicleId: any, companyId: string) {
    this.apiHttpService
      .get(
        this.portfoliosService.getCompanyOverviewDetail(
          lpId,
          vehicleId,
          companyId
        )
      )
      .subscribe(
        (response) => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.companyOverviewDetails = parsedResponse.data;
        },
        (error) => {
          console.log('Error:', error);
        }
      );
  }
  convertToDigits(value: any) {
    return convert(value, this.selectedVehicle, true);
  }
  convertWithDecimal(value: any) {
    return convertWithDecimal(value, this.selectedVehicle, true);
  }
  moveToSelectedTab(tabName: string) {
    for (
      let i = 0;
      i < document.querySelectorAll('.mat-tab-label-content').length;
      i++
    ) {
      if (
        (<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i])
          .innerText == tabName
      ) {
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
      }
    }
  }
  backToOverview() {
    this.location.replaceState(
      `lp/${this.lpId}/vehicle/${this.vehicleId}/insights/portfolio-companies/${this.companyId}`
    );
    this.moveToSelectedTab('First Page');
  }

  backNavigation() {
    this.location.replaceState(
      `lp/${this.lpId}/vehicle/${this.vehicleId}/insights/portfolio-companies`
    );
    this.moveToSelectedTab('Portfolio Companies Table');
  }
  handleMenuClick(tab: any) {
    this.selectedPage = tab;
    this.moveToSelectedTab('Second Page');
    this.location.replaceState(
      `lp/${this.lpId}/vehicle/${this.vehicleId}/insights/portfolio-companies/${this.companyId}/portfolio/ownership-overview`
    );
  }
  openDialog(event: boolean) {
    if (event) {
      let dialogRef = this.dialog.open(DialogComponent, {
        data: <ModalDialogData>{
          titleKey: 'Access Denied',
          contentKey: 'access denied',
          positiveMessageKey: 'OK',
          negativeMessageKey: '',
          isValid: '1'
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === '1') {
          this.notificationService.sendNotification({ isInaccessible: false });
          this.router.navigate([
            `lp/${this.lpId}/vehicle/${this.vehicleId}/insights/portfolio-companies`
          ]);
        }
      });
    }
  }
}
