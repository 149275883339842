import { LiveAnnouncer } from '@angular/cdk/a11y';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
  LOCALE_ID
} from '@angular/core';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { ConfigService } from 'src/app/initializer/config.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { Location, CurrencyPipe } from '@angular/common';
import { CompanyOverviewComponent } from '../company-overview/company-overview.component';
import {
  convert,
  convertWithDecimal,
  unitConvert
} from 'src/app/utils/numberConvertion.util';
import { Column } from 'src/app/shared/components/table/column';
import { BehaviorSubject } from 'rxjs';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { ModalDialogData } from 'src/app/models/modelDialog.model';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface PeriodicElement {
  company: any;
  initialInvestment: any;
  latestInvestment: any;
  status: any;
  lastestValuation: any;
  currentYearRevenue: any;
  companyValue: any;
  id: any;
}
@Component({
  selector: 'app-portfolio-companies',
  templateUrl: './portfolio-companies.component.html',
  styleUrls: ['./portfolio-companies.component.scss']
})
export class PortfolioCompaniesComponent implements AfterViewInit, OnInit {
  @ViewChild(CompanyOverviewComponent, { static: true })
  companyOverviewComponent!: CompanyOverviewComponent;
  displayedColumns: string[] = [
    'company',
    'initialInvestment',
    'latestInvestment',
    'status',
    'latestValuation',
    'currentYearRevenue',
    'companyValue',
    'id'
  ];

  lpId: any;
  vehicleId: any;
  selectedChartBusinessMetric: number = 0;
  portfolioCompanyList: any = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {}
  ];
  selectedVehicle: any;
  isComponentLoaded = false;
  portfolioTab: any;
  nestedSelectedTabIndex: number = 0;
  tabs: any = [
    'PortfolioTable',
    'overview',
    'ownership-overview',
    'stakeholders'
  ];
  @Output()
  customValues: EventEmitter<any> = new EventEmitter<any>();
  companyId: any;
  companyOverviewData: any;
  companyOverviewDetails: any;
  isBusinessMetricChartUpdated$ = new BehaviorSubject(true);

  capTableData: any = [
    {
      stakeholder: 'ARR',
      investmentRound: '2483',
      totalSharesOS: '2232',
      totalSharesFD: '2389',
      ownershipOS: '45%',
      ownershipFD: '49%'
    },
    {
      stakeholder: 'Greth Ventures',
      investmentRound: '1223',
      totalSharesOS: '1899',
      totalSharesFD: '1229',
      ownershipOS: '39%',
      ownershipFD: '33%'
    },
    {
      stakeholder: 'Always Capital',
      investmentRound: '1223',
      totalSharesOS: '232',
      totalSharesFD: '1090',
      ownershipOS: '34%',
      ownershipFD: '30%'
    },
    {
      stakeholder: 'Always Capital',
      investmentRound: '1223',
      totalSharesOS: '232',
      totalSharesFD: '1090',
      ownershipOS: '34%',
      ownershipFD: '30%'
    },
    {
      stakeholder: 'Always Capital',
      investmentRound: '1223',
      totalSharesOS: '232',
      totalSharesFD: '1090',
      ownershipOS: '34%',
      ownershipFD: '30%'
    },
    {
      stakeholder: 'Always Capital',
      investmentRound: '1223',
      totalSharesOS: '232',
      totalSharesFD: '1090',
      ownershipOS: '34%',
      ownershipFD: '30%'
    },
    {
      stakeholder: 'Always Capital',
      investmentRound: '1223',
      totalSharesOS: '232',
      totalSharesFD: '1090',
      ownershipOS: '34%',
      ownershipFD: '30%'
    },
    {
      stakeholder: 'Always Capital',
      investmentRound: '1223',
      totalSharesOS: '232',
      totalSharesFD: '1090',
      ownershipOS: '34%',
      ownershipFD: '30%'
    },
    {
      stakeholder: 'Total',
      investmentRound: '2370',
      totalSharesOS: '1299',
      totalSharesFD: '1787',
      ownershipOS: '39%',
      ownershipFD: '23%'
    }
  ];
  selectedPage: any;
  isVisible$ = new BehaviorSubject(true);
  chartData: any;
  legendColors: any = [];
  chartTitle: any;
  companyName: any;
  pageTitle: string = '';

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private apiHttpService: ApiHttpService,
    private portfoliosService: PortfolioService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private config: ConfigService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private currencyPipe: CurrencyPipe
  ) {}

  unloadedportfolioData: any = [{}, {}, {}, {}, {}, {}, {}];
  portfolioColumns: Array<Column> = [
    {
      columnDef: 'company',
      header: 'company',
      cell: (element: Record<string, any>) => `${element['company']}`
    },
    {
      columnDef: 'type',
      header: 'Type',
      cell: (element: Record<string, any>) => `${element['type']}`
    },
    {
      columnDef: 'sendDate',
      header: 'Send date',
      cell: (element: Record<string, any>) => `${element['sendDate']}`
    },
    {
      columnDef: 'dueDate',
      header: 'Due date',
      cell: (element: Record<string, any>) => `${element['dueDate']}`
    },
    {
      columnDef: 'amount',
      header: `Amount in `,
      cell: (element: Record<string, any>) => `${element['amount']}`
    },
    {
      columnDef: 'status',
      header: 'Status',
      isStatus: true,
      cell: (element: Record<string, any>) => `${element['status']}`
    },
    {
      columnDef: 'documentId',
      header: '',
      cell: (element: Record<string, any>) => `${element['documentId']}`
    }
  ];
  ngOnInit(): void {
    const baseConfigData = this.config.baseData.data;
    this.lpId = this.activatedRoute.snapshot.params['lpId'];
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.portfolioTab =
      this.activatedRoute.snapshot.params['portfolioTab'] != undefined &&
      this.tabs.includes(this.activatedRoute.snapshot.params['portfolioTab'])
        ? this.activatedRoute.snapshot.params['portfolioTab']
        : 'PortfolioTable';
    this.companyId = this.activatedRoute.snapshot.params['companyId'];
    this.selectedVehicle = baseConfigData.vehicles.find(
      (item: any) => item.id === this.vehicleId
    );
    this.isComponentLoaded = true;
    this.getPortfolioCompanyList(this.lpId, this.vehicleId);
    if (this.companyId) {
      //temp solution to check if valid comId ,, need to find alternate solution to check companyID
      if (this.companyId.length == 36) {
        if (this.portfolioTab === 'overview') {
          this.moveToSelectedTab('overview');
          this.destroyAndReload();
        } else if (this.portfolioTab === 'ownership-overview') {
          this.nestedSelectedTabIndex = 1;
          this.handleMenuClick('ownership-overview');
          this.destroyAndReload();
        } else if (this.portfolioTab === 'stakeholders') {
          this.nestedSelectedTabIndex = 1;
          this.handleMenuClick('stakeholders');
          this.destroyAndReload();
        }
      } else {
        this.notificationService.sendNotification({ isInaccessible: true });
        this.openDialog(true);
      }
    }
  }
  // convertWithDecimal(value: any) {
  //   return convertWithDecimal(value, this.selectedVehicle, true);
  // }

  getPortfolioCompanyList(lpId: any, vehicleId: any) {
    this.apiHttpService
      .get(this.portfoliosService.getPortfolioCompanyList(lpId, vehicleId))
      .subscribe(
        (response) => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.portfolioCompanyList = parsedResponse.data;
          const filteredCompanies = this.portfolioCompanyList.filter((company: { id: any; }) => company.id === this.companyId);
          this.companyName = filteredCompanies.length > 0 ? filteredCompanies[0].name : ''
          this.isComponentLoaded = false;
        },
        (error) => {
          this.portfolioCompanyList = [];
          this.isComponentLoaded = true;
          console.log('Error while in getPortfolioCompanyList:', error);
        }
      );
  }

  calculateProgress(actual: number, plan: number): string {
    const progressPercentage = (actual / plan) * 100;
    return progressPercentage + '%';
  }

  public tabChanged(tabChangeEvent: any): void {
    this.destroyAndReload();
    let messageToSend = this.translate.instant(
      'translations.portfoliocompanies.title'
    );
    this.notificationService.sendNotification({
      prefix: '',
      suffix: ' / ' + messageToSend
    });
    if (tabChangeEvent.index === 0) {
      this.portfolioTab = 'PortfolioTable';
    }
    /* if (tabChangeEvent.index === 1) {
      console.log('tabChangeEvent', tabChangeEvent.index);
      // this.moveToSelectedTab('overview');
    } */
  }
  destroyAndReload() {
    this.isVisible$.next(false);
    setTimeout(() => {
      this.isVisible$.next(true);
    }, 0);
  }
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {}

  handleRowClick(row: any) {
    this.companyId = row.id;
    this.moveToSelectedTab('overview');
  }

  backToOverview() {
    this.moveToSelectedTab('First Page');
  }

  getCompanyProgress(value: any[]): any {
    const maxValue = value.reduce((max: any, current: any) => {
      return max > current ? max : current;
    });

    return maxValue;
  }

  backNavigation() {
    this.moveToSelectedTab('PortfolioTable');
    setTimeout(() => {
      this.companyOverviewData = null;
    }, 500);
  }

  handleMenuClick(tab: any) {
    this.selectedPage = tab;
    this.moveToSelectedTab('Second Page');
    if (tab === 'ownership-overview') {
      this.location.replaceState(
        `lp/${this.lpId}/vehicle/${this.vehicleId}/insights/portfolio-companies/${this.companyId}/ownership-overview`
      );
    }

    if (tab === 'stakeholders') {
      this.location.replaceState(
        `lp/${this.lpId}/vehicle/${this.vehicleId}/insights/portfolio-companies/${this.companyId}/stakeholders`
      );
    }
  }

  convertToDigits(value: any) {
    return convert(value, this.selectedVehicle, true);
  }
  convertDecimal(value: any, roundOfRequired:boolean=false) {
    return convertWithDecimal(value, this.selectedVehicle, true, roundOfRequired);
}

  convertToUnit(value: any) {
    return unitConvert(value, this.selectedVehicle);
  }


  degreeArrow(x: any, y: any) {
    const dy = y - x;
    var theta = Math.atan2(dy, 10);
    theta *= 180 / Math.PI;
    if (theta > 60) {
      return 36.5;
    }
    return -36.5;
  }

  //COMPANY OVERVIEW API
  getCompanyDetails(lpId: any, vehicleId: any, companyId: string) {
    this.apiHttpService
      .get(this.portfoliosService.getCompanyDetail(lpId, vehicleId, companyId))
      .subscribe(
        (response) => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.companyOverviewData = parsedResponse.data;
          this.createChart();
          this.companyName = this.companyOverviewData.name;
        },
        (error) => {
          if (error.status == '404') {
          }
          this.companyOverviewData = null;
        }
      );
  }

  getCompanyOverviewDetail(lpId: any, vehicleId: any, companyId: string) {
    this.apiHttpService
      .get(
        this.portfoliosService.getCompanyOverviewDetail(
          lpId,
          vehicleId,
          companyId
        )
      )
      .subscribe(
        (response) => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.companyOverviewDetails = parsedResponse.data;
        },
        (error) => {
          this.companyOverviewDetails = null;
        }
      );
  }

  moveToSelectedTab(tabName: string) {
    if (tabName === 'overview') {
      this.location.go(
        `lp/${this.lpId}/vehicle/${this.vehicleId}/insights/portfolio-companies/${this.companyId}/${tabName}`
      );
      setTimeout(() => {
        this.getCompanyDetails(this.lpId, this.vehicleId, this.companyId);
      }, 1000);
    } else if (tabName === 'PortfolioTable') {
      this.location.go(
        `lp/${this.lpId}/vehicle/${this.vehicleId}/insights/portfolio-companies`
      );
    }
    if (tabName === 'Second Page') {
      // this.companyOverviewDetails = null;
      if (this.selectedPage == 'stakeholders') {
        this.pageTitle = 'Cap table';
      } else if (this.selectedPage == 'ownership-overview') {
        this.pageTitle = 'Ownership overview';
      }

      setTimeout(() => {
        this.location.go(
          `lp/${this.lpId}/vehicle/${this.vehicleId}/insights/portfolio-companies/${this.companyId}/${this.selectedPage}`
        );
        this.getCompanyOverviewDetail(
          this.lpId,
          this.vehicleId,
          this.companyId
        );
      }, 1000);
    }
    if (tabName === 'First Page') {
      this.location.go(
        `lp/${this.lpId}/vehicle/${this.vehicleId}/insights/portfolio-companies/${this.companyId}/${this.tabs[1]}`
      );
      this.getCompanyDetails(this.lpId, this.vehicleId, this.companyId);
    }

    for (
      let i = 0;
      i < document.querySelectorAll('.mat-tab-label-content').length;
      i++
    ) {
      if (
        (<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i])
          .innerText == tabName
      ) {
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
      }
    }
  }

  createChart() {
    let baseConfigData = this.config.baseData.data;
    const selectedVehicle = this.selectedVehicle;
    let currentGP = baseConfigData.gps.find((gp: any) => gp.id === this.selectedVehicle.gpId);
    this.legendColors.push(currentGP.colors.primary);
    this.legendColors.push(currentGP.colors.secondary);
    
    const actualData = this.companyOverviewData.businessMetrics[0].chart.actualValues;
    this.chartTitle = this.companyOverviewData.businessMetrics[0].name;
    const budgetData = this.companyOverviewData.businessMetrics[0].chart.planValues;
    const currencySymbol = this.getCurrencySymbol(
      this.selectedVehicle.currency
    );
    

    /* Dev Note : check the labels dynamic values with PO */
    this.chartData = {
      type: 'line',
      data: {
        // labels: ['01/03/2023', '01/03/2023', '01/03/2023', '01/03/2023'],
        labels: this.companyOverviewData.businessMetrics[0].chart.labels,
        datasets: [
          {
            label: 'Actual',
            data: actualData,
            borderWidth: 3,
            fill: false,
            pointRadius: 0
          },
          {
            label: 'Budget',
            data: budgetData,
            borderWidth: 3,
            fill: false,
            pointRadius: 0
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
            grid: {
              display: false
            },
            title: {
              display: false,
              text: 'Months'
            }
          },
          y: {
            display: true,
            grid: {
              display: true,
              color: 'rgba(217, 217, 217, 1)'
            },
            ticks: {
              callback: function (value: any) {
                return (
                  currencySymbol +
                  convertWithDecimal(value, selectedVehicle, true)
                );
              }
            },
            title: {
              display: false,
              text: 'Value'
            }
          }
        },
        plugins: {
          title: {
            display: false,
            text: 'Line Chart - Actual vs Budget',
            position: 'top',
            align: 'start'
          },
          legend: {
            display: false,
            position: 'bottom',
            align: 'end',
            labels: {
              padding: 10
            }
          }
        }
      }
    };
  }

  getConvertedValue(value: any) {
    return this.customValues.emit(value);
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  openDialog(event: boolean) {
    if (event) {
      let dialogRef = this.dialog.open(DialogComponent, {
        data: <ModalDialogData>{
          titleKey: 'Access Denied',
          contentKey: 'access denied',
          positiveMessageKey: 'OK',
          negativeMessageKey: '',
          isValid: '1'
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === '1') {
          this.notificationService.sendNotification({ isInaccessible: false });
          this.router.navigate([
            `lp/${this.lpId}/vehicle/${this.vehicleId}/insights/portfolio-companies`
          ]);
        }
      });
    }
  }

  updateChartData(data: any, index: number) {
    this.selectedChartBusinessMetric = index;
    this.chartData.data.datasets[0].data = data.chart.actualValues;
    this.chartData.data.datasets[1].data = data.chart.planValues;
    this.chartTitle = data.name;
    this.isBusinessMetricChartUpdated$.next(false);
    setTimeout(() => {
      this.isBusinessMetricChartUpdated$.next(true);
    }, 0);
  }
getCountryCurrrency()
{
  return this.getCurrencySymbol(this.selectedVehicle.currency)
}
  getCurrencySymbol(currencyCode: string): string {
    const value = 1;
    const display = 'symbol';
    const digitsInfo = '1.0-0';
    const formattedCurrency = this.currencyPipe.transform(
      value,
      currencyCode,
      display,
      digitsInfo
    );
    if (formattedCurrency) {
      const currencySymbol = formattedCurrency
        .replace(value.toString(), '')
        .trim();
      return currencySymbol;
    }
    return '';
  }
  getValidURL(value:string)
  {
    return `https://${value}`
  }
}
