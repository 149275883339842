<div class="settings">
  <div class="bottom-bar"></div>
  <ng-container *ngIf="showOption">
    <mat-card class="optionsCard first">
      <div>
        Delete
      </div>
    </mat-card>
</ng-container>
  <mat-tab-group dynamicHeight mat-align-tabs="start" (selectedTabChange)="tabChanged($event)">
    <mat-tab label="{{ 'translations.settings.users' | translate }}">
      <div class="user-settings-container">
        <div class="list-container">
              <mat-card class="list-card">
                <div class="head">
                  <div class="addUser">
                    <span
                      >
                      <mat-icon>add</mat-icon>
                      {{ 'translations.settings.addUser' | translate }}</span
                    >
                  </div>
                </div>
                <div class="body">
                  <div class="usersList">
                    <div (click)="addRemoveClass(1)" class="userDetails" [ngClass]="{'default-user-selection': isActiveOne}">
                      <div class="userInfo">
                        <p class="userName">User name</p>
                        <p class="userEmail">user&#64;example.com</p>
                      </div>
                      <div class="more">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" xPosition="before" class="menuBar">
                          <button  mat-menu-item>
                            <span>{{ 'translations.settings.deleteUser' | translate }}</span>
                          </button>
                        </mat-menu>
                      </div>
  
                    </div>
                    <div (click)="addRemoveClass(2)" class="userDetails" [ngClass]="{'default-user-selection': isActiveTwo}">
                      <div class="userInfo">
                        <p class="userName">User name</p>
                        <p class="userEmail">user&#64;example.com</p>
                      </div>
                      <div class="more">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" xPosition="before">
                          <button  mat-menu-item>
                            <span>{{ 'translations.settings.deleteUser' | translate }}</span>
                          </button>
                        </mat-menu>
                      </div>
                      
                    </div>
                    <div (click)="addRemoveClass(3)" class="userDetails" [ngClass]="{'default-user-selection': isActiveThree}">
                      <div class="userInfo">
                        <p class="userName">User name</p>
                        <p class="userEmail">user&#64;example.com</p>
                      </div>
                      <div class="more">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" xPosition="before">
                          <button  mat-menu-item>
                            <span>{{ 'translations.settings.deleteUser' | translate }}</span>
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
        </div>
        <div class="content-container">
          <div class="previleges-container">
            <form
            class="defaultAccessForm"
            [formGroup]="form"
            (ngSubmit)="submitForm()"
            novalidate
          >
          <app-table [menuTitle]="menuTitle" [tableColumns]="userSettingsColumn" 
            [tableData]="userSettingsData"
          >
          </app-table>
        </form>
          </div>
        <div class="set-default">
          <div class="toggle">
            <div class="container">
              <label class="toggle-switch">
                <input type="checkbox" [(ngModel)]="selected">
                <div class="slider"></div>
              </label>
              <span class="use-defaults-text">{{ 'translations.settings.useDefaults' | translate }}</span>
            </div>
          </div>
          <div class="save-button">
            <button>
              {{ 'translations.settings.save' | translate }}
            </button>
          </div>

        </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Investor">
      <div class="tab investor-container">
        <mat-grid-list cols="6" rowHeight="23px" gutterSize="20px">
          <mat-grid-tile colspan="4" rowspan="7">
            <mat-card class="investorCard1">
              <div class="card-container">
                <div class="cardHead">
                  <div class="tile"></div>
                  <div class="cardHeadLeft">
                    {{ 'translations.settings.address' | translate }}
                  </div>
                </div>
                <div class="cardBody">
                  <div class="investorAddress">
                    <form>
                      <div>
                        <label for="company">{{
                          'translations.settings.company' | translate
                        }}</label>
                        <input type="text" name="company" value="" />
                      </div>
                      <div>
                        <label for="street">{{
                          'translations.settings.street' | translate
                        }}</label>
                        <input type="text" name="street" value="" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </mat-card>
          </mat-grid-tile>
          <mat-grid-tile colspan="4" rowspan="9">
            <mat-card class="investorCard2">
              <div class="card-container">
                <div class="cardHead">
                  <div class="tile"></div>
                  <div class="cardHeadLeft">
                    {{ 'translations.settings.more' | translate }}
                  </div>
                </div>
                <div class="cardBody moreInvestorInfo">
                  <div class="moreInformation">
                    <form>
                      <div>
                        <label for="vat-number">{{
                          'translations.settings.VATnumber'
                            | translate
                        }}</label>
                        <input type="text" name="vat-number" value="" />
                      </div>
                      <div>
                        <label for="commercial-register-number">{{
                          'translations.settings.commercialRegisterNumber'
                            | translate
                        }}</label>
                        <input
                          type="text"
                          name="commercial-register-number"
                          value=""
                        />
                      </div>
                      <div>
                        <label for="iban">IBAN</label>
                        <input type="text" name="iban" value="" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </mat-card>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
