import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { ApiHttpService } from '../../../services/api-http.service';
import { ApiEndpointsService } from '../../../services/api-endpoints.service';


@Component({
  selector: 'app-dashboard-events',
  templateUrl: './dashboard-events.component.html',
  styleUrls: ['./dashboard-events.component.scss']
})
export class DashboardEventsComponent implements OnInit {
  dashboardEventData: any = {}
  constructor(
    private breakpointObserver: BreakpointObserver,
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService
  ) {
  }

  ngOnInit(): void {
  }

  isSmallHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Small])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isMediumScreen$: Observable<boolean> = this.breakpointObserver
    .observe(['(min-width:600px) and (max-width:677.98px)'])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isTablet$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Medium])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isLargeScreen$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Large])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isXLargeScreen$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XLarge])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  isXXLargeScreen$: Observable<boolean> = this.breakpointObserver
    .observe(['(min-width: 2200px)'])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );



  getEventData(lpsId: string, vehicleId: string) {
    this.apiHttpService
      .get(this.apiEndpointsService.getDashboardEventData(lpsId, vehicleId))
      .subscribe((response) => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        this.dashboardEventData = parsedResponse.data;
      })
  }
}
