<div class="portfolio-companies-container">
  <mat-tab-group
    dynamicHeight
    mat-align-tabs="start"
    class="invisible-headers"
    (selectedTabChange)="tabChanged($event)"
    [selectedIndex]="tabs.indexOf(portfolioTab)"
  >
    <mat-tab label="PortfolioTable">
      <div class="portfolio-companies table-container">
        <table
          mat-table
          [dataSource]="portfolioCompanyList"
          matSort
          (matSortChange)="announceSortChange($event)"
          *ngIf="portfolioCompanyList.length"
          class="mat-elevation-z8"
        >
          <ng-container matColumnDef="company">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by company"
            >
              <div class="title">Company</div>
            </th>

            <td mat-cell *matCellDef="let element">
              <img
                class="company-logo"
                *ngIf="element.logoPath && !isComponentLoaded; else logoLoader"
                [src]="element.logoPath"
                [alt]="element.name"
              />
            </td>
          </ng-container>

          <ng-container matColumnDef="initialInvestment">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by initial investment"
            >
              <div class="investmentContent">
                <div class="title">Initial investment</div>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="element.initialInvestment; else investmentLoader"
              >
                <div class="investmentContent">
                  <div class="amount">
                    {{ convertDecimal(element.initialInvestment.amount, true) }}
                  </div>
                  <div class="date">
                    {{ element.initialInvestment.date | date : 'dd. MMM yyyy' }}
                  </div>
                </div>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="latestInvestment">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by latest investment"
            >
              <div class="investmentContent">
                <div class="title">Latest investment</div>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="element.latestInvestment; else investmentLoader"
              >
                <div class="investmentContent">
                  <div class="amount">
                    {{ convertDecimal(element.latestInvestment.amount, true) }}
                  </div>
                  <div class="date">
                    {{ element.latestInvestment.date | date : 'dd. MMM yyyy' }}
                  </div>
                </div>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by status"
            >
              <div class="title">Status</div>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.status; else statusLoader">
                <div *ngIf="element.status === 'Above average'">
                  <img src="../../../../../assets/above-average.svg" />
                  <span class="status-content"> {{ element.status }} </span>
                </div>
                <div *ngIf="element.status === 'On track'">
                  <img src="../../../../../assets/on-track.svg" />
                  <span class="status-content"> {{ element.status }} </span>
                </div>
                <div *ngIf="element.status === 'Sub-performing'">
                  <img src="../../../../../assets/sub-performing.svg" />
                  <span class="status-content"> {{ element.status }} </span>
                </div>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="latestValuation">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              sortActionDescription="Sort by total investment"
            >
              <div class="investmentContent">
                <div class="title">Total investment</div>
              </div>
            </th>

            <td mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="element.totalInvestmentAmount; else investmentLoader"
              >
                <div class="investmentContent">
                  <div class="amount">
                    {{ convertDecimal(element.totalInvestmentAmount, true) }}
                  </div>
                </div>
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="currentYearRevenue">
            <th mat-header-cell *matHeaderCellDef>
              <div class="title">Current year revenue</div>
              <div class="subtitle">(Actual vs plan)</div>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="element.currentYearRevenue; else currentYearRevenue"
              >
                <div class="cssProgress">
                  <span class="plan"
                    >{{ convertDecimal(element.currentYearRevenue.plan, true) }}
                    <span>
                      <img src="../../../../../assets/revenue-plan.svg"
                    /></span>
                  </span>
                  <span class="actual"
                    ><span>
                      <img src="../../../../../assets/revenue-actual.svg"
                    /></span>
                    {{
                      convertDecimal(element.currentYearRevenue.actual, true)
                    }}</span
                  >
                  <div class="progress1">
                    <div
                      class="cssProgress-bar"
                      style="width: {{
                        calculateProgress(
                          element.currentYearRevenue.actual,
                          element.currentYearRevenue.plan
                        )
                      }};"
                    ></div>
                  </div>
                </div>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="companyValue">
            <th mat-header-cell *matHeaderCellDef>
              <div class="investmentContent">
                <div class="title">Company value</div>
              </div>
            </th>
            <td
              mat-cell
              class="company-value-container"
              *matCellDef="let element"
            >
              <ng-container
                *ngIf="element.companyValue; else companyValueLoader"
              >
                <div class="company-value">
                  <div class="company-value-chart">
                    <ul class="area-chart">
                      <ng-container
                        *ngFor="
                          let value of element.companyValue.amount;
                          let i = index
                        "
                      >
                        <li
                          *ngIf="i < element.companyValue.amount.length - 1"
                          [style.--start]="
                            element.companyValue.amount[i] /
                            getCompanyProgress(element.companyValue.amount)
                          "
                          [style.--end]="
                            element.companyValue.amount[i + 1] /
                            getCompanyProgress(element.companyValue.amount)
                          "
                        ></li>
                      </ng-container>
                    </ul>
                    <svg
                      class="company-value-arrow"
                      [style.--pos]="
                        (element.companyValue.amount[
                          element.companyValue.amount.length - 1
                        ] /
                          getCompanyProgress(element.companyValue.amount)) *
                          100 -
                        4 +
                        '%'
                      "
                      [style.--degreeValue]="
                        degreeArrow(
                          element.companyValue.amount[
                            element.companyValue.amount.length - 2
                          ],
                          element.companyValue.amount[
                            element.companyValue.amount.length - 1
                          ]
                        ) + 'deg'
                      "
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.00585762 5.00683L3.00049 0.000488133L5.99268 5.01904L0.00585762 5.00683Z"
                        fill="#00B886"
                      />
                    </svg>
                  </div>
                  <div class="investmentContent">
                    <div class="company-value-amount">
                      {{
                        convertDecimal(
                          element.companyValue.amount[
                            element.companyValue.amount.length - 1
                          ],
                          true
                        )
                      }}
                    </div>
                    <div class="date">
                      {{ element.companyValue.date | date : 'dd. MMM yyyy' }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.logoPath; else cellLoader">
                <img
                  class="view-company-arrow"
                  src="../../../../../assets/view-transaction-arrow.svg"
                />
              </ng-container>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="table-row"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="table-row"
            (click)="handleRowClick(row)"
            (mouseover)="row.hovered = true"
            (mouseout)="row.hovered = false"
          ></tr>
        </table>
      </div>

      <div class="portfolio-companies-handset">
        <div
          *ngFor="let portfolioCompany of portfolioCompanyList"
          class="portfolio-company-handset"
        >
          <mat-card class="portfolio-card-handset">
            <div class="segment">
              <p class="card-title">Company</p>
              <img
                class="company-logo"
                *ngIf="
                  portfolioCompany.logoPath && !isComponentLoaded;
                  else logoLoader
                "
                [src]="portfolioCompany.logoPath"
                [alt]="portfolioCompany.name"
              />
            </div>
            <div class="segment segment-right">
              <p class="card-title">Initial investment</p>
              <ng-container
                *ngIf="
                  portfolioCompany.initialInvestment;
                  else investmentLoader
                "
              >
                <div class="investmentContent">
                  <div class="amount">
                    {{
                      convertDecimal(
                        portfolioCompany.initialInvestment.amount,
                        true
                      )
                    }}
                  </div>
                  <div class="date">
                    {{
                      portfolioCompany.initialInvestment.date
                        | date : 'dd. MMM yyyy'
                    }}
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="segment">
              <p class="card-title">Latest investment</p>
              <ng-container
                *ngIf="portfolioCompany.latestInvestment; else investmentLoader"
              >
                <div class="investmentContent">
                  <div class="amount">
                    {{
                      convertDecimal(portfolioCompany.latestInvestment.amount)
                    }}
                  </div>
                  <div class="date">
                    {{
                      portfolioCompany.latestInvestment.date
                        | date : 'dd. MMM yyyy'
                    }}
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="segment segment-right">
              <p class="card-title">Status</p>
              <ng-container *ngIf="portfolioCompany.status; else statusLoader">
                <div *ngIf="portfolioCompany.status === 'Above average'">
                  <img
                    class="status-img"
                    src="../../../../../assets/above-average.svg"
                  />
                  <span class="status-content">
                    {{ portfolioCompany.status }}
                  </span>
                </div>
                <div *ngIf="portfolioCompany.status === 'On track'">
                  <img
                    class="status-img"
                    src="../../../../../assets/on-track.svg"
                  />
                  <span class="status-content">
                    {{ portfolioCompany.status }}
                  </span>
                </div>
                <div *ngIf="portfolioCompany.status === 'Sub-performing'">
                  <img
                    class="status-img"
                    src="../../../../../assets/sub-performing.svg"
                  />
                  <span class="status-content">
                    {{ portfolioCompany.status }}
                  </span>
                </div>
              </ng-container>
            </div>
            <div class="segment">
              <p class="card-title">Total investment</p>
              <ng-container
                *ngIf="
                  portfolioCompany.totalInvestmentAmount;
                  else investmentLoader
                "
              >
                <div class="investmentContent">
                  <div class="amount">
                    {{ convertDecimal(portfolioCompany.totalInvestmentAmount) }}
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="segment segment-right">
              <div class="current-year-revenue">
                <div class="title">Current year revenue</div>
                <div class="subtitle">(Actual vs plan)</div>
              </div>
              <ng-container
                *ngIf="
                  portfolioCompany.currentYearRevenue;
                  else currentYearRevenue
                "
              >
                <div class="cssProgress">
                  <span class="plan"
                    >{{ portfolioCompany.currentYearRevenue.plan }}
                    <span>
                      <img src="../../../../../assets/revenue-plan.svg"
                    /></span>
                  </span>
                  <span class="actual"
                    ><span>
                      <img src="../../../../../assets/revenue-actual.svg"
                    /></span>
                    {{ portfolioCompany.currentYearRevenue.actual }}</span
                  >
                  <div class="progress1">
                    <div
                      class="cssProgress-bar"
                      style="width: {{
                        calculateProgress(
                          portfolioCompany.currentYearRevenue.actual,
                          portfolioCompany.currentYearRevenue.plan
                        )
                      }};"
                    ></div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="segment">
              <p class="card-title">Company value</p>
              <ng-container
                *ngIf="portfolioCompany.companyValue; else companyValueLoader"
              >
                <div class="company-value">
                  <div class="company-value-chart">
                    <ul class="area-chart">
                      <ng-container
                        *ngFor="
                          let value of portfolioCompany.companyValue.amount;
                          let i = index
                        "
                      >
                        <li
                          *ngIf="
                            i < portfolioCompany.companyValue.amount.length - 1
                          "
                          [style.--start]="
                            portfolioCompany.companyValue.amount[i] /
                            getCompanyProgress(
                              portfolioCompany.companyValue.amount
                            )
                          "
                          [style.--end]="
                            portfolioCompany.companyValue.amount[i + 1] /
                            getCompanyProgress(
                              portfolioCompany.companyValue.amount
                            )
                          "
                        ></li>
                      </ng-container>
                    </ul>
                    <svg
                      class="company-value-arrow"
                      [style.--pos]="
                        (portfolioCompany.companyValue.amount[
                          portfolioCompany.companyValue.amount.length - 1
                        ] /
                          getCompanyProgress(
                            portfolioCompany.companyValue.amount
                          )) *
                          100 -
                        4 +
                        '%'
                      "
                      [style.--degreeValue]="
                        degreeArrow(
                          portfolioCompany.companyValue.amount[
                            portfolioCompany.companyValue.amount.length - 2
                          ],
                          portfolioCompany.companyValue.amount[
                            portfolioCompany.companyValue.amount.length - 1
                          ]
                        ) + 'deg'
                      "
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.00585762 5.00683L3.00049 0.000488133L5.99268 5.01904L0.00585762 5.00683Z"
                        fill="#00B886"
                      />
                    </svg>
                  </div>
                  <div class="investmentContent">
                    <div class="company-value-amount">
                      {{
                        convertDecimal(
                          portfolioCompany.companyValue.amount[
                            portfolioCompany.companyValue.amount.length - 1
                          ],
                          true
                        )
                      }}
                    </div>
                    <div class="date">
                      {{
                        portfolioCompany.companyValue.date
                          | date : 'dd. MMM yyyy'
                      }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="segment segment-right">
              <div class="view-details">
                <div class="button" (click)="handleRowClick(portfolioCompany)">
                  <span class="view-details-content">View Details</span>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3293_30517)">
                      <path
                        class="primary"
                        d="M16.465 15.0001L10.2775 8.81258L12.045 7.04508L20 15.0001L12.045 22.9551L10.2775 21.1876L16.465 15.0001Z"
                        fill="#BBBBBB"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3293_30517">
                        <rect
                          width="30"
                          height="30"
                          fill="white"
                          transform="translate(30 30) rotate(-180)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>

      <div
        class="noRecord"
        *ngIf="portfolioCompanyList.length == 0 && isComponentLoaded"
      >
        No records found
      </div>
    </mat-tab>

    <mat-tab label="overview">
      <!-- <app-company-overview [companyOverviewData]="companyOverviewData"></app-company-overview> -->
      <div class="portfolio-companies-overview">
        <mat-tab-group
          dynamicHeight
          mat-align-tabs="start"
          class="invisible-headers"
          [(selectedIndex)]="nestedSelectedTabIndex"
        >
          <mat-tab label="First Page">
            <div class="company-overview-container">
              <div class="back-button">
                <button mat-raised-button (click)="backNavigation()">
                  <!-- <img src="../../../../assets/arrow-back-gray.svg" /> -->
                  <svg
                    width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="primary"
                      d="M3.06367 6.89433L8.42617 12.2568L6.89433 13.7887L0 6.89433L6.89433 0L8.42617 1.53183L3.06367 6.89433Z"
                      fill="#00B886"
                    />
                  </svg>
                  <span class="text">Back</span>
                </button>
                <span class="breadcrumb-text">{{ companyName }}</span>
              </div>

              <div class="overview-container">
                <mat-grid-list cols="2" rowHeight="20px" gutterSize="20px">
                  <mat-grid-tile rowspan="13">
                    <mat-card>
                      <div class="card-container">
                        <div class="cardHead">
                          <div>
                            <div class="tile"></div>
                            <div class="cardHeadLeft">
                              <span
                                *ngIf="
                                  companyOverviewData;
                                  else companyNameLoader
                                "
                                >{{ companyOverviewData.name }}</span
                              >
                              <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                            </div>
                          </div>
                        </div>
                        <div class="cardBody">
                          <div class="company-details">
                            <div class="logo-description">
                              <div class="logo">
                                <img
                                  *ngIf="
                                    companyOverviewData;
                                    else largeLogoLoader
                                  "
                                  src="{{ companyOverviewData.logoPath }}"
                                  alt="Company Logo"
                                />
                              </div>
                              <div class="description">
                                <p
                                  *ngIf="
                                    companyOverviewData;
                                    else descriptionLoader
                                  "
                                >
                                  {{ companyOverviewData.summary }}
                                </p>
                              </div>
                            </div>
                            <div class="other-details">
                              <div
                                class="detail legal-name"
                                *ngIf="companyOverviewData; else detailsLoader"
                              >
                                <div class="icon">
                                  <img
                                    src="../../../../../assets/home-icon.svg"
                                    alt="Legal Name"
                                  />
                                </div>
                                <div class="content">
                                  <p class="value">
                                    {{ companyOverviewData.legalName }}
                                  </p>
                                  <p class="title">Legal name</p>
                                </div>
                              </div>
                              <div
                                class="detail location"
                                *ngIf="companyOverviewData; else detailsLoader"
                              >
                                <div class="icon">
                                  <img
                                    src="../../../../../assets/location-icon.svg"
                                    alt="Legal Name"
                                  />
                                </div>
                                <div class="content">
                                  <p class="value">
                                    {{ companyOverviewData.domicile }}
                                  </p>
                                  <p class="title">Domicile</p>
                                </div>
                              </div>
                              <div
                                class="detail website"
                                *ngIf="companyOverviewData; else detailsLoader"
                              >
                                <div class="icon">
                                  <img
                                    src="../../../../../assets/website-icon.svg"
                                    alt="Legal Name"
                                  />
                                </div>
                                <div class="content">
                                  <p class="value">
                                    <a
                                      href="{{
                                        getValidURL(
                                          companyOverviewData.websiteUrl
                                        )
                                      }}"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      >{{ companyOverviewData.websiteUrl }}</a
                                    >
                                  </p>
                                  <p class="title">Website</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-card>
                  </mat-grid-tile>
                  <mat-grid-tile rowspan="14">
                    <mat-card>
                      <div class="card-container">
                        <div class="cardHead">
                          <div>
                            <div class="tile"></div>
                            <div class="cardHeadLeft">
                              Business metrics
                              <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                            </div>
                          </div>
                        </div>
                        <div class="cardBody business-metrics-body">
                          <div class="business-metrics-container">
                            <div class="business-metrics-table">
                              <div class="table-header">
                                <div class="column column-0">
                                  <span class="table-title name">Name</span>
                                </div>
                                <div class="column column-1">
                                  <span class="table-title current-value"
                                    >Current values</span
                                  >
                                </div>
                                <div class="column column-2">
                                  <span class="table-title plan-vs-actual"
                                    >Plan vs. actual</span
                                  >
                                </div>
                                <div class="column column-3">
                                  <span class="table-title previous-values"
                                    >Previous values</span
                                  >
                                </div>
                                <div class="column column-4">
                                  <span class="table-title qoq">QoQ</span>
                                </div>
                                <div class="column column-5">
                                  <span class="table-title yoy">YoY</span>
                                </div>
                              </div>
                              <div class="table-body-container">
                                <ng-container
                                  *ngIf="
                                    companyOverviewData;
                                    else businessMetricTableLoader
                                  "
                                >
                                  <div
                                    class="table-body"
                                    (click)="updateChartData(data, i)"
                                    tabindex="-1"
                                    [ngClass]="'row-' + i"
                                    [ngClass]="
                                      i === selectedChartBusinessMetric
                                        ? 'active'
                                        : ''
                                    "
                                    *ngFor="
                                      let data of companyOverviewData.businessMetrics;
                                      let i = index
                                    "
                                  >
                                    <div class="column column-0">
                                      <span class="table-data name">{{
                                        data.name
                                      }}</span>
                                    </div>
                                    <div class="column column-1">
                                      <span class="table-data curren-value">{{
                                        convertDecimal(data.value.current)
                                      }}</span>
                                    </div>
                                    <div class="column column-2">
                                      <span class="table-data plan-vs-actual"
                                        >{{
                                          convertDecimal(
                                            data.comparison.planVsActual
                                          )
                                        }}%</span
                                      >
                                    </div>
                                    <div class="column column-3">
                                      <span
                                        class="table-data previous-values"
                                        >{{
                                          convertDecimal(data.value.previous)
                                        }}</span
                                      >
                                    </div>
                                    <div class="column column-4">
                                      <span class="table-data qoq"
                                        >{{
                                          convertDecimal(
                                            data.comparison.quarterOnQuarter
                                          )
                                        }}%</span
                                      >
                                    </div>
                                    <div class="column column-5">
                                      <span class="table-data yoy"
                                        >{{ data.comparison.yearOnYear }}%</span
                                      >
                                    </div>
                                  </div>
                                </ng-container>

                                <ng-template #businessMetricTableLoader>
                                  <div
                                    class="table-body"
                                    tabindex="-1"
                                    [ngClass]="'row-' + i"
                                    *ngFor="
                                      let data of [
                                        { name: 'ARR' },
                                        { name: 'EBITDA' },
                                        { name: 'Headcount' },
                                        { name: 'Headcount2' }
                                      ];
                                      let i = index
                                    "
                                  >
                                    <div class="column column-0">
                                      <span class="table-data">{{
                                        data.name
                                      }}</span>
                                    </div>
                                    <div class="column column-1">
                                      <span class="table-data">
                                        <ngx-skeleton-loader
                                          count="1"
                                          [theme]="{
                                            width: '70px',
                                            height: '20px',
                                            margin: '1em 0 0 0'
                                          }"
                                        ></ngx-skeleton-loader>
                                      </span>
                                    </div>
                                    <div class="column column-2">
                                      <span class="table-data">
                                        <ngx-skeleton-loader
                                          count="1"
                                          [theme]="{
                                            width: '70px',
                                            height: '20px',
                                            margin: '1em 0 0 0'
                                          }"
                                        ></ngx-skeleton-loader>
                                      </span>
                                    </div>
                                    <div class="column column-3">
                                      <span class="table-data">
                                        <ngx-skeleton-loader
                                          count="1"
                                          [theme]="{
                                            width: '70px',
                                            height: '20px',
                                            margin: '1em 0 0 0'
                                          }"
                                        ></ngx-skeleton-loader>
                                      </span>
                                    </div>
                                    <div class="column column-4">
                                      <span class="table-data">
                                        <ngx-skeleton-loader
                                          count="1"
                                          [theme]="{
                                            width: '70px',
                                            height: '20px',
                                            margin: '1em 0 0 0'
                                          }"
                                        ></ngx-skeleton-loader>
                                      </span>
                                    </div>
                                    <div class="column column-5">
                                      <span class="table-data">
                                        <ngx-skeleton-loader
                                          count="1"
                                          [theme]="{
                                            width: '70px',
                                            height: '20px',
                                            margin: '1em 0 0 0'
                                          }"
                                        ></ngx-skeleton-loader>
                                      </span>
                                    </div>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                            <ng-container
                              *ngIf="
                                companyOverviewData;
                                else businessMetricGraphLoader
                              "
                            >
                              <ng-container
                                *ngIf="isBusinessMetricChartUpdated$ | async"
                              >
                                <div class="chart-container">
                                  <span class="business-metrics-chart-title">{{
                                    chartTitle
                                  }}</span>
                                  <div class="business-metrics-graph">
                                    <app-chart
                                      [chartData]="chartData"
                                      [legendColors]="legendColors"
                                    ></app-chart>
                                    <div
                                      *ngIf="chartData"
                                      class="insight-legends"
                                    >
                                      <div
                                        *ngFor="
                                          let dataset of chartData.data
                                            .datasets;
                                          let i = index
                                        "
                                      >
                                        <span
                                          class="legend-tile"
                                          [ngStyle]="{
                                            'background-color':
                                              dataset.label !== 'Exits'
                                                ? legendColors[i]
                                                : '#9ba6b0'
                                          }"
                                        ></span
                                        ><span class="legend-title">{{
                                          dataset.label
                                        }}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                            <ng-template #businessMetricGraphLoader>
                              <div class="loader-container">
                                <div class="loader-head">
                                  <ngx-skeleton-loader
                                    count="1"
                                    [theme]="{
                                      width: '80px',
                                      height: '25px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div class="loader-body">
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '10px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '20px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '30px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '40px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '50px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '60px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '70px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '80px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '90px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '100px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '110px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '120px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '130px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '140px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '150px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '160px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '170px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div>
                                    <ngx-skeleton-loader
                                      count="1"
                                      appearance="line"
                                      [theme]="{
                                        height: '180px',
                                        'border-radius': '0px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </mat-card>
                  </mat-grid-tile>
                  <mat-grid-tile rowspan="10">
                    <mat-card>
                      <div class="card-container">
                        <div class="cardHead">
                          <div>
                            <div class="tile"></div>
                            <div class="cardHeadLeft">Ownership overview</div>
                          </div>
                          <div class="cardHeadRight">
                            <a (click)="handleMenuClick('ownership-overview')">
                              {{
                                'translations.dashboard.seeDetails' | translate
                              }}
                            </a>
                          </div>
                        </div>
                        <div class="cardBody ownership-overview-body">
                          <div class="ownership-overview">
                            <div class="segment">
                              <div class="heading">
                                <span>Outstanding</span>
                              </div>
                              <div
                                class="ownership-data"
                                *ngIf="
                                  companyOverviewData;
                                  else ownershipOverviewLoader
                                "
                              >
                                <p class="value">
                                  {{
                                    companyOverviewData.outstanding.ownedShares
                                  }}
                                </p>
                                <p class="title">Owned shares</p>
                              </div>
                              <div
                                class="ownership-data"
                                *ngIf="
                                  companyOverviewData;
                                  else ownershipOverviewLoader
                                "
                              >
                                <p class="value">
                                  {{
                                    companyOverviewData.outstanding
                                      .fundOwnership !== undefined &&
                                    companyOverviewData.outstanding
                                      .fundOwnership !== null
                                      ? convertDecimal(
                                          companyOverviewData.outstanding
                                            .fundOwnership
                                        ) + ' %'
                                      : '-'
                                  }}
                                </p>
                                <p class="title">Fund ownership</p>
                              </div>
                              <div
                                class="ownership-data"
                                *ngIf="
                                  companyOverviewData;
                                  else ownershipOverviewLoader
                                "
                              >
                                <p class="value">
                                  {{
                                    companyOverviewData.outstanding
                                      .totalNumberOfShares
                                  }}
                                </p>
                                <p class="title">Total number of shares</p>
                              </div>
                            </div>
                            <div class="segment">
                              <div class="heading">
                                <span>Fully Diluted</span>
                              </div>
                              <div
                                class="ownership-data"
                                *ngIf="
                                  companyOverviewData;
                                  else ownershipOverviewLoader
                                "
                              >
                                <p class="value">
                                  {{
                                    convertDecimal(
                                      companyOverviewData.fullyDiluted
                                        .ownedShares
                                    )
                                  }}
                                </p>
                                <p class="title">Owned shares</p>
                              </div>
                              <div
                                class="ownership-data"
                                *ngIf="
                                  companyOverviewData;
                                  else ownershipOverviewLoader
                                "
                              >
                                <p class="value">
                                  {{
                                    companyOverviewData.fullyDiluted
                                      .fundOwnership !== undefined &&
                                    companyOverviewData.fullyDiluted
                                      .fundOwnership !== null
                                      ? convertDecimal(
                                          companyOverviewData.fullyDiluted
                                            .fundOwnership
                                        ) + ' %'
                                      : '-'
                                  }}
                                </p>
                                <p class="title">Fund ownership</p>
                              </div>
                              <div
                                class="ownership-data"
                                *ngIf="
                                  companyOverviewData;
                                  else ownershipOverviewLoader
                                "
                              >
                                <p class="value">
                                  {{
                                    convertToDigits(
                                      companyOverviewData.fullyDiluted
                                        .totalNumberOfShares
                                    )
                                  }}
                                </p>
                                <p class="title">Total number of shares</p>
                              </div>
                            </div>
                            <div class="segment">
                              <div class="heading heading-3">
                                <span
                                  >Board <br />
                                  Representation</span
                                >
                              </div>
                              <div
                                class="ownership-data"
                                *ngIf="
                                  companyOverviewData;
                                  else ownershipOverviewLoader
                                "
                              >
                                <p class="value">
                                  {{ companyOverviewData.board.numberOfSeats }}
                                </p>
                                <p class="title">Number of board seats</p>
                              </div>
                              <div
                                class="ownership-data"
                                *ngIf="
                                  companyOverviewData;
                                  else ownershipOverviewLoader
                                "
                              >
                                <p class="value">
                                  {{ companyOverviewData.board.members[0] }}
                                </p>
                                <p class="title">Board member</p>
                              </div>
                              <div
                                class="ownership-data"
                                *ngIf="
                                  companyOverviewData;
                                  else ownershipOverviewLoader
                                "
                              >
                                <p class="value">
                                  {{ companyOverviewData.board.members[1] }}
                                </p>
                                <p class="title">Board member</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-card>
                  </mat-grid-tile>
                  <mat-grid-tile rowspan="9">
                    <mat-card>
                      <div class="card-container">
                        <div class="cardHead">
                          <div>
                            <div class="tile"></div>
                            <div class="cardHeadLeft">
                              Top 5 stakeholders
                              <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                            </div>
                          </div>
                          <div class="cardHeadRight">
                            <a (click)="handleMenuClick('stakeholders')">
                              {{
                                'translations.dashboard.seeDetails' | translate
                              }}
                            </a>
                          </div>
                        </div>
                        <div class="cardBody">
                          <div class="stakeholders">
                            <div class="stakeholders-table">
                              <div class="table-header">
                                <div class="column">
                                  <span>Name</span>
                                </div>
                                <div class="column">
                                  <span>Owned shares (FD)</span>
                                </div>
                                <div class="column">
                                  <span>Ownership (FD)</span>
                                </div>
                              </div>
                              <ng-container
                                *ngIf="
                                  companyOverviewData;
                                  else stakeholderLoader
                                "
                              >
                                <div class="table-body">
                                  <div
                                    class="row"
                                    *ngFor="
                                      let data of companyOverviewData.stakeholders
                                    "
                                  >
                                    <div class="column">
                                      <span>{{ data.name }}</span>
                                    </div>
                                    <div class="column">
                                      <span>{{
                                        convertToDigits(data.ownedShares)
                                      }}</span>
                                    </div>
                                    <div class="column">
                                      <span>{{
                                        data.ownership !== null &&
                                        data.ownership !== undefined
                                          ? convertDecimal(data.ownership) +
                                            ' %'
                                          : '-'
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-template #stakeholderLoader>
                                <div class="table-body">
                                  <div
                                    class="row"
                                    *ngFor="let data of [{}, {}, {}, {}, {}]"
                                  >
                                    <div class="column">
                                      <ngx-skeleton-loader
                                        count="1"
                                        [theme]="{
                                          width: '120px',
                                          height: '25px'
                                        }"
                                      ></ngx-skeleton-loader>
                                    </div>
                                    <div class="column">
                                      <ngx-skeleton-loader
                                        count="1"
                                        [theme]="{
                                          width: '120px',
                                          height: '25px',
                                          float: 'right'
                                        }"
                                      ></ngx-skeleton-loader>
                                    </div>
                                    <div class="column">
                                      <ngx-skeleton-loader
                                        count="1"
                                        [theme]="{
                                          width: '120px',
                                          height: '25px',
                                          float: 'right'
                                        }"
                                      ></ngx-skeleton-loader>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-card>
                  </mat-grid-tile>
                </mat-grid-list>
              </div>
              <div class="overview-container-handset">
                <div class="overview-box">
                  <mat-card>
                    <div class="card-container">
                      <div class="cardHead">
                        <div>
                          <div class="tile"></div>
                          <div class="cardHeadLeft">
                            <span
                              *ngIf="
                                companyOverviewData;
                                else companyNameLoader
                              "
                              >{{ companyOverviewData.name }}</span
                            >
                            <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                          </div>
                        </div>
                      </div>
                      <div class="cardBody">
                        <div class="company-details">
                          <div class="logo-description">
                            <div class="logo">
                              <img
                                *ngIf="
                                  companyOverviewData;
                                  else largeLogoLoader
                                "
                                src="{{ companyOverviewData.logoPath }}"
                                alt="Company Logo"
                              />
                            </div>
                            <div class="description">
                              <p
                                *ngIf="
                                  companyOverviewData;
                                  else descriptionLoader
                                "
                              >
                                {{ companyOverviewData.summary }}
                              </p>
                            </div>
                          </div>
                          <div class="other-details">
                            <div
                              class="detail legal-name"
                              *ngIf="companyOverviewData; else detailsLoader"
                            >
                              <div class="icon">
                                <img
                                  src="../../../../../assets/home-icon.svg"
                                  alt="Legal Name"
                                />
                              </div>
                              <div class="content">
                                <p class="value">
                                  {{ companyOverviewData.legalName }}
                                </p>
                                <p class="title">Legal name</p>
                              </div>
                            </div>
                            <div
                              class="detail location"
                              *ngIf="companyOverviewData; else detailsLoader"
                            >
                              <div class="icon">
                                <img
                                  src="../../../../../assets/location-icon.svg"
                                  alt="Legal Name"
                                />
                              </div>
                              <div class="content">
                                <p class="value">
                                  {{ companyOverviewData.domicile }}
                                </p>
                                <p class="title">Domicile</p>
                              </div>
                            </div>
                            <div
                              class="detail website"
                              *ngIf="companyOverviewData; else detailsLoader"
                            >
                              <div class="icon">
                                <img
                                  src="../../../../../assets/website-icon.svg"
                                  alt="Legal Name"
                                />
                              </div>
                              <div class="content">
                                <p class="value">
                                  <a
                                    href="{{
                                      getValidURL(
                                        companyOverviewData.websiteUrl
                                      )
                                    }}"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >{{ companyOverviewData.websiteUrl }}</a
                                  >
                                </p>
                                <p class="title">Website</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div class="overview-box">
                  <mat-card>
                    <div class="card-container">
                      <div class="cardHead">
                        <div>
                          <div class="tile"></div>
                          <div class="cardHeadLeft">
                            Business metrics
                            <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                          </div>
                        </div>
                      </div>
                      <div class="cardBody business-metrics-body">
                        <div class="business-metrics-container">
                          <div class="business-metrics-table">
                            <div class="table-header">
                              <div class="column">
                                <span class="table-title name">Name</span>
                              </div>
                              <div class="column">
                                <span class="table-title current-value"
                                  >Current values</span
                                >
                              </div>
                              <div class="column">
                                <span class="table-title plan-vs-actual"
                                  >Plan vs. actual</span
                                >
                              </div>
                              <div class="column">
                                <span class="table-title previous-values"
                                  >Previous values</span
                                >
                              </div>
                              <div class="column">
                                <span class="table-title qoq">QoQ</span>
                              </div>
                              <div class="column">
                                <span class="table-title yoy">YoY</span>
                              </div>
                            </div>
                            <div class="table-body-container">
                              <ng-container
                                *ngIf="
                                  companyOverviewData;
                                  else businessMetricTableLoader
                                "
                              >
                                <div
                                  class="table-body"
                                  (click)="updateChartData(data, i)"
                                  tabindex="-1"
                                  [ngClass]="'row-' + i"
                                  [ngClass]="
                                    i === selectedChartBusinessMetric
                                      ? 'active'
                                      : ''
                                  "
                                  *ngFor="
                                    let data of companyOverviewData.businessMetrics;
                                    let i = index
                                  "
                                >
                                  <div class="column column-0">
                                    <span class="table-data name">{{
                                      data.name
                                    }}</span>
                                  </div>
                                  <div class="column column-1">
                                    <span class="table-data curren-value">{{
                                      data.value.current
                                    }}</span>
                                  </div>
                                  <div class="column column-2">
                                    <span class="table-data plan-vs-actual">{{
                                      data.comparison.planVsActual
                                    }}</span>
                                  </div>
                                  <div class="column column-3">
                                    <span class="table-data previous-values">
                                      {{
                                        convertDecimal(data.value.previous)
                                      }}</span
                                    >
                                  </div>
                                  <div class="column column-4">
                                    <span class="table-data qoq">{{
                                      data.comparison.quarterOnQuarter
                                    }}</span>
                                  </div>
                                  <div class="column column-5">
                                    <span class="table-data yoy">{{
                                      data.comparison.yearOnYear
                                    }}</span>
                                  </div>
                                </div>
                              </ng-container>

                              <ng-template #businessMetricTableLoader>
                                <div
                                  class="table-body"
                                  tabindex="-1"
                                  [ngClass]="'row-' + i"
                                  *ngFor="
                                    let data of [
                                      { name: 'ARR' },
                                      { name: 'EBITDA' },
                                      { name: 'Headcount' },
                                      { name: 'Headcount2' }
                                    ];
                                    let i = index
                                  "
                                >
                                  <div class="column column-0">
                                    <span class="table-data">{{
                                      data.name
                                    }}</span>
                                  </div>
                                  <div class="column column-1">
                                    <span class="table-data">
                                      <ngx-skeleton-loader
                                        count="1"
                                        [theme]="{
                                          width: '70px',
                                          height: '20px',
                                          margin: '1em 0 0 0'
                                        }"
                                      ></ngx-skeleton-loader>
                                    </span>
                                  </div>
                                  <div class="column column-2">
                                    <span class="table-data">
                                      <ngx-skeleton-loader
                                        count="1"
                                        [theme]="{
                                          width: '70px',
                                          height: '20px',
                                          margin: '1em 0 0 0'
                                        }"
                                      ></ngx-skeleton-loader>
                                    </span>
                                  </div>
                                  <div class="column column-3">
                                    <span class="table-data">
                                      <ngx-skeleton-loader
                                        count="1"
                                        [theme]="{
                                          width: '70px',
                                          height: '20px',
                                          margin: '1em 0 0 0'
                                        }"
                                      ></ngx-skeleton-loader>
                                    </span>
                                  </div>
                                  <div class="column column-4">
                                    <span class="table-data">
                                      <ngx-skeleton-loader
                                        count="1"
                                        [theme]="{
                                          width: '70px',
                                          height: '20px',
                                          margin: '1em 0 0 0'
                                        }"
                                      ></ngx-skeleton-loader>
                                    </span>
                                  </div>
                                  <div class="column column-5">
                                    <span class="table-data">
                                      <ngx-skeleton-loader
                                        count="1"
                                        [theme]="{
                                          width: '70px',
                                          height: '20px',
                                          margin: '1em 0 0 0'
                                        }"
                                      ></ngx-skeleton-loader>
                                    </span>
                                  </div>
                                </div>
                              </ng-template>
                            </div>
                          </div>

                          <ng-container
                            *ngIf="
                              companyOverviewData;
                              else businessMetricGraphLoader
                            "
                          >
                            <ng-container
                              *ngIf="isBusinessMetricChartUpdated$ | async"
                            >
                              <div class="chart-container">
                                <span class="business-metrics-chart-title">{{
                                  chartTitle
                                }}</span>
                                <div class="business-metrics-graph">
                                  <app-chart
                                    [chartData]="chartData"
                                    [legendColors]="legendColors"
                                  ></app-chart>
                                  <div
                                    *ngIf="chartData"
                                    class="insight-legends"
                                  >
                                    <div
                                      *ngFor="
                                        let dataset of chartData.data.datasets;
                                        let i = index
                                      "
                                    >
                                      <span
                                        class="legend-tile"
                                        [ngStyle]="{
                                          'background-color':
                                            dataset.label !== 'Exits'
                                              ? legendColors[i]
                                              : '#9ba6b0'
                                        }"
                                      ></span
                                      ><span class="legend-title">{{
                                        dataset.label
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>
                          <ng-template #businessMetricGraphLoader>
                            <div class="loader-container">
                              <div class="loader-head">
                                <ngx-skeleton-loader
                                  count="1"
                                  [theme]="{
                                    width: '80px',
                                    height: '25px'
                                  }"
                                ></ngx-skeleton-loader>
                              </div>
                              <div class="loader-body">
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '10px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '20px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '30px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '40px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '50px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '60px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '70px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '80px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '90px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '100px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '110px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '120px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '130px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '140px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '150px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '160px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '170px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                                <div>
                                  <ngx-skeleton-loader
                                    count="1"
                                    appearance="line"
                                    [theme]="{
                                      height: '180px',
                                      'border-radius': '0px'
                                    }"
                                  ></ngx-skeleton-loader>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div class="overview-box">
                  <mat-card>
                    <div class="card-container">
                      <div class="cardHead">
                        <div>
                          <div class="tile"></div>
                          <div class="cardHeadLeft">
                            Ownership overview
                            <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                          </div>
                        </div>
                        <div class="cardHeadRight">
                          <a (click)="handleMenuClick('ownership-overview')">
                            {{
                              'translations.dashboard.seeDetails' | translate
                            }}
                          </a>
                        </div>
                      </div>
                      <div class="cardBody ownership-overview-body">
                        <div class="ownership-overview">
                          <div class="segment">
                            <div class="heading"><span>Outstanding</span></div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewData;
                                else ownershipOverviewLoader
                              "
                            >
                              <p class="value">
                                {{
                                  companyOverviewData.outstanding.ownedShares
                                }}
                              </p>
                              <p class="title">Owned shares</p>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewData;
                                else ownershipOverviewLoader
                              "
                            >
                              <p class="value">
                                {{
                                  companyOverviewData.outstanding
                                    .fundOwnership !== undefined &&
                                  companyOverviewData.outstanding
                                    .fundOwnership !== null
                                    ? convertDecimal(
                                        companyOverviewData.outstanding
                                          .fundOwnership
                                      ) + ' %'
                                    : '-'
                                }}
                              </p>
                              <p class="title">Fund ownership</p>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewData;
                                else ownershipOverviewLoader
                              "
                            >
                              <p class="value">
                                {{
                                  convertToDigits(
                                    companyOverviewData.outstanding
                                      .totalNumberOfShares
                                  )
                                }}
                              </p>
                              <p class="title">Total number of shares</p>
                            </div>
                          </div>
                          <div class="segment">
                            <div class="heading">
                              <span>Fully Diluted</span>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewData;
                                else ownershipOverviewLoader
                              "
                            >
                              <p class="value">
                                {{
                                  companyOverviewData.fullyDiluted.ownedShares
                                }}
                              </p>
                              <p class="title">Owned shares</p>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewData;
                                else ownershipOverviewLoader
                              "
                            >
                              <p class="value">
                                {{
                                  companyOverviewData.outstanding
                                    .fundOwnership !== undefined &&
                                  companyOverviewData.outstanding
                                    .fundOwnership !== null
                                    ? convertDecimal(
                                        companyOverviewData.outstanding
                                          .fundOwnership
                                      ) + ' %'
                                    : '-'
                                }}
                              </p>
                              <p class="title">Fund ownership</p>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewData;
                                else ownershipOverviewLoader
                              "
                            >
                              <p class="value">
                                {{
                                  companyOverviewData.fullyDiluted
                                    .totalNumberOfShares
                                }}
                              </p>
                              <p class="title">Total number of shares</p>
                            </div>
                          </div>
                          <div class="segment">
                            <div class="heading heading-3">
                              <span>Board Representation</span>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewData;
                                else ownershipOverviewLoader
                              "
                            >
                              <p class="value">
                                {{ companyOverviewData.board.numberOfSeats }}
                              </p>
                              <p class="title">Number of board seats</p>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewData;
                                else ownershipOverviewLoader
                              "
                            >
                              <p class="value">
                                {{ companyOverviewData.board.numberOfSeats }}
                              </p>
                              <p class="title">Board member</p>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewData;
                                else ownershipOverviewLoader
                              "
                            >
                              <p class="value">
                                {{ companyOverviewData.board.members }}
                              </p>
                              <p class="title">Board member</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div class="overview-box">
                  <mat-card>
                    <div class="card-container">
                      <div class="cardHead">
                        <div>
                          <div class="tile"></div>
                          <div class="cardHeadLeft">
                            Top 5 stakeholders
                            <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                          </div>
                        </div>
                        <div class="cardHeadRight">
                          <a (click)="handleMenuClick('stakeholders')">
                            {{
                              'translations.dashboard.seeDetails' | translate
                            }}
                          </a>
                        </div>
                      </div>
                      <div class="cardBody">
                        <div class="stakeholders">
                          <div class="stakeholders-table">
                            <div class="table-header">
                              <div class="column">
                                <span>Name</span>
                              </div>
                              <div class="column">
                                <span>Owned shares (FD)</span>
                              </div>
                              <div class="column">
                                <span>Ownership (FD)</span>
                              </div>
                            </div>
                            <ng-container
                              *ngIf="
                                companyOverviewData;
                                else stakeholderLoader
                              "
                            >
                              <div class="table-body">
                                <div
                                  class="row"
                                  *ngFor="
                                    let data of companyOverviewData.stakeholders
                                  "
                                >
                                  <div class="column">
                                    <span>{{ data.name }}</span>
                                  </div>
                                  <div class="column">
                                    <span>{{
                                      convertToDigits(data.ownedShares)
                                    }}</span>
                                  </div>
                                  <div class="column">
                                    <span>{{
                                      data.ownership !== null &&
                                      data.ownership !== undefined
                                        ? convertDecimal(data.ownership) + ' %'
                                        : '-'
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                            <ng-template #stakeholderLoader>
                              <div class="table-body">
                                <div
                                  class="row"
                                  *ngFor="let data of [{}, {}, {}, {}, {}]"
                                >
                                  <div class="column">
                                    <ngx-skeleton-loader
                                      count="1"
                                      [theme]="{
                                        width: '120px',
                                        height: '25px'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div class="column">
                                    <ngx-skeleton-loader
                                      count="1"
                                      [theme]="{
                                        width: '120px',
                                        height: '25px',
                                        float: 'right'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                  <div class="column">
                                    <ngx-skeleton-loader
                                      count="1"
                                      [theme]="{
                                        width: '120px',
                                        height: '25px',
                                        float: 'right'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>
              </div>
              <div
                class="noRecord"
                *ngIf="!companyOverviewData || companyOverviewData.length == 0"
              >
                No records found
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Second Page">
            <div class="back-button">
              <button mat-raised-button (click)="backToOverview()">
                <svg
                  width="9"
                  height="14"
                  viewBox="0 0 9 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="primary"
                    d="M3.06367 6.89433L8.42617 12.2568L6.89433 13.7887L0 6.89433L6.89433 0L8.42617 1.53183L3.06367 6.89433Z"
                    fill="#00B886"
                  />
                </svg>
                <span class="text">Back</span>
              </button>
              <span class="breadcrumb-text-main">{{ companyName }} /</span
              ><span class="breadcrumb-text-child">{{ pageTitle }}</span>
            </div>
            <div
              class="ownershipOverviewPage"
              *ngIf="selectedPage === 'stakeholders'"
            >
              <mat-grid-list cols="30" rowHeight="10px" gutterSize="20px">
                <mat-grid-tile rowspan="17" colspan="17">
                  <mat-card>
                    <div class="card-container">
                      <div class="cardHead">
                        <div>
                          <div class="tile"></div>
                          <div class="cardHeadLeft">
                            Cap table
                            <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                          </div>
                        </div>
                      </div>
                      <div class="cardBody">
                        <div class="cap-tab-container">
                          <div class="table-header">
                            <div class="column">
                              <span>Stakeholder</span>
                            </div>
                            <div class="column">
                              <span>
                                <select>
                                  <option value="series-a">Series A</option>
                                </select>
                              </span>
                            </div>
                            <div class="column">
                              <span> Total shares OS </span>
                            </div>
                            <div class="column">
                              <span>Total shares FD</span>
                            </div>
                            <div class="column">
                              <span> Ownership % OS </span>
                            </div>
                            <div class="column">
                              <span> Ownership % FD </span>
                            </div>
                          </div>
                          <div class="table-body">
                            <div class="row" *ngFor="let data of capTableData">
                              <div class="column">
                                <span>{{ data.stakeholder }}</span>
                              </div>
                              <div class="column">
                                <span>{{ data.investmentRound }}</span>
                              </div>
                              <div class="column">
                                <span>{{ data.totalSharesOS }}</span>
                              </div>
                              <div class="column">
                                <span>{{ data.totalSharesFD }}</span>
                              </div>
                              <div class="column">
                                <span>{{ data.ownershipOS }}</span>
                              </div>
                              <div class="column">
                                <span>{{ data.ownershipFD }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </mat-grid-tile>
                <mat-grid-tile rowspan="15" colspan="13">
                  <mat-card>
                    <div class="card-container">
                      <div class="cardHead">
                        <div>
                          <div class="tile"></div>
                          <div class="cardHeadLeft">
                            Cap table evolution
                            <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                          </div>
                        </div>
                      </div>
                      <div class="cardBody">
                        <div class="cap-table-evolution-container">
                          <div class="evolution-graph">
                            <img
                              src="../../../../../assets/cap-table-evolution.svg"
                              alt="Cap Table Evolution"
                            />
                          </div>
                          <div class="evolution-legends">
                            <div class="legends-1">
                              <div>
                                <span class="tile"></span>
                                <span class="title">ABC Ventures</span>
                              </div>
                              <div>
                                <span class="tile"></span>
                                <span class="title">Greth Ventures</span>
                              </div>
                              <div>
                                <span class="tile"></span>
                                <span class="title">Always Capital</span>
                              </div>
                            </div>
                            <div class="legends-2">
                              <div>
                                <span class="tile"></span>
                                <span class="title">VC Adventures</span>
                              </div>
                              <div>
                                <span class="tile"></span>
                                <span class="title">UniBank</span>
                              </div>
                              <div>
                                <span class="tile"></span>
                                <span class="title">Neu VC</span>
                              </div>
                              <div>
                                <span class="tile"></span>
                                <span class="title">FutureNext</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </mat-grid-tile>
              </mat-grid-list>
            </div>
            <div
              class="ownershipOverviewPage-handset"
              *ngIf="selectedPage === 'stakeholders'"
            >
              <div>
                <mat-card>
                  <div class="card-container">
                    <div class="cardHead">
                      <div>
                        <div class="tile"></div>
                        <div class="cardHeadLeft">
                          Cap table
                          <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                        </div>
                      </div>
                    </div>
                    <div class="cardBody">
                      <div class="cap-tab-container">
                        <div class="table-header">
                          <div class="column">
                            <span>Stakeholder</span>
                          </div>
                          <div class="column">
                            <span>
                              <select>
                                <option value="series-a">Series A</option>
                              </select>
                            </span>
                          </div>
                          <div class="column">
                            <span> Total shares OS </span>
                          </div>
                          <div class="column">
                            <span>Total shares FD</span>
                          </div>
                          <div class="column">
                            <span> Ownership % OS </span>
                          </div>
                          <div class="column">
                            <span> Ownership % FD </span>
                          </div>
                        </div>
                        <div class="table-body">
                          <div class="row" *ngFor="let data of capTableData">
                            <div class="column">
                              <span>{{ data.stakeholder }}</span>
                            </div>
                            <div class="column">
                              <span>{{ data.investmentRound }}</span>
                            </div>
                            <div class="column">
                              <span>{{ data.totalSharesOS }}</span>
                            </div>
                            <div class="column">
                              <span>{{ data.totalSharesFD }}</span>
                            </div>
                            <div class="column">
                              <span>{{ data.ownershipOS }}</span>
                            </div>
                            <div class="column">
                              <span>{{ data.ownershipFD }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
              <div>
                <mat-card>
                  <div class="card-container">
                    <div class="cardHead">
                      <div>
                        <div class="tile"></div>
                        <div class="cardHeadLeft">
                          Cap table evolution
                          <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                        </div>
                      </div>
                    </div>
                    <div class="cardBody">
                      <div class="cap-table-evolution-container">
                        <div class="evolution-graph">
                          <img
                            src="../../../../../assets/cap-table-evolution.svg"
                            alt="Cap Table Evolution"
                          />
                        </div>
                        <div class="evolution-legends">
                          <div class="legends-1">
                            <div>
                              <span class="tile"></span>
                              <span class="title">ABC Ventures</span>
                            </div>
                            <div>
                              <span class="tile"></span>
                              <span class="title">Greth Ventures</span>
                            </div>
                            <div>
                              <span class="tile"></span>
                              <span class="title">Always Capital</span>
                            </div>
                          </div>
                          <div class="legends-2">
                            <div>
                              <span class="tile"></span>
                              <span class="title">VC Adventures</span>
                            </div>
                            <div>
                              <span class="tile"></span>
                              <span class="title">UniBank</span>
                            </div>
                            <div>
                              <span class="tile"></span>
                              <span class="title">Neu VC</span>
                            </div>
                            <div>
                              <span class="tile"></span>
                              <span class="title">FutureNext</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>

            <div
              class="stakeholdersPage"
              *ngIf="selectedPage === 'ownership-overview'"
            >
              <mat-grid-list cols="30" rowHeight="10px" gutterSize="20px">
                <mat-grid-tile rowspan="14" colspan="14">
                  <mat-card>
                    <div class="card-container">
                      <div class="cardHead">
                        <div>
                          <div class="tile"></div>
                          <div class="cardHeadLeft">
                            Latest investment
                            <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                          </div>
                        </div>
                      </div>
                      <div class="cardBody">
                        <div class="latestInvestments">
                          <div class="segment">
                            <div class="heading"><span>Details</span></div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewDetails;
                                else latestInvestmentsLoader
                              "
                            >
                              <p class="value">
                                {{
                                  companyOverviewDetails.latestInvestment.date
                                    | date : 'dd. MMM yyyy'
                                }}
                              </p>
                              <p class="title">Date</p>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewDetails;
                                else latestInvestmentsLoader
                              "
                            >
                              <p class="value">
                                {{
                                  companyOverviewDetails.latestInvestment
                                    .securityType
                                }}
                              </p>
                              <p class="title">Security type</p>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewDetails;
                                else latestInvestmentsLoader
                              "
                            >
                              <p class="value"></p>
                              <p class="title"></p>
                            </div>
                          </div>
                          <div class="segment">
                            <div class="heading">
                              <span>Company Values</span>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewDetails;
                                else latestInvestmentsLoader
                              "
                            >
                              <p class="value">
                                {{
                                  convertDecimal(
                                    companyOverviewDetails.latestInvestment
                                      .preMoneyValuation
                                  )
                                }}
                              </p>
                              <p class="title">Pre-money valuation</p>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewDetails;
                                else latestInvestmentsLoader
                              "
                            >
                              <p class="value">
                                {{
                                  convertDecimal(
                                    companyOverviewDetails.latestInvestment
                                      .roundSize
                                  )
                                }}
                              </p>
                              <p class="title">Round size</p>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewDetails;
                                else latestInvestmentsLoader
                              "
                            >
                              <p class="value">
                                {{
                                  convertDecimal(
                                    companyOverviewDetails.latestInvestment
                                      .postMoneyValuation
                                  )
                                }}
                              </p>
                              <p class="title">Post money valuation</p>
                            </div>
                          </div>
                          <div class="segment">
                            <div class="heading heading-3">
                              <span>Acquired Value</span>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewDetails;
                                else latestInvestmentsLoader
                              "
                            >
                              <p class="value">
                                {{
                                  convertDecimal(
                                    companyOverviewDetails.latestInvestment
                                      .purchasedSecurities
                                  )
                                }}
                              </p>
                              <p class="title">Purchased securities</p>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewDetails;
                                else latestInvestmentsLoader
                              "
                            >
                              <p class="value">
                                {{
                                  convertDecimal(
                                    companyOverviewDetails.latestInvestment
                                      .pricePerShare
                                  )
                                }}
                              </p>
                              <p class="title">Price per share</p>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewDetails;
                                else latestInvestmentsLoader
                              "
                            >
                              <p class="value">
                                {{
                                  convertDecimal(
                                    companyOverviewDetails.latestInvestment
                                      .acquiredValue
                                  )
                                }}
                              </p>
                              <p class="title">Acquired Value</p>
                            </div>
                          </div>
                          <div class="segment">
                            <div class="heading heading-3">
                              <span>Value Creation</span>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewDetails;
                                else latestInvestmentsLoader
                              "
                            >
                              <p class="value">
                                {{
                                  convertDecimal(
                                    companyOverviewDetails.latestInvestment
                                      .changeInUnrealizedValue
                                  )
                                }}
                              </p>
                              <p class="title">Change in unrealized value</p>
                            </div>
                            <div
                              class="ownership-data"
                              *ngIf="
                                companyOverviewDetails;
                                else latestInvestmentsLoader
                              "
                            >
                              <p class="value">
                                {{
                                  convertDecimal(
                                    companyOverviewDetails.latestInvestment
                                      .realizedProceeds
                                  )
                                }}
                              </p>
                              <p class="title">Realized proceeds</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </mat-grid-tile>
                <mat-grid-tile rowspan="8" colspan="16">
                  <mat-card>
                    <div class="card-container">
                      <div class="cardHead">
                        <div>
                          <div class="tile"></div>
                          <div class="cardHeadLeft">
                            Fund return
                            <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                          </div>
                        </div>
                      </div>
                      <div class="cardBody">
                        <div class="fund-return-container">
                          <div class="fund-return">
                            <div
                              class="totalCommitment box"
                              *ngIf="
                                companyOverviewDetails;
                                else fundReturnsLoader
                              "
                            >
                              <p class="value">
                                {{ getCountryCurrrency() }}
                                {{
                                  convertToUnit(
                                    companyOverviewDetails.fundReturn
                                      .totalCommitment
                                  ).value
                                }}
                                <span class="suffixCount">
                                  {{
                                    convertToUnit(
                                      companyOverviewDetails.fundReturn
                                        .totalCommitment
                                    ).unit
                                  }}</span
                                >
                              </p>
                              <p class="title">Total commitment</p>
                            </div>
                            <div
                              class="proceeds box"
                              *ngIf="
                                companyOverviewDetails;
                                else fundReturnsLoader
                              "
                            >
                              <p class="value">
                                {{ getCountryCurrrency() }}
                                {{
                                  convertToUnit(
                                    companyOverviewDetails.fundReturn.proceeds
                                  ).value
                                }}
                                <span class="suffixCount">
                                  {{
                                    convertToUnit(
                                      companyOverviewDetails.fundReturn.proceeds
                                    ).unit
                                  }}</span
                                >
                              </p>
                              <p class="title">Proceeds</p>
                            </div>
                            <div
                              class="totalCapitalGain box"
                              *ngIf="
                                companyOverviewDetails;
                                else fundReturnsLoader
                              "
                            >
                              <p class="value">
                                {{ getCountryCurrrency() }}
                                {{
                                  convertToUnit(
                                    companyOverviewDetails.fundReturn
                                      .totalCapitalGain
                                  ).value
                                }}
                                <span class="suffixCount">
                                  {{
                                    convertToUnit(
                                      companyOverviewDetails.fundReturn
                                        .totalCapitalGain
                                    ).unit
                                  }}</span
                                >
                              </p>
                              <p class="title">Total capital gain</p>
                            </div>
                          </div>
                          <div
                            class="fundOwnership"
                            *ngIf="
                              companyOverviewDetails;
                              else fundOwnershipLoader
                            "
                          >
                            <span
                              >Fund ownership
                              {{
                                convertDecimal(
                                  companyOverviewDetails.fundReturn
                                    .fundOwnership
                                )
                              }}%</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </mat-grid-tile>
                <mat-grid-tile rowspan="10" colspan="16">
                  <mat-card>
                    <div class="card-container">
                      <div class="cardHead">
                        <div>
                          <div class="tile"></div>
                          <div class="cardHeadLeft">
                            Financing rounds
                            <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                          </div>
                        </div>
                      </div>
                      <div class="cardBody">
                        <div class="financing-rounds-container">
                          <div class="financing-rounds-table">
                            <div class="table-header">
                              <div class="column">
                                <span class="table-title item">Item</span>
                              </div>
                              <div class="column">
                                <span class="table-title">Date</span>
                              </div>
                              <div class="column">
                                <span class="table-title">Fund</span>
                              </div>
                              <div class="column">
                                <span class="table-title">Total</span>
                              </div>
                            </div>
                            <ng-container
                              *ngIf="
                                companyOverviewDetails;
                                else financialRoundsLoader
                              "
                            >
                              <div
                                class="table-body"
                                [ngClass]="'row-' + i"
                                *ngFor="
                                  let data of companyOverviewDetails.financingRounds;
                                  let i = index
                                "
                              >
                                <div class="column column-0">
                                  <span class="table-data">{{
                                    data.type
                                  }}</span>
                                </div>
                                <div class="column column-1">
                                  <span class="table-data">{{
                                    data.date | date : 'dd. MMM yyyy'
                                  }}</span>
                                </div>
                                <div class="column column-2">
                                  <span class="table-data value-data">
                                    {{ getCountryCurrrency() }}
                                    {{
                                      data.fundAmount !== undefined &&
                                      data.fundAmount != null
                                        ? convertToUnit(data.totalAmount).value
                                        : '-'
                                    }}
                                    {{
                                      data.fundAmount !== undefined &&
                                      data.fundAmount != null
                                        ? convertToUnit(data.totalAmount).unit
                                        : ''
                                    }}</span
                                  >
                                </div>
                                <div class="column column-2">
                                  <span class="table-data value-data">
                                    {{ getCountryCurrrency() }}
                                    {{ convertToUnit(data.totalAmount).value }}
                                    {{
                                      convertToUnit(data.totalAmount).unit
                                    }}</span
                                  >
                                </div>
                              </div>
                            </ng-container>

                            <ng-template #financialRoundsLoader>
                              <div
                                class="table-body"
                                [ngClass]="'row-' + i"
                                *ngFor="
                                  let data of [{}, {}, {}, {}, {}];
                                  let i = index
                                "
                              >
                                <div class="column column-0">
                                  <span class="table-data">
                                    <ngx-skeleton-loader
                                      count="1"
                                      [theme]="{
                                        width: '130px',
                                        height: '20px',
                                        margin: '5px 0'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </span>
                                </div>
                                <div class="column column-1">
                                  <span class="table-data">
                                    <ngx-skeleton-loader
                                      count="1"
                                      [theme]="{
                                        width: '100px',
                                        height: '20px',
                                        margin: '5px 0'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </span>
                                </div>
                                <div class="column column-2">
                                  <span class="table-data value-data">
                                    <ngx-skeleton-loader
                                      count="1"
                                      [theme]="{
                                        width: '70px',
                                        height: '20px',
                                        margin: '5px 0'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </span>
                                </div>
                                <div class="column column-2">
                                  <span class="table-data value-data">
                                    <ngx-skeleton-loader
                                      count="1"
                                      [theme]="{
                                        width: '70px',
                                        height: '20px',
                                        margin: '5px 0'
                                      }"
                                    ></ngx-skeleton-loader>
                                  </span>
                                </div>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </mat-grid-tile>
              </mat-grid-list>
            </div>
            <div
              class="stakeholdersPage-handset"
              *ngIf="selectedPage === 'ownership-overview'"
            >
              <div>
                <mat-card>
                  <div class="card-container">
                    <div class="cardHead">
                      <div>
                        <div class="tile"></div>
                        <div class="cardHeadLeft">
                          Latest investment
                          <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                        </div>
                      </div>
                    </div>
                    <div class="cardBody">
                      <div class="latestInvestments">
                        <div class="segment">
                          <div class="heading"><span>Details</span></div>
                          <div
                            class="ownership-data"
                            *ngIf="
                              companyOverviewDetails;
                              else latestInvestmentsLoader
                            "
                          >
                            <p class="value">
                              {{
                                companyOverviewDetails.latestInvestment.date
                                  | date : 'dd. MMM yyyy'
                              }}
                            </p>
                            <p class="title">Date</p>
                          </div>
                          <div
                            class="ownership-data"
                            *ngIf="
                              companyOverviewDetails;
                              else latestInvestmentsLoader
                            "
                          >
                            <p class="value">
                              {{
                                companyOverviewDetails.latestInvestment
                                  .securityType
                              }}
                            </p>
                            <p class="title">Security type</p>
                          </div>
                          <div
                            class="ownership-data"
                            *ngIf="
                              companyOverviewDetails;
                              else latestInvestmentsLoader
                            "
                          >
                            <p class="value"></p>
                            <p class="title"></p>
                          </div>
                        </div>
                        <div class="segment">
                          <div class="heading"><span>Company Values</span></div>
                          <div
                            class="ownership-data"
                            *ngIf="
                              companyOverviewDetails;
                              else latestInvestmentsLoader
                            "
                          >
                            <p class="value">
                              {{
                                convertDecimal(
                                  companyOverviewDetails.latestInvestment
                                    .preMoneyValuation
                                )
                              }}
                            </p>
                            <p class="title">Pre-money valuation</p>
                          </div>
                          <div
                            class="ownership-data"
                            *ngIf="
                              companyOverviewDetails;
                              else latestInvestmentsLoader
                            "
                          >
                            <p class="value">
                              {{
                                convertDecimal(
                                  companyOverviewDetails.latestInvestment
                                    .roundSize
                                )
                              }}
                            </p>
                            <p class="title">Round size</p>
                          </div>
                          <div
                            class="ownership-data"
                            *ngIf="
                              companyOverviewDetails;
                              else latestInvestmentsLoader
                            "
                          >
                            <p class="value">
                              {{
                                convertDecimal(
                                  companyOverviewDetails.latestInvestment
                                    .postMoneyValuation
                                )
                              }}
                            </p>
                            <p class="title">Post money valuation</p>
                          </div>
                        </div>
                        <div class="segment">
                          <div class="heading heading-3">
                            <span>Acquired Value</span>
                          </div>
                          <div
                            class="ownership-data"
                            *ngIf="
                              companyOverviewDetails;
                              else latestInvestmentsLoader
                            "
                          >
                            <p class="value">
                              {{
                                convertDecimal(
                                  companyOverviewDetails.latestInvestment
                                    .purchasedSecurities
                                )
                              }}
                            </p>
                            <p class="title">Purchased securities</p>
                          </div>
                          <div
                            class="ownership-data"
                            *ngIf="
                              companyOverviewDetails;
                              else latestInvestmentsLoader
                            "
                          >
                            <p class="value">
                              {{
                                convertDecimal(
                                  companyOverviewDetails.latestInvestment
                                    .pricePerShare
                                )
                              }}
                            </p>
                            <p class="title">Price per share</p>
                          </div>
                          <div
                            class="ownership-data"
                            *ngIf="
                              companyOverviewDetails;
                              else latestInvestmentsLoader
                            "
                          >
                            <p class="value">
                              {{
                                convertDecimal(
                                  companyOverviewDetails.latestInvestment
                                    .acquiredValue
                                )
                              }}
                            </p>
                            <p class="title">Acquired Value</p>
                          </div>
                        </div>
                        <div class="segment">
                          <div class="heading heading-3">
                            <span>Value Creation</span>
                          </div>
                          <div
                            class="ownership-data"
                            *ngIf="
                              companyOverviewDetails;
                              else latestInvestmentsLoader
                            "
                          >
                            <p class="value">
                              {{
                                convertDecimal(
                                  companyOverviewDetails.latestInvestment
                                    .changeInUnrealizedValue
                                )
                              }}
                            </p>
                            <p class="title">Change in unrealized value</p>
                          </div>
                          <div
                            class="ownership-data"
                            *ngIf="
                              companyOverviewDetails;
                              else latestInvestmentsLoader
                            "
                          >
                            <p class="value">
                              {{
                                convertDecimal(
                                  companyOverviewDetails.latestInvestment
                                    .realizedProceeds
                                )
                              }}
                            </p>
                            <p class="title">Realized proceeds</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
              <div>
                <mat-card>
                  <div class="card-container">
                    <div class="cardHead">
                      <div>
                        <div class="tile"></div>
                        <div class="cardHeadLeft">
                          Fund return
                          <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                        </div>
                      </div>
                    </div>
                    <div class="cardBody">
                      <div class="fund-return-container">
                        <div class="fund-return">
                          <div
                            class="totalCommitment box"
                            *ngIf="
                              companyOverviewDetails;
                              else fundReturnsLoader
                            "
                          >
                            <p class="value">
                              {{ getCountryCurrrency() }}
                              {{
                                companyOverviewDetails.fundReturn
                                  .totalCommitment !== undefined &&
                                companyOverviewDetails.fundReturn
                                  .totalCommitment != null
                                  ? convertToUnit(
                                      companyOverviewDetails.fundReturn
                                        .totalCommitment
                                    ).value
                                  : '-'
                              }}
                              <span class="suffixCount">
                                {{
                                  companyOverviewDetails.fundReturn
                                    .totalCommitment !== undefined &&
                                  companyOverviewDetails.fundReturn
                                    .totalCommitment != null
                                    ? convertToUnit(
                                        companyOverviewDetails.fundReturn
                                          .totalCommitment
                                      ).unit
                                    : ''
                                }}</span
                              >
                            </p>
                            <p class="title">Total commitment</p>
                          </div>
                          <div
                            class="proceeds box"
                            *ngIf="
                              companyOverviewDetails;
                              else fundReturnsLoader
                            "
                          >
                            <p class="value">
                              {{ getCountryCurrrency() }}
                              {{
                                companyOverviewDetails.fundReturn
                                  .totalCommitment !== undefined &&
                                companyOverviewDetails.fundReturn
                                  .totalCommitment != null
                                  ? convertToUnit(
                                      companyOverviewDetails.fundReturn.proceeds
                                    ).value
                                  : '-'
                              }}
                              <span class="suffixCount">
                                {{
                                  companyOverviewDetails.fundReturn
                                    .totalCommitment !== undefined &&
                                  companyOverviewDetails.fundReturn
                                    .totalCommitment != null
                                    ? convertToUnit(
                                        companyOverviewDetails.fundReturn
                                          .proceeds
                                      ).unit
                                    : ''
                                }}</span
                              >
                            </p>
                            <p class="title">Proceeds</p>
                          </div>
                          <div
                            class="totalCapitalGain box"
                            *ngIf="
                              companyOverviewDetails;
                              else fundReturnsLoader
                            "
                          >
                            <p class="value">
                              {{ getCountryCurrrency() }}
                              {{
                                convertToUnit(
                                  companyOverviewDetails.fundReturn
                                    .totalCapitalGain
                                ).value
                              }}
                              <span class="suffixCount">
                                {{
                                  convertToUnit(
                                    companyOverviewDetails.fundReturn
                                      .totalCapitalGain
                                  ).unit
                                }}</span
                              >
                            </p>
                            <p class="title">Total capital gain</p>
                          </div>
                        </div>
                        <div
                          class="fundOwnership"
                          *ngIf="
                            companyOverviewDetails;
                            else fundOwnershipLoader
                          "
                        >
                          <span
                            >Fund ownership
                            {{
                              convertDecimal(
                                companyOverviewDetails.fundReturn.fundOwnership
                              )
                            }}%</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
              <div>
                <mat-card>
                  <div class="card-container">
                    <div class="cardHead">
                      <div>
                        <div class="tile"></div>
                        <div class="cardHeadLeft">
                          Financing rounds
                          <!-- {{ 'translations.dashboard.timeline.title' | translate }} -->
                        </div>
                      </div>
                    </div>
                    <div class="cardBody">
                      <div class="financing-rounds-container">
                        <div class="financing-rounds-table">
                          <div class="table-header">
                            <div class="column">
                              <span class="table-title item">Item</span>
                            </div>
                            <div class="column">
                              <span class="table-title">Date</span>
                            </div>
                            <div class="column">
                              <span class="table-title">Fund</span>
                            </div>
                            <div class="column">
                              <span class="table-title">Total</span>
                            </div>
                          </div>
                          <ng-container
                            *ngIf="
                              companyOverviewDetails;
                              else financialRoundsLoader
                            "
                          >
                            <div
                              class="table-body"
                              [ngClass]="'row-' + i"
                              *ngFor="
                                let data of companyOverviewDetails.financingRounds;
                                let i = index
                              "
                            >
                              <div class="column column-0">
                                <span class="table-data">{{ data.type }}</span>
                              </div>
                              <div class="column column-1">
                                <span class="table-data">{{
                                  data.date | date : 'dd. MMM yyyy'
                                }}</span>
                              </div>
                              <div class="column column-2">
                                <span class="table-data value-data">
                                  {{ getCountryCurrrency() }}
                                  {{
                                    data.fundAmount !== undefined &&
                                    data.fundAmount != null
                                      ? convertToUnit(data.fundAmount).value
                                      : '-'
                                  }}
                                  {{
                                    data.fundAmount !== undefined &&
                                    data.fundAmount != null
                                      ? convertToUnit(data.fundAmount).unit
                                      : ''
                                  }}</span
                                >
                              </div>
                              <div class="column column-2">
                                <span class="table-data value-data">
                                  {{ getCountryCurrrency() }}
                                  {{
                                    data.fundAmount !== undefined &&
                                    data.fundAmount != null
                                      ? convertToUnit(data.totalAmount).value
                                      : '-'
                                  }}
                                  {{
                                    data.fundAmount !== undefined &&
                                    data.fundAmount != null
                                      ? convertToUnit(data.totalAmount).unit
                                      : ''
                                  }}</span
                                >
                              </div>
                            </div>
                          </ng-container>

                          <ng-template #financialRoundsLoader>
                            <div
                              class="table-body"
                              [ngClass]="'row-' + i"
                              *ngFor="
                                let data of [{}, {}, {}, {}, {}];
                                let i = index
                              "
                            >
                              <div class="column column-0">
                                <span class="table-data">
                                  <ngx-skeleton-loader
                                    count="1"
                                    [theme]="{
                                      width: '130px',
                                      height: '20px',
                                      margin: '5px 0'
                                    }"
                                  ></ngx-skeleton-loader>
                                </span>
                              </div>
                              <div class="column column-1">
                                <span class="table-data">
                                  <ngx-skeleton-loader
                                    count="1"
                                    [theme]="{
                                      width: '100px',
                                      height: '20px',
                                      margin: '5px 0'
                                    }"
                                  ></ngx-skeleton-loader>
                                </span>
                              </div>
                              <div class="column column-2">
                                <span class="table-data value-data">
                                  <ngx-skeleton-loader
                                    count="1"
                                    [theme]="{
                                      width: '70px',
                                      height: '20px',
                                      margin: '5px 0'
                                    }"
                                  ></ngx-skeleton-loader>
                                </span>
                              </div>
                              <div class="column column-2">
                                <span class="table-data value-data">
                                  <ngx-skeleton-loader
                                    count="1"
                                    [theme]="{
                                      width: '70px',
                                      height: '20px',
                                      margin: '5px 0'
                                    }"
                                  ></ngx-skeleton-loader>
                                </span>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #cellLoader>
  <ngx-skeleton-loader
    count="1"
    [theme]="{
      width: '100px',
      height: '25px'
    }"
  ></ngx-skeleton-loader>
</ng-template>

<ng-template #logoLoader>
  <ngx-skeleton-loader
    count="1"
    [theme]="{
      width: '100px',
      height: '50px',
      margin: '2px 0 0 0'
    }"
  ></ngx-skeleton-loader>
</ng-template>

<ng-template #investmentLoader>
  <div class="amount">
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        width: '130px',
        height: '20px',
        margin: '0px'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div class="date">
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        width: '90px',
        height: '15px',
        margin: '0px'
      }"
    ></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #statusLoader>
  <ngx-skeleton-loader
    count="1"
    [theme]="{
      width: '20px',
      height: '20px',
      margin: '0 5px 0 0'
    }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    count="1"
    [theme]="{
      width: '100px',
      height: '20px',
      margin: '0px'
    }"
  ></ngx-skeleton-loader>
</ng-template>

<ng-template #currentYearRevenue>
  <div class="currentYearContainer">
    <div class="currentYearTop">
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          width: '70px',
          height: '10px',
          margin: '5px 5px 0 0'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          width: '10px',
          height: '10px',
          margin: '5px 0 0 0'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          width: '130px',
          height: '20px',
          margin: '0px'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          width: '10px',
          height: '10px',
          margin: '0 5px 0 0'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          width: '70px',
          height: '10px',
          margin: '0'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>

<ng-template #companyValueLoader>
  <div>
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        width: '130px',
        height: '40px',
        margin: '5px 0 0 0'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div>
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        width: '130px',
        height: '15px',
        margin: '0px 0 0 0'
      }"
    ></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #companyNameLoader>
  <ngx-skeleton-loader
    count="1"
    [theme]="{
      width: '100px',
      height: '25px'
    }"
  ></ngx-skeleton-loader>
</ng-template>

<ng-template #ownershipOverviewLoader>
  <div class="ownership-data">
    <p class="value" style="margin: 0 !important">
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          width: '150px',
          height: '25px'
        }"
      ></ngx-skeleton-loader>
    </p>
    <p class="title" style="margin: 0 !important">
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          width: '100px',
          height: '20px'
        }"
      ></ngx-skeleton-loader>
    </p>
  </div>
</ng-template>

<ng-template #largeLogoLoader>
  <ngx-skeleton-loader
    count="1"
    [theme]="{
      width: '200px',
      height: '70px'
    }"
  ></ngx-skeleton-loader>
</ng-template>

<ng-template #descriptionLoader>
  <div>
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        width: '100%',
        height: '20px',
        margin: '5px 0'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div>
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        width: '100%',
        height: '20px',
        margin: '5px 0'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div>
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        width: '100%',
        height: '20px',
        margin: '5px 0'
      }"
    ></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #detailsLoader>
  <div class="details-loader">
    <div>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          width: '50px',
          height: '50px',
          'margin-right': '5px'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <div>
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            width: '180px',
            height: '20px',
            'margin-bottom': '5px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div>
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            width: '120px',
            height: '20px'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #latestInvestmentsLoader>
  <div class="latestInvestmentLoader">
    <div>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          width: '100px',
          height: '20px',
          margin: '0'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          width: '60px',
          height: '20px',
          float: 'right',
          margin: '0'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>

<ng-template #fundReturnsLoader>
  <div class="fundReturnsLoader box">
    <div>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          width: '150px',
          height: '25px',
          margin: '0 auto'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          width: '100px',
          height: '20px',
          margin: '0'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>

<ng-template #fundOwnershipLoader>
  <ngx-skeleton-loader
    count="1"
    [theme]="{
      width: '150px',
      height: '25px'
    }"
  ></ngx-skeleton-loader>
</ng-template>
