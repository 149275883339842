import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CapitalAccountComponent } from './core/components/capital-account/capital-account.component';
import { DashboardComponent } from './core/components/dashboard/dashboard.component';
import { DocumentsComponent } from './core/components//documents/documents.component';
import { InsightsComponent } from './core/components//insights/insights.component';
import { ReportsComponent } from './core/components//reports/reports.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: DashboardComponent,
  },
  {
    path: 'lp/:lpId/vehicle/:vehicleId/dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },{ path: 'lp/:lpId/vehicle/:vehicleId/insights', component: InsightsComponent, canActivate: [AuthGuard],
},
  { path: 'lp/:lpId/vehicle/:vehicleId/insights/:insights-tab', component: InsightsComponent, canActivate: [AuthGuard],
},
  {
    path: 'lp/:lpId/vehicle/:vehicleId/capital-account',
    component: CapitalAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'lp/:lpId/vehicle/:vehicleId/capital-account/:capitalAccountTab',
    component: CapitalAccountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lp/:lpId/vehicle/:vehicleId/capital-account/:capitalAccountTab/:year/:quarter/:format',
    component: CapitalAccountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lp/:lpId/vehicle/:vehicleId/capital-account/:capitalAccountTab/:year/:quarter',
    component: CapitalAccountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lp/:lpId/vehicle/:vehicleId/capital-account/:capitalAccountTab/:transactionId',
    component: CapitalAccountComponent,
    canActivate: [AuthGuard]
  },
  { path: 'lp/:lpId/vehicle/:vehicleId/reports', component: ReportsComponent, canActivate: [AuthGuard] },
  { path: 'lp/:lpId/vehicle/:vehicleId/reports/:year', component: ReportsComponent, canActivate: [AuthGuard] },
  { path: 'lp/:lpId/vehicle/:vehicleId/reports/:year/:quarter', component: ReportsComponent, canActivate: [AuthGuard] },
  {
    path: 'lp/:lpId/vehicle/:vehicleId/documents',
    component: DocumentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lp/:lpId/vehicle/:vehicleId/documents/:documentParams',
    component: DocumentsComponent,
    canActivate: [AuthGuard]
  },
  { path: 'lp/:lpId/vehicle/:vehicleId/insights/:insights-tab/:companyId', component: InsightsComponent, canActivate: [AuthGuard] },
  { path: 'lp/:lpId/vehicle/:vehicleId/insights/:insights-tab/:companyId/:portfolioTab', component: InsightsComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
