import { Component } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { UserService } from './services/user.service';
import { ApiHttpService } from './core/services/api-http.service';
import { ApiEndpointsService } from './core/services/api-endpoints.service';
import { ConfigService } from './initializer/config.service';
import { AuthService } from './core/services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { TermsOfUseComponent } from './shared/components/dialog/terms-of-use/terms-of-use.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title(title: any) {
    throw new Error('Method not implemented.');
  }

  baseData: any;
  sessionUser: any;
  isAuthenticated: boolean = false;
  selectedLP: any;
  selectedVehicle: any;
  error: any;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private userService: UserService,
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService,
    private config: ConfigService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.authService.startTokenRefreshTimer();
    this.authService.logoutAfterThreeHours();
    this.isAuthenticated = this.authService.isAuthenticated();
    if (this.config.sessionuser.error) {
      this.error = this.config.sessionuser.error;
    }

    router.events
      .pipe(
        filter((routerEvent: Event) => routerEvent instanceof NavigationEnd)
      )
      .subscribe(() =>
        document.getElementsByTagName('mat-sidenav-content')[0]?.scrollTo(0, 0)
      );
  }

  // getSessionUser() {
  //   this.apiHttpService
  //     .get(this.apiEndpointsService.getSessionUser())
  //     .subscribe(
  //       (response) => {
  //         let sessionUser = JSON.parse(JSON.stringify(response));
  //         let userId = sessionUser.data.id;
  //         let name = `${sessionUser.data.firstName} ${sessionUser.data.lastName}`;

  //         let userLanguage = ['en', 'de'].includes(
  //           sessionUser.data.state.lang.toLowerCase()
  //         )
  //           ? sessionUser.data.state.lang.toLowerCase()
  //           : 'en';
  //         this.userService.setUserConfig(
  //           `${sessionUser.data.firstName} ${sessionUser.data.lastName}`,
  //           userLanguage,
  //           sessionUser.data.id
  //         );
  //         localStorage.setItem(
  //           'user',
  //           JSON.stringify({
  //             name: name,
  //             languageCode: userLanguage,
  //             id: userId
  //           })
  //         );
  //         this.translate.use(userLanguage);
  //         this.error = null;
  //       },
  //       (error) => {
  //         if (error.status == 403) {
  //           this.error = 'ACCESS_DENIED';
  //         }
  //       }
  //     );
  // }

  handleLPS(lps: any) {
    this.selectedLP = lps;
    this.baseData = { ...this.baseData, lps };
    this.router.navigate(
      [
        `lp/${this.selectedVehicle.id}/vehicle/${this.selectedVehicle.id}/dashboard`
      ],
      { state: { ...this.baseData } }
    );
    // this.router.navigate([`lp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/dashboard`], { state: { lps: this.selectedInvestor, vehicle: this.currentVehicle, baseData: this.baseData } })
  }

  handleVehicle(vehicle: any) {
    this.selectedVehicle = vehicle;
    this.baseData = { ...this.baseData, vehicle };
    this.router.navigate(
      [
        `lp/${this.selectedVehicle.id}/vehicle/${this.selectedVehicle.id}/dashboard`
      ],
      { state: { ...this.baseData } }
    );
    // this.router.navigate(['/'], { state: { ...this.baseData } });
  }

  ngOnInit() {
    if (this.config.sessionuser.error) {
      this.error = this.config.sessionuser.error;
    } else {
      if (
        !this.config.sessionuser.error &&
        !this.config.sessionuser.data.confirmedTermsOfUse
      ) {
        this.dialog.open(TermsOfUseComponent, {
          disableClose: true,
          data: {}
        });
      }
    }

    if (
      this.config.sessionuser.data &&
      this.config.baseData &&
      this.config.baseData.data
    ) {
      this.sessionUser = this.config.sessionuser.data;
      this.baseData = this.config.baseData.data;
      let userLanguage = ['en', 'de'].includes(
        this.sessionUser.state.lang.toLowerCase()
      )
        ? this.sessionUser.state.lang.toLowerCase()
        : 'en';
      this.userService.setUserConfig(
        `${this.sessionUser.firstName} 
        ${this.sessionUser.lastName}`,
        userLanguage,
        this.sessionUser.id
      );
      this.setAppLanguage();
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }
  }
  setAppLanguage() {
    this.translate.addLangs(['en', 'de']);
    const { languageCode } = JSON.parse(this.userService.getUserConfig());
    this.translate.use(languageCode);
  }
}
