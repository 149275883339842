import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { DocumentsService } from 'src/app/services/documents.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Column } from 'src/app/shared/components/table/column';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-transaction',
  templateUrl: './view-transaction.component.html',
  styleUrls: ['./view-transaction.component.scss']
})
export class ViewTransactionComponent {
  selectedTransaction: any = [];
  frontendBaseUrl: any;
  filePath: any;
  isLoading: boolean = false;
  transactionColumns: Array<Column> = [
    {
      columnDef: 'reportTitle',
      header: 'Title',
      cell: (element: Record<string, any>) => `${element['title']}`
    },
    {
      columnDef: 'type',
      header: 'Type',
      cell: (element: Record<string, any>) => `${element['type']}`
    },
    {
      columnDef: 'sendDate',
      header: 'Send date',
      cell: (element: Record<string, any>) => `${element['sendDate']}`
    },
    {
      columnDef: 'dueDate',
      header: 'Due date',
      cell: (element: Record<string, any>) => `${element['dueDate']}`
    },
    {
      columnDef: 'amount',
      header: 'Amount ',
      cell: (element: Record<string, any>) => `${element['amount']}`
    },
    {
      columnDef: 'status',
      header: 'Status',
      isStatus: true,
      cell: (element: Record<string, any>) => `${element['status']}`
    }
  ];

  constructor(
    private apiHttpService: ApiHttpService,
    private documentsService: DocumentsService,
    private notificationService: NotificationService,
    private router: Router){
    this.frontendBaseUrl = environment.frontendBaseUrl;
  }

  ngOnInit(): void{
    const {transaction, lps, vehicle} = history.state;
    this.selectedTransaction.push(history.state.transaction);
    this.isLoading = true;
    this.getTransactionDocument(lps.id, vehicle.id, transaction.documentId);
    let messageToSend = 'capital-account.transactions';
    this.notificationService.sendNotification({ prefix: messageToSend, suffix: '' });
  };

  tabClick(){
    this.router.navigate(['/capital-account'], { state: { lps: history.state.lps, vehicle: history.state.vehicle, tab: 0 } });
  }

  backNavigation(){
    this.router.navigate(['/capital-account'], { state: { lps: history.state.lps, vehicle: history.state.vehicle, tab: 1 } });
  }

  getTransactionDocument(lpsId: any, vehicleId: any, documentId: any) {
    this.apiHttpService
      .get(
        this.documentsService.getDocumentById(lpsId, vehicleId, documentId),
        { responseType: 'arraybuffer' }
      )
      .subscribe(
        (response) => {
          const blob = new Blob([response], {
            type: 'application/pdf; charset=utf-8'
          });
          this.filePath = blob;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.filePath = '';
        }
      );
  }
}
