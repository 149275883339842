import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavComponent } from './core/components/nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { DashboardComponent } from './core/components/dashboard/dashboard.component';
import { DashboardEventsComponent } from './core/components/dashboard/dashboard-events/dashboard-events.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { InsightsComponent } from './core/components/insights/insights.component';
import { CapitalAccountComponent } from './core/components/capital-account/capital-account.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { ReportsComponent } from './core/components/reports/reports.component';
import { DocumentsComponent } from './core/components/documents/documents.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MatDateFormats,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions
} from '@angular/material/core';
import { I18nModule } from './modules/i18n/i18n.module';
import { ProfileComponent } from './core/components/profile/profile.component';
import { CardComponent } from './shared/components/card/card.component';
import { FolderTemplateComponent } from './shared/components/folder-template/folder-template.component';
import { FileTemplateComponent } from './shared/components/file-template/file-template.component';
import { SettingsComponent } from './core/components/settings/settings.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PdfViewerComponent } from './shared/components/pdf-viewer/pdf-viewer.component';
import { BreadcrumbsComponent } from './core/components/breadcrumbs/breadcrumbs.component';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { NotificationService } from './services/notification.service';
import { TableComponent } from './shared/components/table/table.component';
import { DatepickerComponent } from './shared/components/datepicker/datepicker.component';
import { SelectComponent } from './shared/components/select/select.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { ChartComponent } from './shared/components/chart/chart.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ApiEndpointsService } from './core/services/api-endpoints.service';
import { ApiHttpService } from './core/services/api-http.service';
import { Constants } from './config/constants';
import { ConfigService } from './initializer/config.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { OAuthService, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0
  }
};

export const MY_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'D. MMM YYYY'
  },
  display: {
    dateInput: 'D. MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'D. MMM YYYY',
    monthYearA11yLabel: 'MMM YYYY'
  }
};

import myLocaleDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { ViewTransactionComponent } from './core/components/capital-account/view-transaction/view-transaction.component';
import { environment } from 'src/environments/environment';
import { MetricDialogComponent } from './shared/components/dialog/metric-dialog/metric-dialog.component';
import { PagenotfoundComponent } from './shared/components/pagenotfound/pagenotfound.component';
import { TooltipModule } from './shared/components/tooltip/tooltip.module';
import { PortfolioCompaniesComponent } from './core/components/insights/portfolio-companies/portfolio-companies.component';
import { CompanyOverviewComponent } from './core/components/insights/company-overview/company-overview.component';
import { TermsOfUseComponent } from './shared/components/dialog/terms-of-use/terms-of-use.component';
import { FundMetricsComponent } from './core/components/insights/fund-metrics/fund-metrics.component';
import { PortfolioInvestmentComponent } from './core/components/insights/portfolio-investment/portfolio-investment.component';

registerLocaleData(myLocaleDe);

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    DashboardComponent,
    DashboardEventsComponent,
    InsightsComponent,
    CapitalAccountComponent,
    ReportsComponent,
    DocumentsComponent,
    ProfileComponent,
    CardComponent,
    FolderTemplateComponent,
    FileTemplateComponent,
    SettingsComponent,
    PdfViewerComponent,
    BreadcrumbsComponent,
    TableComponent,
    DatepickerComponent,
    SelectComponent,
    PdfViewerComponent,
    DialogComponent,
    ChartComponent,
    ViewTransactionComponent,
    MetricDialogComponent,
    PagenotfoundComponent,
    PortfolioCompaniesComponent,
    CompanyOverviewComponent,
    TermsOfUseComponent,
    FundMetricsComponent,
    PortfolioInvestmentComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.datahubApiUrl],
        sendAccessToken: true
      }
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    NgxSkeletonLoaderModule,
    MatCardModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    BreadcrumbModule,
    MatMomentDateModule,
    MatDialogModule,
    MatTooltipModule,
    MatProgressBarModule,
    CdkAccordionModule,
    MatExpansionModule,
    TooltipModule,
    CommonModule
  ],
  providers: [
    ConfigService,
    Title,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ConfigService) => () => config.init(),
      deps: [ConfigService],
      multi: true
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: OAuthStorage, useFactory: storageFactory },
    NotificationService,
    ApiEndpointsService,
    ApiHttpService,
    Constants,
    DatePipe,
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
