<mat-form-field appearance="fill" class="datepicker-container">
    <input #fromInput matInput [min]="startDate" [max]="maxDate" [matDatepicker]="picker" 
    (dateChange)="onDate($event)" >
    <mat-datepicker-toggle matIconSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon>
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="primary" d="M12.5 1.66667H15.8333C16.0543 1.66667 16.2663 1.75446 16.4226 1.91074C16.5789 2.06702 16.6667 2.27899 16.6667 2.5V15.8333C16.6667 16.0543 16.5789 16.2663 16.4226 16.4226C16.2663 16.5789 16.0543 16.6667 15.8333 16.6667H0.833333C0.61232 16.6667 0.400358 16.5789 0.244078 16.4226C0.0877973 16.2663 0 16.0543 0 15.8333V2.5C0 2.27899 0.0877973 2.06702 0.244078 1.91074C0.400358 1.75446 0.61232 1.66667 0.833333 1.66667H4.16667V0H5.83333V1.66667H10.8333V0H12.5V1.66667ZM15 6.66667V3.33333H12.5V5H10.8333V3.33333H5.83333V5H4.16667V3.33333H1.66667V6.66667H15ZM15 8.33333H1.66667V15H15V8.33333ZM3.33333 10H7.5V13.3333H3.33333V10Z"/>
        </svg>          
    </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>