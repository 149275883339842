
<div *ngIf="data.isValid=='1' " else="modelDialog">
  <div class="dialog-container" >
    <div class="confirmation-wrapper">
      <svg width="125" height="129" viewBox="0 0 125 129" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="63" cy="62" r="62" fill="#9DC655"/>
        <mask id="mask0_3938_31078" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="37" width="75" height="92">
        <path d="M0 79L45 37V39L46 65.5L74.5 88.5L32.5 129L1.5 118.5L0 79Z" fill="#74BB9F"/>
        </mask>
        <g mask="url(#mask0_3938_31078)">
        <circle cx="63.5" cy="62" r="62" fill="#82B32E"/>
        </g>
        <rect x="34" y="33" width="58" height="58" rx="4" fill="white"/>
        <rect x="38" y="44" width="50" height="43" fill="#9DC655"/>
        <rect x="39.9707" y="36.4121" width="28.4314" height="2.84314" rx="1.42157" fill="#9DC655"/>
        <circle cx="76.0778" cy="37.8337" r="1.42157" fill="#9DC655"/>
        <circle cx="80.3415" cy="37.8337" r="1.42157" fill="#9DC655"/>
        <circle cx="84.6071" cy="37.8337" r="1.42157" fill="#9DC655"/>
        <circle cx="63" cy="65" r="14" fill="white"/>
        <path d="M63 64.0558L67.6669 59L69 60.4442L64.3331 65.5L69 70.5558L67.6669 72L63 66.9442L58.3331 72L57 70.5558L61.6669 65.5L57 60.4442L58.3331 59L63 64.0558Z" fill="#9DC655"/>
      </svg>
        <div class="bottom-separator">
          <div class="hr1"></div>
          <div class="hr2"></div>
        </div>
    </div>
    
    <div class="logout-wrapper">
      <div class="content">
        <mat-dialog-content>{{ 'translations.invalidPageConfirmation' | translate}}</mat-dialog-content>
      </div>
      <div>
        <mat-dialog-actions class="actions">
          <button class="access-denied" mat-button mat-dialog-close="1" >OK</button>
        </mat-dialog-actions>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="data.isValid=='0' "  #modelDialog >
<div class="dialog-container" >
  <div class="confirmation-wrapper">
    <div class="circle third" [ngStyle]="{'background-color': hexToRGB(data.currentGP.colors.primary, 3)}">
      <div class="circle second" [ngStyle]="{'background-color': hexToRGB(data.currentGP.colors.primary, 2)}">
        <div class="circle first" [ngStyle]="{'background-color': hexToRGB(data.currentGP.colors.primary, 1)}">
          <div class="exclamatory">!</div>
        </div>
      </div>
    </div>
    <div class="confirmation">Confirmation</div>
  </div>
  
  <div class="logout-wrapper">
    <div class="content">
      <mat-dialog-content>{{'translations.' + data.contentKey | translate}}</mat-dialog-content>
    </div>
    <div>
      <mat-dialog-actions class="actions">
        <button class="button-cancel" mat-button mat-dialog-close="0">{{'translations.' + data.negativeMessageKey | translate}}</button>
        <button class="button-warn" mat-button mat-dialog-close="1">{{'translations.' + data.positiveMessageKey | translate}}</button>
      </mat-dialog-actions>
    </div>
  </div>
</div>
</ng-container>