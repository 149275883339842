<div class="insights">
  <div class="bottom-bar"></div>
  <mat-tab-group
    dynamicHeight
    mat-align-tabs="start"
    (selectedTabChange)="tabChanged($event)"
    [selectedIndex]="tabs.indexOf(insightsTab)"
  >
    <mat-tab label="Fund Metrics">
      <div class="tab fund-metrics">
        <div class="category net-performance">
          <div *ngIf="!isLoading; else chartLoader">
            <ng-container *ngIf="fundMetrics.length; else noRecord">
              <mat-accordion multi class="" is-open="false">
                <mat-expansion-panel
                  *ngFor="let fund of fundMetrics"
                  [expanded]="true"
                >
                  <mat-expansion-panel-header>
                    <div class="insights-title">
                      {{ fundMetricsSegments[fund.labelKey] }}
                    </div>
                  </mat-expansion-panel-header>

                  <mat-grid-list
                    [cols]="
                      (isSmallHandset$ | async)
                        ? 1
                        : (isHandset$ | async)
                        ? 1
                        : (isSmallScreen$ | async)
                        ? 1
                        : 2
                    "
                    [rowHeight]="'360px'"
                    gutterSize="20px"
                  >
                    <mat-grid-tile
                      *ngFor="let box of fund.boxes"
                      class="insights-card"
                    >
                      <mat-card class="insights-card">
                        <div class="card-container">
                          <div class="cardHead">
                            <div class="tile"></div>
                            <div class="cardHeadLeft">
                              {{ box.labelKey }}
                            </div>
                          </div>
                          <div *ngFor="let view of box.views" class="cardBody">
                            <div
                              *ngIf="view.type === 'chart'"
                              class="insight-head fundMetrics"
                            >
                              <div *ngFor="let kpi of view.kpis" class="item">
                                <p
                                  *ngIf="kpi.type !== 'currency'; else currency"
                                  class="value"
                                >
                                  {{ convertCustomValue(kpi) }}{{ kpi.unit }}
                                </p>
                                <ng-template #currency>
                                  <p class="value">
                                    {{ convertCurrency(kpi) }}
                                  </p>
                                </ng-template>
                                <p class="title">{{ kpi.labelKey }}</p>
                              </div>
                            </div>
                            <div class="insight-chart">
                              <app-chart
                                *ngIf="view.type === 'chart'; else table"
                                [chartData]="view.data"
                                [legendColors]="legendColors"
                              ></app-chart>
                              <ng-template #table>
                                <div class="insight-table">
                                  <div
                                    *ngFor="let row of view.data.rows"
                                    class="row"
                                  >
                                    <div class="title">{{ row.labelKey }}</div>
                                    <div
                                      class="value"
                                      [ngClass]="
                                        selectedVehicle.negativeNumbers !==
                                        'parentheses'
                                          ? ''
                                          : row.value < 0
                                          ? 'negativeValue'
                                          : 'nonNegativeValue'
                                      "
                                    >
                                      {{ convertCurrency(row) }}
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </div>
                            <div *ngIf="view.data.data" class="insight-legends">
                              <div
                                *ngFor="
                                  let dataset of view.data.data.datasets;
                                  let i = index
                                "
                              >
                                <span
                                  class="legend-tile"
                                  [ngStyle]="{
                                    'background-color':
                                      dataset.label !== 'Exits'
                                        ? legendColors[i]
                                        : '#9ba6b0'
                                  }"
                                ></span
                                ><span class="legend-title">{{
                                  dataset.label
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-card>

                      <!--------------->
                    </mat-grid-tile>
                  </mat-grid-list>
                </mat-expansion-panel>
              </mat-accordion>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Portfolio Investments">
      <div class="tab portfolio-investments">
        <div class="category capital-deployment">
          <div *ngIf="!isLoading; else chartLoader">
            <ng-container *ngIf="portfolioInvestments.length; else noRecord">
              <mat-accordion multi class="" is-open="false">
                <mat-expansion-panel
                  *ngFor="let investment of portfolioInvestments"
                  [expanded]="true"
                >
                  <mat-expansion-panel-header>
                    <div class="insights-title">
                      {{ portFolioInvestmentSegments[investment.labelKey] }}
                    </div>
                  </mat-expansion-panel-header>

                  <mat-grid-list
                    [cols]="
                      (isSmallHandset$ | async)
                        ? 1
                        : (isHandset$ | async)
                        ? 1
                        : (isSmallScreen$ | async)
                        ? 1
                        : 2
                    "
                    [rowHeight]="'360px'"
                    gutterSize="20px"
                  >
                    <mat-grid-tile
                      *ngFor="let box of investment.boxes"
                      class="insights-card"
                    >
                      <!---------->

                      <mat-card class="insights-card">
                        <div class="card-container">
                          <div class="cardHead">
                            <div class="tile"></div>
                            <div class="cardHeadLeft">
                              {{ box.labelKey }}
                            </div>
                            <div class="cardHeadRight">
                              <div
                                *ngIf="box.views.length > 1"
                                class="chart-dropdown"
                              >
                                <mat-select
                                  [value]="box.views[0]"
                                  (valueChange)="viewChange($event, box)"
                                >
                                  <mat-option
                                    *ngFor="let view of box.views"
                                    [value]="view"
                                  >
                                    {{ view.labelKey }}
                                  </mat-option>
                                </mat-select>
                              </div>
                            </div>
                          </div>
                          <ng-container *ngIf="box.views.length === 1">
                            <div class="cardBody">
                              <div class="insight-head">
                                <div
                                  *ngFor="let kpi of box.views[0].kpis"
                                  class="item"
                                >
                                  <p class="value">
                                    {{ convertCurrency(kpi) }}
                                  </p>
                                  <p class="title">{{ kpi.labelKey }}</p>
                                </div>
                              </div>
                              <div class="insight-chart">
                                <app-chart
                                  *ngIf="box.views[0].type === 'chart'"
                                  [chartData]="box.views[0].data"
                                  [legendColors]="legendColors"
                                ></app-chart>
                              </div>
                              <div
                                *ngIf="box.views[0].data"
                                class="insight-legends"
                              >
                                <div
                                  *ngFor="
                                    let dataset of box.views[0].data.data
                                      .datasets;
                                    let i = index
                                  "
                                >
                                  <span
                                    class="legend-tile"
                                    [ngStyle]="{
                                      'background-color':
                                        dataset.label !== 'Exits'
                                          ? legendColors[i]
                                          : '#9ba6b0'
                                    }"
                                  ></span
                                  ><span class="legend-title">{{
                                    dataset.label
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container
                            *ngIf="
                              box.labelKey ===
                              'Initial investments vs. follow-on investments'
                            "
                          >
                            <ng-container
                              *ngIf="isInitialVsFollowOnUpdated$ | async"
                            >
                              <div class="cardBody">
                                <div class="insight-head">
                                  <div
                                    *ngFor="
                                      let kpi of selectedInitialAndFollowOnInvestments.kpis
                                    "
                                    class="item"
                                  >
                                    <p class="value">
                                      {{ convertCurrency(kpi) }}
                                    </p>
                                    <p class="title">{{ kpi.labelKey }}</p>
                                  </div>
                                </div>
                                <div class="insight-chart">
                                  <app-chart
                                    *ngIf="
                                      selectedInitialAndFollowOnInvestments.type ===
                                      'chart'
                                    "
                                    [chartData]="
                                      selectedInitialAndFollowOnInvestments.data
                                    "
                                    [legendColors]="legendColors"
                                  ></app-chart>
                                </div>
                                <div
                                  *ngIf="
                                    selectedInitialAndFollowOnInvestments.data
                                  "
                                  class="insight-legends"
                                >
                                  <div
                                    *ngFor="
                                      let dataset of selectedInitialAndFollowOnInvestments
                                        .data.data.datasets;
                                      let i = index
                                    "
                                  >
                                    <span
                                      class="legend-tile"
                                      [ngStyle]="{
                                        'background-color':
                                          dataset.label !== 'Exits'
                                            ? legendColors[i]
                                            : '#9ba6b0'
                                      }"
                                    ></span
                                    ><span class="legend-title">{{
                                      dataset.label
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>

                          <ng-container
                            *ngIf="
                              box.labelKey ===
                              'Inital investment pacing (No. of deals)'
                            "
                          >
                            <ng-container
                              *ngIf="isInitialPacingUpdated$ | async"
                            >
                              <div class="cardBody">
                                <div class="insight-head">
                                  <div
                                    *ngFor="
                                      let kpi of selectedInitialInvestmentPacing.kpis
                                    "
                                    class="item"
                                  >
                                    <p class="value">
                                      {{ convertCurrency(kpi) }}
                                    </p>
                                    <p class="title">{{ kpi.labelKey }}</p>
                                  </div>
                                </div>
                                <div class="insight-chart">
                                  <app-chart
                                    *ngIf="
                                      selectedInitialInvestmentPacing.type ===
                                      'chart'
                                    "
                                    [chartData]="
                                      selectedInitialInvestmentPacing.data
                                    "
                                    [legendColors]="legendColors"
                                  ></app-chart>
                                </div>
                                <div
                                  *ngIf="selectedInitialInvestmentPacing.data"
                                  class="insight-legends"
                                >
                                  <div
                                    *ngFor="
                                      let dataset of selectedInitialInvestmentPacing
                                        .data.data.datasets;
                                      let i = index
                                    "
                                  >
                                    <span
                                      class="legend-tile"
                                      [ngStyle]="{
                                        'background-color':
                                          dataset.label !== 'Exits'
                                            ? legendColors[i]
                                            : '#9ba6b0'
                                      }"
                                    ></span
                                    ><span class="legend-title">{{
                                      dataset.label
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>

                          <ng-container
                            *ngIf="
                              box.labelKey ===
                              'Follow-on investment pacing (No. of deals)'
                            "
                          >
                            <ng-container
                              *ngIf="isFollowOnPacingUpdated$ | async"
                            >
                              <div class="cardBody">
                                <div class="insight-head">
                                  <div
                                    *ngFor="
                                      let kpi of selectedFollowOnInvestmentPacing.kpis
                                    "
                                    class="item"
                                  >
                                    <p class="value">
                                      {{ convertCurrency(kpi) }}
                                    </p>
                                    <p class="title">{{ kpi.labelKey }}</p>
                                  </div>
                                </div>
                                <div class="insight-chart">
                                  <app-chart
                                    *ngIf="
                                      selectedFollowOnInvestmentPacing.type ===
                                      'chart'
                                    "
                                    [chartData]="
                                      selectedFollowOnInvestmentPacing.data
                                    "
                                    [legendColors]="legendColors"
                                  ></app-chart>
                                </div>
                                <div
                                  *ngIf="selectedFollowOnInvestmentPacing.data"
                                  class="insight-legends"
                                >
                                  <div
                                    *ngFor="
                                      let dataset of selectedFollowOnInvestmentPacing
                                        .data.data.datasets;
                                      let i = index
                                    "
                                  >
                                    <span
                                      class="legend-tile"
                                      [ngStyle]="{
                                        'background-color':
                                          dataset.label !== 'Exits'
                                            ? legendColors[i]
                                            : '#9ba6b0'
                                      }"
                                    ></span
                                    ><span class="legend-title">{{
                                      dataset.label
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>

                          <ng-container
                            *ngIf="
                              box.labelKey ===
                              'Average holding period per month'
                            "
                          >
                            <ng-container
                              *ngIf="isAverageHoldingPeriodUpdated$ | async"
                            >
                              <div class="cardBody">
                                <div class="insight-head">
                                  <div
                                    *ngFor="
                                      let kpi of selectedAverageHoldingPeriod.kpis
                                    "
                                    class="item"
                                  >
                                    <p class="value">
                                      {{ convertCurrency(kpi) }}
                                    </p>
                                    <p class="title">{{ kpi.labelKey }}</p>
                                  </div>
                                </div>
                                <div class="insight-chart">
                                  <app-chart
                                    *ngIf="
                                      selectedAverageHoldingPeriod.type ===
                                      'chart'
                                    "
                                    [chartData]="
                                      selectedAverageHoldingPeriod.data
                                    "
                                    [legendColors]="legendColors"
                                  ></app-chart>
                                </div>
                                <div
                                  *ngIf="selectedAverageHoldingPeriod.data"
                                  class="insight-legends"
                                >
                                  <div
                                    *ngFor="
                                      let dataset of selectedAverageHoldingPeriod
                                        .data.data.datasets;
                                      let i = index
                                    "
                                  >
                                    <span
                                      class="legend-tile"
                                      [ngStyle]="{
                                        'background-color':
                                          dataset.label !== 'Exits'
                                            ? legendColors[i]
                                            : '#9ba6b0'
                                      }"
                                    ></span
                                    ><span class="legend-title">{{
                                      dataset.label
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>
                        </div>
                      </mat-card>
                    </mat-grid-tile>
                  </mat-grid-list>
                </mat-expansion-panel>
              </mat-accordion>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-tab>
    <!-- // -->
    <mat-tab *ngIf="hideInsights" label="Portfolio Companies">
      <div class="tab portfolio-companies">
        <div class="portfolio-companies-table">
          <div class="portfolio-companies-card">
            <app-portfolio-companies
              (convertPortfolioValues)="convertPortfolioValues($event)"
            ></app-portfolio-companies>
          </div>
        </div>
      </div>
    </mat-tab>
    <!--  -->
  </mat-tab-group>
</div>
<ng-template #noRecord>
  <div class="noRecord">No records found</div>
</ng-template>

<ng-template #chartLoader>
  <mat-grid-list
    [cols]="
      (isSmallHandset$ | async)
        ? 1
        : (isHandset$ | async)
        ? 1
        : (isSmallScreen$ | async)
        ? 1
        : 2
    "
    [rowHeight]="'360px'"
    gutterSize="20px"
  >
    <mat-grid-tile *ngFor="let box of [1, 2, 3, 4, 5, 6]" class="insights-card">
      <!------------>
      <mat-card class="insights-card">
        <div class="loader-container card-container">
          <div class="cardHead">
            <div class="tile"></div>
            <div class="cardHeadLeft">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  width: '150px'
                }"
              ></ngx-skeleton-loader>
            </div>
          </div>
          <div class="loader-head">
            <div class="kpi-loader" *ngFor="let kpi of [0, 1, 2]">
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  width: '90%',
                  height: '18px',
                  float: 'right'
                }"
              ></ngx-skeleton-loader>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  width: '70%',
                  height: '15px',
                  float: 'right'
                }"
              ></ngx-skeleton-loader>
            </div>
          </div>
          <div class="loader-body">
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '10px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '20px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '30px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '40px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '50px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '60px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '70px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '80px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '90px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '100px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '110px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '120px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '130px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '140px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '150px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '160px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '170px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="1"
                appearance="line"
                [theme]="{
                  height: '180px',
                  'border-radius': '0px'
                }"
              ></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </mat-card>
      <!------------>
    </mat-grid-tile>
  </mat-grid-list>
</ng-template>
