import { Component, Input, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})

export class ChartComponent implements OnInit {

  ngOnInit(){
  }

  canvas: any;
  ctx: any;
  @ViewChild('chart') chartElement: any;

  chart: any;
  @Input() chartData: any = {};
  @Input() legendColors: any = [];

  ngOnChanges(){
    this.chartData = {...this.chartData, data: {...this.chartData.data, datasets: this.chartData.data.datasets.map((dataset: any, index: any) => {
      if(dataset.label !== 'Exits')
        return { ...dataset, backgroundColor: this.legendColors[index], borderColor: this.legendColors[index]}
      return dataset;
    })}}
  }

  ngAfterViewInit() {
    Chart.defaults.elements.bar.borderSkipped = true;

    this.canvas = this.chartElement.nativeElement;
    this.ctx = this.canvas.getContext('2d');

    this.chart = new Chart(this.ctx, this.chartData);
  }
}