import { Component, EventEmitter, Input, OnInit, Output, SimpleChange} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit{
  @Input() items!: any;
  @Input() translateText:boolean  = false;
  @Input() selectedItem!: any;
  @Input() transactionData!:any;

  @Output() reportSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    // this.translateText=this.translateText;
    // let selectValue= this.items.find((type: any) => type.viewValue === this.selectedItem);
    // this.selectedItem= selectValue;
   if( this.selectedItem)
   {
    this.selectedItem= this.items.find((type: any) => type.viewValue === this.selectedItem.viewValue);
   }
  }

  onSelectEvent(event: any) {

    this.reportSelected.emit(event.value);
    this.selectedItem = event.value;
  }
  ngOnChanges(changes:SimpleChange):void {
    this.selectedItem= this.items.find((type: any) => type.viewValue === this.selectedItem);
  }
  resetForm() {
    
  }
}
