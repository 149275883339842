<div class="view-transactions-container">
    <div class="bottom-bar"></div>
    <mat-tab-group dynamicHeight mat-align-tabs="start" (selectedTabChange)="tabClick()" [selectedIndex]="1">
    <mat-tab label="{{ 'translations.capital-account.overview' | translate }}">

    </mat-tab>
    <mat-tab label="{{ 'translations.capital-account.transactions' | translate }}">
        <div class="back-button">
            <button mat-raised-button (click)="backNavigation()"> 
              <img src="../../../../assets/arrow-back-gray.svg" />
              <!-- <img src="../../../../assets/Vector.png">  -->
              <span class="text">Back</span></button>
        </div>
        <div class="current-transaction-container">
          <div class="transaction-table">
            <app-table  menuTitle="capital-account" [tableColumns]="transactionColumns" [tableData]="selectedTransaction"></app-table>
        </div>
        <div [ngClass]="!isLoading && filePath ?  'pdf-viewer-container' : 'loader-container'">
            <app-pdf-viewer *ngIf="!isLoading && filePath;" [filePath]="filePath" [fileName]="selectedTransaction[0].title" [pageNumber]=1></app-pdf-viewer>
            <ngx-skeleton-loader *ngIf="isLoading" count="1" appearance="line" [theme]="{
                'width': '100%',
                'height': '100%',
                'margin': '0px'
              }"></ngx-skeleton-loader>
              <div class="previewImgContainer" *ngIf="!isLoading && !filePath;">
                <svg width="128" height="180" viewBox="0 0 128 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="primary"
                    d="M118.297 45.3748C118.549 45.3748 118.796 45.3 119.005 45.1599C119.215 45.0198 119.378 44.8207 119.475 44.5877C119.571 44.3547 119.597 44.0983 119.548 43.851C119.498 43.6037 119.377 43.3765 119.199 43.1982L85.3014 9.30103C85.1231 9.1226 84.8959 9.00106 84.6486 8.9518C84.4012 8.90253 84.1448 8.92776 83.9118 9.02429C83.6788 9.12081 83.4797 9.28429 83.3396 9.49404C83.1995 9.70378 83.1249 9.95037 83.125 10.2026V38.9998C83.125 40.6905 83.7966 42.3121 84.9922 43.5076C86.1877 44.7031 87.8092 45.3748 89.5 45.3748H118.297Z" />
                  <path class="secondary"
                    d="M121.375 58.125H89.5C84.4277 58.125 79.5632 56.1101 75.9766 52.5234C72.3899 48.9368 70.375 44.0723 70.375 39V7.14138C70.3775 6.30561 70.2151 5.47757 69.8971 4.70463C69.5792 3.9317 69.1119 3.22905 68.5221 2.63692C67.9323 2.04478 67.2315 1.57478 66.4598 1.25381C65.6881 0.932836 64.8607 0.767201 64.0249 0.766383C49.0926 0.758606 13 0.75 13 0.75C11.3256 0.74995 9.66765 1.0797 8.12073 1.72043C6.5738 2.36116 5.16823 3.30032 3.98428 4.48428C2.80032 5.66823 1.86116 7.0738 1.22043 8.62073C0.579705 10.1677 0.24995 11.8256 0.25 13.5V166.5C0.25 169.882 1.5933 173.125 3.98439 175.516C6.37548 177.907 9.61849 179.25 13 179.25H115C118.382 179.25 121.625 177.907 124.016 175.516C126.407 173.125 127.75 169.882 127.75 166.5V64.5C127.75 62.8092 127.078 61.1877 125.883 59.9922C124.687 58.7967 123.066 58.125 121.375 58.125ZM102.02 124.087C97.246 129.453 91.39 133.749 84.8371 136.69C78.2842 139.631 71.1829 141.152 64.0001 141.152C56.8174 141.152 49.716 139.631 43.1631 136.69C36.6102 133.749 30.7543 129.453 25.9805 124.087C23.8745 121.722 22.7109 118.666 22.7109 115.5C22.7109 112.333 23.8745 109.278 25.9805 106.913C30.7543 101.546 36.6103 97.2511 43.1632 94.3098C49.7161 91.3685 56.8174 89.8479 64.0001 89.8479C71.1828 89.8479 78.2842 91.3685 84.8371 94.3098C91.39 97.2511 97.2459 101.546 102.02 106.913C104.126 109.277 105.289 112.333 105.289 115.5C105.289 118.666 104.126 121.722 102.02 124.087Z" />
                  <path class="primary"
                    d="M74.9333 118.573C72.4467 118.579 70.0415 117.687 68.1608 116.06C66.2802 114.433 65.0509 112.182 64.6994 109.72C64.348 107.259 64.898 104.753 66.2482 102.665C67.5984 100.577 69.6578 99.047 72.0469 98.3575C68.2981 96.6238 64.0882 96.1527 60.0489 97.0147C56.0096 97.8767 52.3588 100.025 49.6442 103.138C46.9297 106.251 45.298 110.161 44.9938 114.28C44.6896 118.399 45.7294 122.505 47.9571 125.983C50.1849 129.461 53.4805 132.123 57.3494 133.569C61.2182 135.015 65.4517 135.167 69.4145 134.003C73.3773 132.839 76.8557 130.421 79.3277 127.112C81.7998 123.804 83.1321 119.782 83.1248 115.652C83.1248 115.293 83.0384 114.958 83.019 114.604C82.0611 115.836 80.8351 116.834 79.4342 117.521C78.0332 118.209 76.4939 118.569 74.9333 118.573Z" />
                </svg>
                <p class="selectMessage">No document found.</p>
              </div>
        </div>
        </div>
    </mat-tab>
    </mat-tab-group>
</div>

<ng-template #pdfLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
      'width': '100%',
      'height': '100%',
      'margin': '0px'
    }"></ngx-skeleton-loader>
  </ng-template>