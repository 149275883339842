<mat-sidenav-container *ngIf="!isInaccessible" class="sidenav-container">
  <mat-sidenav #drawer [class]="(isTablet$ | async) ? 'sidenav-tablet' : 'sidenav'"
    fixedInViewport="(isHandset$ | async) === false" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">

    <mat-toolbar *ngIf="currentGPSLogo.bigUrl && currentGPSLogo.smallUrl; else logoLoader">
      <img *ngIf="isTablet$ | async; else bigUrl" src="{{ currentGPSLogo.smallUrl }}" />
      <ng-template #bigUrl>
        <img src="{{ currentGPSLogo.bigUrl }}" />
      </ng-template>
    </mat-toolbar>

    <!-- <div *ngIf="isHandset$ | async" class="handsetDropDown">
      <mat-form-field appearance="fill">
        <mat-select
          [(value)]="selectedInvestor"
          (selectionChange)="onLPSChange($event.value)"
        >
          <mat-option
            *ngFor="let investor of investors"
            [value]="investor"
            (click)="drawer.close()"
          >
            {{ investor.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->

    <div *ngIf="isHandset$ | async" class="handsetDropDown">
      <mat-form-field appearance="fill" *ngIf="investors.length > 1 ; else singleInvestorMobile"
        [matTooltip]="selectedInvestor.name" matTooltipPosition="below"
        [matTooltipDisabled]="selectedInvestor.name.length<10">
        <img src="../../assets/images/lp_icon.svg" matPrefix />
        <mat-select [(value)]="selectedInvestor" (selectionChange)="onLPSChange($event.value)" #mobileInvestorSelect>
          <mat-option *ngFor="let investor of investors" [value]="investor" (click)="drawer.close()">
            {{ investor.name }}
          </mat-option>
        </mat-select>
        <img [ngClass]="{ 'open': mobileInvestorSelect.panelOpen}" class="arrow-down"
          src="../../assets/images/arrow-down.svg" matSuffix />
      </mat-form-field>
      <ng-template #singleInvestorMobile>
        <div class="single-investor-mobile" [matTooltip]="selectedInvestor?.name" matTooltipPosition="below"
          [matTooltipDisabled]="selectedInvestor?.name?.length<14">
          <img src="../../assets/images/gp_icon.svg" alt="gp-icon">
          <div>{{selectedInvestor.name}}</div>
        </div>
      </ng-template>
    </div>

    <!-- <mat-nav-list class="navList" *ngIf="isHandset$ | async">
      <ng-container *ngIf="!!privileges.length; else menuItemLoader">
        <div *ngFor="let item of privileges" mat-list-item [routerLink]="
            '/lp/' +
            selectedInvestor.id +
            '/vehicle/' +
            selectedFund.id +
            item.path
          " (click)="navigateToComponent(item)" routerLinkActive="active-link" (click)="onSelectedMenu(item)"
          (click)="drawer.close()">
          <img class="icon" [src]="item.activeimgURL" [ngClass]="
              selectedMenu.value === item.value
                ? 'showActiveImg'
                : 'hideActiveImg'
            " />
          <img class="icon" [src]="item.imgURL" [ngClass]="
              selectedMenu.value !== item.value
                ? 'showInactiveImg'
                : 'hideInactiveImg'
            " />
          <span class="icon-title" *ngIf="(isTablet$ | async) === false"
            [ngClass]="item.value === 'dashboard' ? 'dashboard-icon' : ''">{{ 'translations.' + item.value + '.title' |
            translate }}</span>
        </div>
      </ng-container>
    </mat-nav-list>

    <mat-nav-list class="navList" *ngIf="(isHandset$ | async) === false">
      <ng-container *ngIf="!!privileges.length; else menuItemLoader">
        <div *ngFor="let item of privileges" class="menuItem" mat-list-item [routerLink]="
            '/lp/' +
            selectedInvestor.id +
            '/vehicle/' +
            selectedFund.id +
            item.path
          " (click)="navigateToComponent(item)" routerLinkActive="active-link" (click)="onSelectedMenu(item)">
          <img class="icon" [src]="item.activeimgURL" [ngClass]="
              selectedMenu.value === item.value
                ? 'showActiveImg'
                : 'hideActiveImg'
            " />
          <img class="icon" [src]="item.imgURL" [ngClass]="
              selectedMenu.value !== item.value
                ? 'showInactiveImg'
                : 'hideInactiveImg'
            " />
          <span class="icon-title" *ngIf="(isTablet$ | async) === false"
            [ngClass]="item.value === 'dashboard' ? 'dashboard-icon' : ''">{{ 'translations.' + item.value + '.title' |
            translate }}</span>
        </div>
      </ng-container>
    </mat-nav-list>

    <mat-nav-list class="navList2">
      <div *ngIf="sessionUser.confirmedTermsOfUse" (click)="openTermsOfUse()" class="menuItem">
        <img src="../../../../assets/terms-of-use.png" />

        <span class="icon-title" *ngIf="(isTablet$ | async) === false">
          Terms of use
        </span>
      </div>
      <div (click)="openDialog(false, '')" class="menuItem">
        <img src="../../../../assets/logout.png" />
        <span class="icon-title" *ngIf="(isTablet$ | async) === false">{{
          'translations.logOut' | translate
          }}</span>
      </div>
    </mat-nav-list> -->

    <ng-container *ngIf="!!privileges.length; else menuItemLoader">
      <!-- mobile screen menu item -->
      <mat-nav-list class="navList" *ngIf="isHandset$ | async">
        <ng-container *ngFor="let item of privileges">
          <mat-divider *ngIf="item.value == 'Settings'"></mat-divider>
          <div mat-list-item class="menuItem" [routerLink]="
              '/lp/' +
              selectedInvestor.id +
              '/vehicle/' +
              selectedFund.id +
              item.path
            " routerLinkActive="active-link" (click)="navigateToComponent(item)" (click)="onSelectedMenu(item)">
            <!-- <img class="icon" [src]="item.activeimgURL" [ngClass]="
                selectedMenu.value === item.value
                  ? 'showActiveImg'
                  : 'hideActiveImg'
              " /> -->
            <span class="icon" [ngClass]="selectedMenu.value === item.value ? 'showActiveImg' : 'hideActiveImg'">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ng-container *ngFor="let svgPath of item.svg">
                  <path [attr.d]="svgPath.d" [attr.stroke]="svgPath.stroke ? svgPath.stroke : null"
                    [attr.stroke-width]="svgPath.strokeWidth ? svgPath.strokeWidth : null"
                    [attr.stroke-linecap]="svgPath.strokeLinecap ? svgPath.strokeLinecap : null"
                    [attr.stroke-linejoin]="svgPath.strokeLinejoin ? svgPath.strokeLinejoin : null" />
                </ng-container>
              </svg>
            </span>
            <img class="icon" [src]="item.imgURL" [ngClass]="
                selectedMenu.value !== item.value
                  ? 'showInactiveImg'
                  : 'hideInactiveImg'
              " />
            <span class="icon-title" *ngIf="(isTablet$ | async) === false"
              [ngClass]="item.value === 'Dashboard' ? 'dashboard-icon' : ''">
              {{ item.value }}</span>
          </div>
        </ng-container>
      </mat-nav-list>

      <!-- desktop screen menu item -->
      <mat-nav-list class="navList" *ngIf="(!(isHandset$ | async) && !(isTablet$ | async))">
        <ng-container *ngFor="let item of privileges">
          <mat-divider *ngIf="item.value == 'Settings'"></mat-divider>
          <div mat-list-item class="menuItem"
            [routerLink]="'/lp/' + selectedInvestor.id + '/vehicle/' + selectedFund.id + item.path"
            routerLinkActive="active-link" (click)="navigateToComponent(item)" (click)="onSelectedMenu(item)"
            tabindex="0">
            <!-- <img class="icon" [src]="item.activeimgURL"
              [ngClass]="selectedMenu.value === item.value ? 'showActiveImg' : 'hideActiveImg'" /> -->
            <span class="icon" [ngClass]="selectedMenu.value === item.value ? 'showActiveImg' : 'hideActiveImg'">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ng-container *ngFor="let svgPath of item.svg">
                  <path [attr.d]="svgPath.d" [attr.stroke]="svgPath.stroke ? svgPath.stroke : null"
                    [attr.stroke-width]="svgPath.strokeWidth ? svgPath.strokeWidth : null"
                    [attr.stroke-linecap]="svgPath.strokeLinecap ? svgPath.strokeLinecap : null"
                    [attr.stroke-linejoin]="svgPath.strokeLinejoin ? svgPath.strokeLinejoin : null" />
                </ng-container>
              </svg>
            </span>

            <img class="icon" [src]="item.imgURL"
              [ngClass]="selectedMenu.value !== item.value ? 'showInactiveImg' : 'hideInactiveImg'" />
            <span class="icon-title" [ngClass]="item.value === 'Dashboard' ? 'dashboard-icon' : ''">
              {{ item.viewValue }}
            </span>
          </div>
        </ng-container>
      </mat-nav-list>

      <!-- tablet screen menu item -->
      <mat-nav-list class="navList" *ngIf="(isTablet$ | async)">
        <ng-container *ngFor="let item of privileges">
          <mat-divider *ngIf="item.value == 'Settings'"></mat-divider>
          <div mat-list-item class="menuItem"
            [routerLink]="'/lp/' + selectedInvestor.id + '/vehicle/' + selectedFund.id + item.path"
            routerLinkActive="active-link" (click)="navigateToComponent(item)" (click)="onSelectedMenu(item)"
            tabindex="0">
            <!-- <img class="icon" [src]="item.activeimgURL" alt="icon"
              [ngClass]="selectedMenu.value === item.value ? 'showActiveImg' : 'hideActiveImg'" /> -->
            <span class="icon" [ngClass]="selectedMenu.value === item.value ? 'showActiveImg' : 'hideActiveImg'">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ng-container *ngFor="let svgPath of item.svg">
                  <path [attr.d]="svgPath.d" [attr.stroke]="svgPath.stroke ? svgPath.stroke : null"
                    [attr.stroke-width]="svgPath.strokeWidth ? svgPath.strokeWidth : null"
                    [attr.stroke-linecap]="svgPath.strokeLinecap ? svgPath.strokeLinecap : null"
                    [attr.stroke-linejoin]="svgPath.strokeLinejoin ? svgPath.strokeLinejoin : null" />
                </ng-container>
              </svg>
            </span>
            <img class="icon" [src]="item.imgURL"
              [ngClass]="selectedMenu.value !== item.value ? 'showActiveImg' : 'hideActiveImg'" alt="Menu Icon" />
          </div>
        </ng-container>
      </mat-nav-list>

      <div class="sidenav-footer">
        <mat-nav-list class="navList">
          <div mat-list-item (click)="openTermsOfUse()" class="menuItem">
            <img src="../../../../assets/images/terms_icon.svg" alt="icon" />

            <span class="icon-title" *ngIf="(isTablet$ | async) === false">
              Terms of use
            </span>
          </div>
          <div mat-list-item (click)="openDialog(false, '')" class="menuItem">
            <img src="../../../../assets/images/signout_icon.svg" alt="icon" />
            <span class="icon-title" *ngIf="(isTablet$ | async) === false">{{
              'translations.logOut' | translate
              }}</span>
          </div>
        </mat-nav-list>
        <div class="copy-rites">
          {{(isTablet$ | async)? "" : "© 2024 ACE Alternatives."}}
        </div>
      </div>
    </ng-container>



  </mat-sidenav>

  <mat-sidenav-content class="{{ activeClassName }} componentClass"
    [ngClass]="(isTablet$ | async) ? 'tablet' : (isHandset$| async) ? 'mobile' : 'desktop'">
    <div class="header">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async" style="z-index: 1">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div class="dashBoardTitle">
        <!-- <mat-form-field appearance="fill" class="fundDropdown">
              <mat-select
                [(value)]="selectedFund"
                (selectionChange)="onVehicleChange($event.value)"
              >
                <mat-option *ngFor="let fund of funds" [value]="fund">
                  {{ getVehicleName(fund) }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
        <mat-form-field appearance="fill" class="fundDropdown" *ngIf="funds.length > 1"
          [matTooltip]="selectedFund?.name" matTooltipPosition="below"
          [matTooltipDisabled]="selectedFund?.name?.length<10">
          <img src="../../assets/images/fund_icon.svg" matPrefix />
          <mat-select [(value)]="selectedFund" (selectionChange)="onVehicleChange($event.value)" #fundSelect>
            <mat-option class="fund-item" *ngFor="let fund of funds" [value]="fund">
              {{ getVehicleName(fund) }}
            </mat-option>
          </mat-select>
          <img class="arrow-down" [ngClass]="{ 'open': fundSelect.panelOpen}" src="../../assets/images/arrow-down.svg"
            matSuffix />
        </mat-form-field>

        <div class="bread-crumb">
          <ng-container *ngIf="funds.length == 1">
            <div class="single-fund">
              <img src="../../assets/images/fund_icon.svg">
              <div>{{getVehicleName(selectedFund)}}</div>
            </div>
            <img src="../../../../assets/images/arrow_right_icon.svg" height="16px" width="16px" alt="">
          </ng-container>
          <span class="primary-path">
            {{ 'translations.' + newMenuItem + '.title' | translate }}</span>
          <!-- <ng-container *ngIf="!(isHandset$ | async)">
                <ng-container *ngFor="let path of commonService.secondaryPathObservable$ | async">
                <img src="../../../../assets/images/arrow_right_icon.svg" alt="">
                <span class="path-name">{{selectedChildItem}}</span>
                </ng-container>
              </ng-container> -->
        </div>

        <!-- <span class="menuHeading">/
              {{ 'translations.' + newMenuItem + '.title' | translate }}
              <ng-container *ngIf="selectedChildItem">
                <span class="childMenuItem"><ng-container class="childMenuItem">
                    {{ pathSeparator }}
                  </ng-container>
                  {{ 'translations.' + selectedChildItem | translate }}
                </span></ng-container>

              <ng-container *ngIf="suffixMessage">
                <span class="childMenuItem"><ng-container class="childMenuItem"> </ng-container>
                  {{ suffixMessage }}
                </span></ng-container>
            </span> -->
      </div>
      <div *ngIf="(isHandset$ | async) === false" class="investorDropdown">
        <!-- <mat-form-field appearance="fill">
            <mat-select [(value)]="selectedInvestor" (selectionChange)="onLPSChange($event.value)">
              <mat-option *ngFor="let investor of investors" [value]="investor">
                {{ investor.name }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

        <mat-form-field appearance="fill" *ngIf="investors.length > 1 ; else singleInvestor"
          [matTooltip]="selectedInvestor?.name" matTooltipPosition="below"
          [matTooltipDisabled]="selectedInvestor?.name?.length<10">
          <img src="../../assets/images/lp_icon.svg" matPrefix />
          <mat-select [(value)]="selectedInvestor" (selectionChange)="onLPSChange($event.value)" #investorSelect>
            <mat-option class="investor-item" *ngFor="let investor of investors" [value]="investor"
              [matTooltip]="investor?.name" matTooltipPosition="below" [matTooltipDisabled]="investor?.name?.length<25">
              {{ investor.name }}
            </mat-option>
          </mat-select>
          <img class="arrow-down" [ngClass]="{ 'open': investorSelect.panelOpen}"
            src="../../assets/images/arrow-down.svg" matSuffix />
        </mat-form-field>
        <ng-template #singleInvestor>
          <div class="single-investor" [matTooltip]="selectedInvestor?.name" matTooltipPosition="below"
            [matTooltipDisabled]="selectedInvestor?.name?.length<14">
            <img src="../../assets/images/gp_icon.svg" alt="gp-icon">
            <div>{{selectedInvestor.name}}</div>
          </div>
        </ng-template>
      </div>
      <!-- <div
          [routerLink]="'/account'"
          (click)="onSelectedMenu('profile')"
          routerLinkActive="profile-active-link"
          *ngIf="(isHandset$ | async) === false"
          class="profile"
        >
          <div>
            <img
              *ngIf="
                activeClassName === 'dashboard-component' ||
                  activeClassName === 'default-component';
                else darkProfile
              "
              src="../../assets/account_circle.svg"
            />
            <ng-template #darkProfile>
              <img src="../../assets/account_circle_black.svg" />
            </ng-template>
          </div>
        </div> -->

    </div>

    <div style="padding: 0 24px;">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>

<ng-template #menuItemLoader>
  <div *ngFor="let number of [0, 1, 2, 3, 4]">
    <div class="menuItemLoader">
      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          width: '30px',
          height: '30px'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader *ngIf="(isTablet$ | async) === false" count="1" appearance="line" [theme]="{
          width: '130px'
        }"></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>

<ng-template #logoLoader>
  <div class="logoLoader">
    <ngx-skeleton-loader *ngIf="!(isTablet$ | async)" count="1" appearance="line" [theme]="{
        width: '130px',
        height: '60px'
      }"></ngx-skeleton-loader>
    <ngx-skeleton-loader *ngIf="isTablet$ | async" count="1" appearance="line" [theme]="{
        width: '60px',
        height: '40px'
      }"></ngx-skeleton-loader>
  </div>
</ng-template>