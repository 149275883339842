export const formatDate = (date: Date) => {
  if (!date) {
    return [];
  }
  let dateArray = new Array();
  // don't use date.toISOString() here, it will be always one day off (cause of the timezone)
  const day = date.getDate() < 10 ? 0 + date.getDate() : date.getDate();
  const month =
    date.getMonth() < 9 ? 0 + (date.getMonth() + 1) : date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours() < 10 ? 0 + date.getHours() : date.getHours();
  const minutes =
    date.getMinutes() < 10 ? 0 + date.getMinutes() : date.getMinutes();
  const seconds =
    date.getSeconds() < 10 ? 0 + date.getSeconds() : date.getSeconds();
  dateArray = [year, month, day, hour, minutes];
  return dateArray;
};
export const download = (filename: any, text: any) => {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);
  element.setAttribute('target', '_blank');
  element.style.display = 'none';
  element.click();
};

export const createEventData = (_event: any) => {
  let event: any;
  event = {
    start: formatDate(new Date(_event.start)),
    end: formatDate(new Date(_event.end)),
    title: _event.title,
    description: _event.description,
    location: _event.location,
  };
  return event;
};
